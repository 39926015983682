import React from 'react'

import Header from '../includes/header';
import Footer from '../includes/footer';
import Home_Banner from '../component/home_banner';
import Platform from '../component/platform';
import Services from '../component/services';
import Award_Winning from '../component/award_winning';
import Process from '../component/process';
import Cta from '../component/cta';
import Choose from '../component/choose_us';
import Portfolio from '../component/portfolio';
import Testimonial from '../component/testimonial';
import useDocumentTitle from '../useDocumentTitle.jsx'

const Home = () => {
  useDocumentTitle('Custom Web Design Agency | Reputable Web Design Services')

  return (
    <div>
      <Header />
      <Home_Banner />
      <Platform />
      <Services />
      <Portfolio />
      <Award_Winning />
      <Process />
      <Cta />
      <Testimonial />
      <Choose />
      <Footer />
    </div>
  )
}

export default Home
