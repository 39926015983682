import React from 'react'
import Header from '../includes/header';
import Footer from '../includes/footer';

import brand_img from '../assets/images/brand-img.png';
import inner_banner_img from '../assets/images/inner-banner-img.png';
import icon_1 from '../assets/images/icon-1.png';
import icon_2 from '../assets/images/icon-2.png';
import icon_3 from '../assets/images/icon-3.png';
import icon_4 from '../assets/images/icon-4.png';

import icon_5 from '../assets/images/icon-5.png';
import icon_6 from '../assets/images/icon-6.png';
import icon_7 from '../assets/images/icon-7.png';
import icon_8 from '../assets/images/icon-8.png';

import icon_9 from '../assets/images/icon-9.png';
import icon_10 from '../assets/images/icon-10.png';
import icon_11 from '../assets/images/icon-11.png';
import icon_12 from '../assets/images/icon-12.png';

import icon_13 from '../assets/images/icon-13.png';
import icon_14 from '../assets/images/icon-14.png';
import icon_15 from '../assets/images/icon-15.png';
import icon_16 from '../assets/images/icon-16.png';
import icon_17 from '../assets/images/icon-17.png';
import icon_18 from '../assets/images/icon-18.png';


import profund_bg from '../assets/images/profund-bg.png';
import pricing_bg from '../assets/images/pricing-bg.png';
import useDocumentTitle from '../useDocumentTitle.jsx'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Logo_Design = () => {
    useDocumentTitle('Logo Design')

  return (
    <>
       <Header />
          <section className="inner-banner-sec">
              <div className="container">
                  <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="banner-content">
                              <h1>Custom Logo Design Services for You</h1>
                              <p>It's essential to have a company logo of top-notch quality and distinct to make your brand stand out. You can team up with branded logo designing experts to produce a spectacular and influential logo for your brand or company that makes a lasting impact.</p>
                              <div className="brand-rating">
                                  <div className="rating">
                                      <h6>5.0 </h6>
                                      <ul>
                                          <li><i className="fa-solid fa-star"></i></li>
                                          <li><i className="fa-solid fa-star"></i></li>
                                          <li><i className="fa-solid fa-star"></i></li>
                                          <li><i className="fa-solid fa-star"></i></li>
                                          <li><i className="fa-solid fa-star"></i></li>
                                      </ul>
                                      <h6> rating on</h6>
                                  </div>
                                  <div className="brand-img">
                                      <LazyLoadImage src={brand_img} alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                              </div>
                              <div className="banner-btn">
                                  <a href="javascript:$zopim.livechat.window.show();" className="btn btn-contact">Get a quote now</a>
                                  <a href="javascript:$zopim.livechat.window.show();" className="btn btn-chat"><i className="fa-solid fa-messages"></i></a>
                              </div>
                          </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="main-img">
                              <LazyLoadImage src={inner_banner_img} className="img-fluid" alt="img not found" effect="blur"/>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="about-sec-wrap">
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">
                          <div className="content">
                              <h2>About Our Creative Logo Design Agency</h2>
                              <p>From fast food restaurants to construction companies to beauty salons, our skilled designers will generate unique logo design ideas, from which you can choose your favorite. Our corporate identity logo design agency is a creative platform with a global reach, facilitating easy collaboration between clients and designers online. Over the years, we have delivered more than a million creative projects, bringing to life the visions of countless brilliant entrepreneurs, small business owners, and ambitious brands. Our mission is to empower small businesses by providing them with top-notch design expertise and helping them flourish in their respective markets</p>
                          </div>
                      </div>
                  </div>
                  <div className="row justify-content-xl-between justify-content-center">
                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
                          <div className="about-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_1} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>275+</h4>
                                  <p>Projects Completed.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
                          <div className="about-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_2} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>89%</h4>
                                  <p>Customer Retention</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
                          <div className="about-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_3} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>120+</h4>
                                  <p>Professionals</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
                          <div className="about-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_4} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>20+</h4>
                                  <p>Years of Experience</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
                          <div className="about-card">
                              <h5>Schedule A Call
                                  <br/>
                                      Now!
                              </h5>
                              <div className="desc">
                                  <a href="javascript:$zopim.livechat.window.show();" className="btn btn-call"><i className="fa-solid fa-phone-volume"></i> Click
                                      Here!</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="Premium-ppc-sec">
              <div className="container-fluid">
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="content">
                              <h3>Logo Creation Services</h3>
                          </div>
                      </div>
                  </div>
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_5} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Restaurant logos</h4>
                                  <p>Codex Design Agency can create logos for restaurants that capture the essence of their cuisine and brand identity, helping them attract and retain customers.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_6} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Construction logos</h4>
                                  <p>For businesses in the construction industry, we can design logos that communicate strength, stability, and reliability.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_7} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Barber Logos</h4>
                                  <p>Codex Design Agency can design logos for barbershops that reflect their unique style and personality.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_8} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Photography Logos</h4>
                                  <p>Our team also designs logos that convey creativity, artistry, and professionalism for those in the photography business.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_9} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Business Card Design</h4>
                                  <p>We create custom business card logos that reflect your brand identity and leave a lasting impression on potential clients</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_10} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Letterhead Design</h4>
                                  <p>Our professional letterhead designs can showcase your brand identity and convey a sense of professionalism and trustworthiness.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_11} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Envelope Designs</h4>
                                  <p>Create a custom envelope that matches your brand identity and gives your business a cohesive and professional look.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_12} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Promotional Products</h4>
                                  <p>Get your promotional products, such as pens, mugs, and keychains, a featured logo to help spread the word about your business.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_13} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Gaming Logo</h4>
                                  <p>Our team also designs logos that capture the excitement and energy of the gaming world.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_14} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Flyer design</h4>
                                  <p>Get eye-catching flyers that communicate information about your business or event, and help attract new customers.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_15} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Brochure designs</h4>
                                  <p>We design brochures that provide detailed information about your business or products and showcase your brand identity professionally.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_16} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Beauty Salon Logos</h4>
                                  <p>Get your beauty salon an attractive logo to help men and women remember it in a competitive market.</p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="profund-sec-wrap">
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">
                          <div className="content">
                              <h2>Our Logo Design Process</h2>
                          </div>
                      </div>
                  </div>
                  <div className="row justify-content-center">
                      <div className="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3 col-xxl-3">
                          <div className="profund-card">
                              <div className="step">
                                  <h5>01</h5>
                              </div>
                              <div className="inner-box">
                                  <div className="main-img">
                                      <LazyLoadImage src={icon_17} alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                                  <div className="desc">
                                      <h4>Select Your Niche</h4>
                                      <p>Select the industry or niche that your website belongs to, to helps us understand the specific requirements and design elements that are appropriate for your website's logo.</p>
                                  </div>
                                  <div className="bg-img">
                                      <LazyLoadImage src={profund_bg} alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3 col-xxl-3">
                          <div className="profund-card">
                              <div className="step">
                                  <h5>02</h5>
                              </div>
                              <div className="inner-box">
                                  <div className="main-img">
                                      <LazyLoadImage src={icon_18} alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                                  <div className="desc">
                                      <h4>Describe Your Designs</h4>
                                      <p>You will provide a brief description of the type of logo you want. This includes details like the design style, color scheme, typography, etc.</p>
                                  </div>
                                  <div className="bg-img">
                                      <LazyLoadImage src={profund_bg} alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3 col-xxl-3">
                          <div className="profund-card">
                              <div className="step">
                                  <h5>03</h5>
                              </div>
                              <div className="inner-box">
                                  <div className="main-img">
                                      <LazyLoadImage src={icon_6}alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                                  <div className="desc">
                                      <h4>Submission</h4>
                                      <p>Based on your requirements, our designers will submit unique logo designs for you to choose from.</p>
                                  </div>
                                  <div className="bg-img">
                                      <LazyLoadImage src={profund_bg} alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3 col-xxl-3">
                          <div className="profund-card">
                              <div className="step">
                                  <h5>04</h5>
                              </div>
                              <div className="inner-box">
                                  <div className="main-img">
                                      <LazyLoadImage src={icon_17} alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                                  <div className="desc">
                                      <h4>Finalization</h4>
                                      <p>Once you select the final design, you will receive the logo in various file formats, making it easy to use on your website and other marketing materials.</p>
                                  </div>
                                  <div className="bg-img">
                                      <LazyLoadImage src={profund_bg} alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="pricing-sec-wrap">
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">
                          <div className="content">
                              <h2>Pricing Plans</h2>
                          </div>
                      </div>
                  </div>
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <div className="pricing-top-btn">
                              <a href="javascript:$zopim.livechat.window.show();" className="btn btn-top-pricing">Logo Design</a>
                          </div>
                      </div>
                  </div>
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="pricing-card">
                              <div className="main-box">
                                  <LazyLoadImage src={pricing_bg} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="title">
                                  <h2>Basic Logo Package</h2>
                              </div>
                              <div className="inner-box">
                                  <h5>STARTING AT</h5>
                                  <h4>$35</h4>
                                  <ul>
                                      <li>3 Custom Logo Design Concepts</li>
                                      <li>1 Dedicated Designer</li>
                                      <li>4 Revisions</li>
                                      <li>48 to 72 hours TAT</li>
                                      <li>100% Unique Design Guarantee</li>
                                      <li>100% Satisfaction Guarantee</li>
                                      <li>100% Money Back Guarantee *</li>
                                  </ul>

                                  <div className="delivery">
                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                  </div>
                                  <div className="pricing-btns">
                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="pricing-card">
                              <div className="main-box">
                                  <LazyLoadImage src={pricing_bg} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="title">
                                  <h2>Startup Logo Package</h2>
                              </div>
                              <div className="inner-box">
                                  <h5>STARTING AT</h5>
                                  <h4>$75</h4>
                                  <ul>
                                      <li>5 Custom Logo Design Concepts</li>
                                      <li>By 2 Designers</li>
                                      <li>UNLIMITED Revisions</li>
                                      <li>48 to 72 hours TAT</li>
                                      <li>All Final Files Format (All/ PSD/ EPS/ PNG/ GIF/ JPG/ PDF)</li>
                                      <li>100% Satisfaction Guarantee</li>
                                      <li>100% Unique Design Guarantee</li>
                                      <li>100% Money Back Guarantee *</li>
                                  </ul>

                                  <div className="delivery">
                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                  </div>
                                  <div className="pricing-btns">
                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="pricing-card">
                              <div className="main-box">
                                  <LazyLoadImage src={pricing_bg} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="title">
                                  <h2>Professional Logo Package</h2>
                              </div>
                              <div className="inner-box">
                                  <h5>STARTING AT</h5>
                                  <h4>$125</h4>
                                  <ul>
                                      <li>UNLIMITED Logo Design Concepts</li>
                                      <li>By 4 Designers</li>
                                      <li>UNLIMITED Revisions</li>
                                      <li>Stationary Design (Business Card</li>
                                      <li>Letterhead</li>
                                      <li>Envelope)</li>
                                      <li>FREE MS Word Letterhead</li>
                                      <li>48 to 72 hours TAT</li>
                                      <li>All Final Files Format (All/ PSD/ EPS/ PNG/ GIF/ JPG/ PDF)</li>
                                      <li>100% Satisfaction Guarantee</li>
                                      <li>100% Unique Design Guarantee</li>
                                      <li>100% Money Back Guarantee *</li>
                                  </ul>

                                  <div className="delivery">
                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                  </div>
                                  <div className="pricing-btns">
                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="pricing-card">
                              <div className="main-box">
                                  <LazyLoadImage src={pricing_bg} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="title">
                                  <h2>Elite Logo Package</h2>
                              </div>
                              <div className="inner-box">
                                  <h5>STARTING AT</h5>
                                  <h4>$149</h4>
                                  <ul>
                                      <li>Website Mockups</li>
                                      <li>UNLIMITED Logo Design Concepts</li>
                                      <li>By 5 Award Winning Designers</li>
                                      <li>Stationary Design (Business Card</li>
                                      <li>Letterhead</li>
                                      <li>Envelope)</li>
                                      <li>Icon Design</li>
                                      <li>UNLIMITED Revisions</li>
                                      <li>FREE MS Word Letterhead</li>
                                      <li>Free Email Signature</li>
                                      <li>All Final Files Format (All/ PSD/ EPS/ PNG/ GIF/ JPG/ PDF)</li>
                                      <li>100% Satisfaction Guarantee</li>
                                      <li>100% Unique Design Guarantee</li>
                                      <li>100% Money Back Guarantee *</li>
                                      <li>48 to 72 hours TAT</li>
                                  </ul>

                                  <div className="delivery">
                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                  </div>
                                  <div className="pricing-btns">
                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="pricing-card">
                              <div className="main-box">
                                  <LazyLoadImage src={pricing_bg} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="title">
                                  <h2>Business Logo Package</h2>
                              </div>
                              <div className="inner-box">
                                  <h5>STARTING AT</h5>
                                  <h4>$199</h4>
                                  <ul>
                                      <li>Website Mockup</li>
                                      <li>UNLIMITED Logo Design Concepts</li>
                                      <li>By 8 Award Winning Designers</li>
                                      <li>UNLIMITED Revisions</li>
                                      <li>2 Stationary Design Sets (Business Card</li>
                                      <li>Letterhead</li>
                                      <li>Envelope)</li>
                                      <li>Icon Design</li>
                                      <li>Double Sided Flyer Design / Bi-Fold Brochure</li>
                                      <li>FREE MS Word Letterhead</li>
                                      <li>Free Email Signature</li>
                                      <li>All Final Files Format (All / PSD / EPS / PNG / GIF / JPG / PDF)</li>
                                      <li>100% Ownership Rights</li>
                                      <li>100% Satisfaction Guarantee</li>
                                      <li>100% Unique Design Guarantee</li>
                                      <li>100% Money Back Guarantee *</li>
                                  </ul>
                                  <div className="delivery">
                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                  </div>
                                  <div className="pricing-btns">
                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="pricing-card">
                              <div className="main-box">
                                  <LazyLoadImage src={pricing_bg} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="title">
                                  <h2>Gold Logo Package</h2>
                              </div>
                              <div className="inner-box">
                                  <h5>STARTING AT</h5>
                                  <h4>$425</h4>
                                  <ul>
                                      <li>UNLIMITED Logo Design Concepts</li>
                                      <li>By 8 Award Winning Designers</li>
                                      <li>Icon Design</li>
                                      <li>UNLIMITED Revisions</li>
                                      <li>2 Stationary Design Sets (Business Card</li>
                                      <li>Letterhead</li>
                                      <li>Envelope)</li>
                                      <li>FREE MS Word Letterhead</li>
                                      <li>Free Email Signature</li>
                                      <li>3 Page Custom Website</li>
                                      <li>2 Stock Photos</li>
                                      <li>2 Banner Designs</li>
                                      <li>jQuery Slider</li>
                                      <li>All Final Files Format (All/ PSD/ EPS/ PNG/ GIF/ JPG/ PDF)</li>
                                      <li>100% Ownership Rights</li>
                                      <li>100% Satisfaction Guarantee</li>
                                      <li>100% Unique Design Guarantee</li>
                                      <li>100% Money Back Guarantee *</li>
                                  </ul>

                                  <div className="delivery">
                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                  </div>
                                  <div className="pricing-btns">
                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
       <Footer />      
    </>
  )
}

export default Logo_Design
