import React from 'react'
import Header from '../includes/header';
import Footer from '../includes/footer';
import useDocumentTitle from '../useDocumentTitle.jsx'

const PrivacyPolicy = () => {
  useDocumentTitle('Privacy Policy')

  return (
    <>
      <Header />

      <section className="polices-banner">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="title">
              <h1>Privacy Policy</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="main-privacy-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div className="term-text">
                <h3>About The Policy</h3>
                <p>At Codex Design Agency the protection of our customers is our top need on
                  https://codexdesignagency.com and we regard it as our own. In spite of the fact that we
                  gather data from our customers, it is just used to make enhancements in our client
                  administrations. Our organization recognizes that the support and utilization of our
                  customers’ data is our obligation. We DO NOT lease or offer the data that our customers give
                  us on the web.</p>
                <p>This arrangement portrays how the individual data of our customer gathered by us is utilized,
                  why we gather it, and how we utilize it. It is inside our strategy that we depict the
                  decisions you can make about how we can gather and utilize your data.</p>
                <h3>Personal Information Collected</h3>
                <p>The data gathered by Codex Design Agency incorporates the customer’s name, email, postage
                  information and telephone number. These are bits of data that the customer gives us while
                  requesting or while sparing the data with our organization. We may likewise utilize the
                  email addresses or street numbers which we get through our mailing framework, for example,
                  our Contact Us Form for reacting to remarks, questions and so on.</p>
                <p>Our organization likewise keeps up records of the things, which have intrigued our customers
                  before, and in addition the customer’s buys on the web.</p>
                <h3>Use of Collected Data</h3>
                <p>The data gathered is utilized as a part of numerous enhanced techniques. Our organization
                  utilizes the data spared by our customers to handle their request. We additionally send them
                  messages to affirm the request and our client administrations may likewise get in touch with
                  them by means of telephone, street number or email if our organization has different
                  inquiries in regards to the request set.</p>
                <p>As a customer, one may likewise get redesigns in regards to our site and administrations
                  which may incorporate a pamphlet and data on advancements. Likewise, we may likewise utilize
                  the data about your interests and buys to help our organization enhance our site plan and
                  the customer’s buying background.</p>
                <h3>Newsletter Opt-out</h3>
                <p>On the off chance that you no more wish to get our pamphlet and limited time correspondences,
                  you may quit getting them by taking after the guidelines incorporated into every bulletin or
                  correspondence or by messaging us at Or calling us at+1-(844)-452-0008</p>
                <h3>Social Media (Features) and Widgets</h3>
                <p>Our Web website incorporates Social Media Features, for example, the Facebook Like catch [and
                  Widgets, for example, the Share this catch or intelligent small scale programs that keep
                  running on our site]. These Features may gather your IP address, which page you are going by
                  on our site, and may set a treat to empower the Feature to work appropriately. Online
                  networking Features and Widgets are either facilitated by a third gathering or facilitated
                  specifically on our Site. Your cooperations with these Features are represented by the
                  protection approach of the organization giving it.</p>
                <h3>3rd Party Sharing</h3>
                <p>Individual data won’t be disclosed to outsiders unless written in agreement. There are no
                  circumstances under which we provide individual data to outsiders.</p>
                <p>We use credit card processing companies to bill you for services. These organizations don’t
                  hold, share, store or utilize by and by identifiable data for some other purposes. We
                  likewise use ZopimChat to provide live customer support chat on our website. These
                  organizations are approved to utilize your own data only as necessary to provide services to
                  us.</p>
                <h3>Security of Personal Information</h3>
                <p>The data of our customers is secure, as it is ensured amid transmission by the utilization of
                  the Secure Sockets Layer (SSL) Software which encrypts the data the customer puts in.</p>
                <p>We take after for the most part acknowledged industry models to secure the individual data
                  submitted to us, both amid transmission and once we get it. No technique for transmission
                  over the Internet, or strategy for electronic stockpiling, is 100% secure, in any case. In
                  this manner, while we endeavor to utilize financially satisfactory intends to ensure your
                  own data, we can’t promise its outright security.</p>
                <p>In the event that you have any inquiries concerning security on our Web website, you can send
                  email us at <a href=" info@codexdesignagency.com">
                    info@codexdesignagency.com</a></p>
                <h3>Access of Registered Accounts</h3>
                <p>The customer can get to their enrolled accounts by marking in on our landing page. Once
                  marked in, the customer has entry to their past records and data that they have as of now
                  submitted to the site. The clients can redesign their data once they have signed into their
                  records by clicking My Account.</p>
                <h3>Changing and Deleting/Unsubscribing Accounts</h3>
                <p>The customer needs to send in an email to ask for the cancelation of membership or
                  solicitation to have their own data erased. This should keep the client from accepting
                  messages correspondence identifying with any online request they may have put at the
                  webpage.</p>
                <p>We will hold your data for whatever length of time that your record is dynamic or as expected
                  to give you benefits. It would be ideal if you get in touch with us in the event that you
                  wish to wipe out your record or demand that we no more utilize your data to give you
                  benefits. We will hold and utilize your data as important to consent to our lawful
                  commitments, resolve debate, and uphold our understandings.</p>
                <h3>Cookies and Their Use</h3>
                <p>Treats are alphanumeric identifiers that are exchanged to the customers’ hard drives through
                  their Web programs. This empowers our frameworks to perceive the customers’ programs and
                  capacity of things in their Shopping Carts amid visits.</p>
                <p>The Help segment of the toolbar on most programs will be more useful in disclosing how to
                  keep the program from tolerating new treats, how to have the program tell the client when
                  another treat is gotten, or how to impair treats inside and out. In any case, treats permit
                  you to exploit the top components at our site, and our organization’s close to home
                  suggestion is that the customer abandons them to be acknowledged.</p>
                <p>Second, we monitor your IP location to determine issues to have our server and to oversee our
                  Web webpage. Your IP location is additionally used to accumulate wide demographic data about
                  you, for example, your area and your Internet administration supplier. We may likewise
                  gather joined data on how our clients are using the site. This may incorporate data with
                  respect to movement designs through the site and pursuit questions. No IP address/log record
                  data is attached to Personally Identifiable Information (PII).</p>
                <p>Third, we log program sorts, access times, URLs from which guests went to our site and URLs
                  saw by guests while on our site. But as generally expressed in this Privacy Policy, we don’t
                  give this data to outsiders, aside from in joined structure.</p>
                <p>The utilization of treats by our accomplices, offshoots, following service organization,
                  administration suppliers is not secured by our protection proclamation. We don’t have
                  admittance or control over these treats. Our accomplices, partners, following service
                  organization, administration suppliers use session ID treats to make it less demanding for
                  you to explore our site.</p>
                <h3>Clear Gifs (Web Beacons/Web Bugs)</h3>
                <p>We utilize a product innovation called clear gifs (a.k.a. Web Beacons/Web Bugs), that help us
                  better oversee content on our webpage by advising us what substance is successful. Clear
                  gifs are little illustrations with an exceptional identifier, comparable in capacity to
                  treats, and are utilized to track the online developments of Web clients. As opposed to
                  treats, which are put away on a client’s PC hard drive, clear gifs are installed
                  undetectably on Web pages and are about the extent of the period toward the end of this
                  sentence. We don’t tie the data accumulated by clear gifs to our clients’ by and by
                  identifiable data.</p>
                <h3>Testimonials</h3>
                <p>With your consent we may post your testimonial alongside your name. In the event that you
                  need your testimonial expelled please get in touch with us.</p>
                <h3>Links to Other Web Sites</h3>
                <p>Our Site incorporates connections to other Web destinations whose protection practices may
                  vary from those of our own. In the event that you submit individual data to any of those
                  locales, your data is represented by their protection proclamations. We urge you to
                  painstakingly read the security proclamation of any Web website you visit.</p>
                <h3>Notice of the Changes in Privacy Policy</h3>
                <p>In the event that we choose to change our protection strategy, we will present those
                  progressions on this security articulation, the landing page, and different spots we esteem
                  suitable with the goal that you know about what data we gather, how we utilize it, and under
                  what circumstances, assuming any, we reveal it. We maintain whatever authority is needed to
                  alter this protection articulation whenever, so please audit it every now and again. On the
                  off chance that we roll out material improvements to this approach, we will inform you here,
                  by email, or by method for a notification on our landing page before the change produces
                  results.</p>
                <h3>Legal Disclaimer</h3>
                <p>We claim all authority to reveal your actually identifiable data as required by law and when
                  we trust that revelation is important to secure our rights and/or agree to a legal
                  continuing, court request, or legitimate procedure served on our Web webpage.</p>
                <h3>Questions</h3>
                <p>On the off chance that you have any inquiries with respect to our Privacy Policy or our
                  utilization of your data, call our toll free number+1-(844)-452-0008or email us on <a
                    href=" info@codexdesignagency.com"> info@codexdesignagency.com</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}

export default PrivacyPolicy
