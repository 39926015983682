import React from 'react'

const addons = () => {
  return (
    <>
          <a href="mailto: info@codexdesignagency.com" className="bt-support-now theme-btn"><i className="fa fa-envelope"></i></a>
          <a href="https://wa.me/+1-(844)-452-0008" className="bt-buy-now theme-btn"><i className="fab fa-whatsapp"></i></a>
          <a href="#" data-target="html" className="scroll-to-target scroll-to-top" ><i className="fas fa-angle-up"></i></a>


          <div className="floatbutton">
              <div className="btns_wrap"><a href="javascript:void(0);" className="chat_wrap" onClick="setButtonURL()">
                <span className="icoo"><i className="fas fa-comment"></i></span> <span>Chat With Us</span> 
                </a><a href="tel:+1-(844)-452-0008" className="call_wrap">
                    <span className="icoo"><i className="fas fa-phone"></i></span> <span>+1-(844)-452-0008</span></a>
                </div>
              {/* <div className="clickbutton">
                  <div className="crossplus">START YOUR JOURNEY TO FAME</div>
              </div>
              <div className="banner-form">
                  <h3>Chat With Us to<br/>Avail 50% Discount</h3>
                  <div className="banform">
                      <div className="container">
                          <div className="row">
                              <div className="ban-form">
                                  <form action="https://leads.infinityprojectmanager.com/brand/codexdesignagency/lead" className="popup-form CrudForm cmxform">
                                    <input type="hidden" name="brand_name" defaultValue="codexdesignagency.com"/> 
                                    <input type="hidden" id="lead_area" name="lead_area" defaultValue="https://codexdesignagency.com/"/>
                                      <div className="row">
                                          <div className="col-lg-12 col-12 col-sm-12 col-md-12">
                                              <div className="fldset"><input name="name" placeholder="Enter your name" required/></div>
                                          </div>
                                          <div className="col-lg-12 col-12 col-sm-12 col-md-12">
                                              <div className="fldset"><input type="email" name="Email" placeholder="Enter email here" required/></div>
                                          </div>
                                          <div className="col-lg-12 col-12 col-sm-12 col-md-12">
                                              <div className="fldset"><input type="tel" name="phone" className="phone-country masking contact" minLength="9" maxLength="11" placeholder="Phone*" onKeyPress="return isNumber(event)" data-inputmask="'mask': '(999) 999-9999'" required/> <span className="bg-danger col-lg-offset-3 contact_error" >Contact No Incorrect</span></div>
                                          </div>
                                          <div className="col-lg-12 col-12 col-sm-12 col-md-12">
                                              <div className="fldset"><textarea name="message" rows="7" placeholder="Talk About Your Project"></textarea></div>
                                          </div>
                                          <div className="col-lg-12 col-12 col-sm-12 col-md-12">
                                              <div className="fldset"><input type="submit" placeholder="Connect With Us" required/></div>
                                          </div>
                                      </div>
                                  </form>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div> */}
              </div>
    </>
  )
}

export default addons
