import React from 'react'
import web_design from '../assets/images/web-design.png';
import icon2 from '../assets/images/icon2.png';
import icon4 from '../assets/images/icon4.png';
import icon5 from '../assets/images/icon5.png';
import ppc from '../assets/images/ppc.png';
import digital_marketing from '../assets/images/digital-marketing.png';
import animation from '../assets/images/animation.png';
import { Link } from 'react-router-dom';
const services = () => {
  return (
    <div>
          <section className="services">
              <div className="container">
                  <h2>Our Broad Range of Services</h2>
                  <div className="row">
                      <div className="col-lg-3 col-12 col-md-6">
                          <div className="service-box shadow-lg"><img src={web_design} alt="img not found"/>
                              <h4>Web Design</h4>
                              <div className="ser-learn-more">
                                  <p>From creating a layout to developing an attractive, user-friendly website, we do it all!</p>
                                  <Link  to="/web-design">Learn More <i className="fas fa-chevron-circle-right"></i></Link>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-3 col-12 col-md-6">
                          <div className="service-box shadow-lg"><img src={icon2} alt="img not found"/>
                              <h4>Web Development</h4>
                              <div className="ser-learn-more">
                                  <p>We use coding and programming to make your website functional and interactive.</p><Link  to="/web-design">Learn More <i className="fas fa-chevron-circle-right"></i></Link>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-3 col-12 col-md-6">
                          <div className="service-box shadow-lg"><img src={icon4} alt="img not found" />
                              <h4>Logo Design</h4>
                              <div className="ser-learn-more">
                                  <p>Our web developers will create a unique and recognizable symbol or emblem that represents
                                      your company or brand.</p><Link to="/logo-design">Learn More <i className="fas fa-chevron-circle-right"></i></Link>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-3 col-12 col-md-6">
                          <div className="service-box shadow-lg"><img src={ppc} className="img-fluid" alt="img not found"/>
                              <h4>PPC</h4>
                              <div className="ser-learn-more">
                                  <p>Achieve your business desired outcomes by increasing leads &amp; web traffic. .</p><Link to="/ppc">Learn More <i className="fas fa-chevron-circle-right"></i></Link>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-lg-3 col-12 col-md-6">
                          <div className="service-box shadow-lg"><img src={digital_marketing} alt="img not found"/>
                              <h4>Digital Marketing</h4>
                              <div className="ser-learn-more">
                                  <p>This is where we’ll promote your company or brand online to improve your online visibility.
                                  </p><Link to="/digital-marketing">Learn More <i className="fas fa-chevron-circle-right"></i></Link>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-3 col-12 col-md-6">
                          <div className="service-box shadow-lg"><img src={icon5} alt="img not found"/>
                              <h4>SEO Services</h4>
                              <div className="ser-learn-more">
                                  <p>Our web design agency ensures that your website ranks higher in search engine results pages.
                                  </p><Link to="/seo">Learn More <i className="fas fa-chevron-circle-right"></i></Link>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-3 col-12 col-md-6">
                          <div className="service-box shadow-lg"><img src={icon2} alt="img not found"/>
                              <h4>Content Management</h4>
                              <div className="ser-learn-more">
                                  <p>Our team will create unique and informative content for your website to attract your target
                                      audience</p><Link to="/creative-writting">Learn More <i className="fas fa-chevron-circle-right"></i></Link>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-3 col-12 col-md-6">
                          <div className="service-box shadow-lg"><img src={animation} alt="img not found"/>
                              <h4>Animation</h4>
                              <div className="ser-learn-more">
                                  <p>Our animation video company offers an all-in-one solution to build your brand image.</p><Link to="/animation">Learn More <i className="fas fa-chevron-circle-right"></i></Link>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
    </div>
  )
}

export default services
