import React from 'react'
import brand_img from '../assets/images/brand-img.png';
import review_banner_img from '../assets/images/review-banner-img.png';

import Header from '../includes/header';
import Footer from '../includes/footer';

import client_1 from '../assets/images/client-1.png';
import client_2 from '../assets/images/client-2.png';
import client_3 from '../assets/images/client-3.png';
import client_4 from '../assets/images/client-4.png';
import client_5 from '../assets/images/client-5.png';
import client_6 from '../assets/images/client-6.png';
import client_7 from '../assets/images/client-7.png';
import client_8 from '../assets/images/client-8.png';
import client_9 from '../assets/images/client-9.png';
import client_10 from '../assets/images/client-10.png';
import client_11 from '../assets/images/client-11.png';
import client_12 from '../assets/images/client-12.png';
import client_13 from '../assets/images/client-13.png';
import client_14 from '../assets/images/client-14.png';
import client_15 from '../assets/images/client-15.png';
import client_16 from '../assets/images/client-16.png';
import client_17 from '../assets/images/client-17.png';
import client_18 from '../assets/images/client-18.png';
import client_19 from '../assets/images/client-19.png';
import client_20 from '../assets/images/client-20.png';
import client_21 from '../assets/images/client-21.png';
import client_22 from '../assets/images/client-22.png';
import client_23 from '../assets/images/client-23.png';
import client_24 from '../assets/images/client-24.png';
import client_25 from '../assets/images/client-25.png';
import client_26 from '../assets/images/client-26.png';
import client_27 from '../assets/images/client-27.png';
import client_28 from '../assets/images/client-27.png';
import client_29 from '../assets/images/client-28.png';
import client_30 from '../assets/images/client-29.png';
import useDocumentTitle from '../useDocumentTitle.jsx'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Reviews = () => {
    useDocumentTitle('Reviews')

  return (
    <>
          <Header />
          <section className="inner-banner-sec">
              <div className="container">
                  <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="banner-content">
                              <h1>Reviews From Clients</h1>
                              <p>We receive immense love and appreciation for our stellar web design and development and
                                  digital marketing services worldwide. See what our clients have to say about our services.
                              </p>
                              <div className="brand-rating">
                                  <div className="rating">
                                      <h6>5.0 </h6>
                                      <ul>
                                          <li><i className="fa-solid fa-star"></i></li>
                                          <li><i className="fa-solid fa-star"></i></li>
                                          <li><i className="fa-solid fa-star"></i></li>
                                          <li><i className="fa-solid fa-star"></i></li>
                                          <li><i className="fa-solid fa-star"></i></li>
                                      </ul>
                                      <h6> rating on</h6>
                                  </div>
                                  <div className="brand-img">
                                      <LazyLoadImage src={brand_img} alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                              </div>
                              <div className="banner-btn">
                                  <a href="javascript:void(0);" className="btn btn-contact">Connect with us now!</a>
                                  <a href="javascript:void(0);" className="btn btn-chat"><i className="fa-solid fa-messages"></i></a>
                              </div>
                          </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="main-img">
                              <LazyLoadImage src={review_banner_img}className="img-fluid" alt="img not found" effect="blur"/>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="clients-review-sec">
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">
                          <div className="content">
                              <h2>Reviews From Clients</h2>
                          </div>
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <div className="reviews-main">
                              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                  <li className="nav-item" role="presentation">
                                      <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill"
                                          data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                                          aria-selected="true">Web Development & Design</button>
                                  </li>
                                  <li className="nav-item" role="presentation">
                                      <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                                          data-bs-target="#pills-profile" type="button" role="tab"
                                          aria-controls="pills-profile" aria-selected="false">Social Media</button>
                                  </li>
                                  <li className="nav-item" role="presentation">
                                      <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill"
                                          data-bs-target="#pills-contact" type="button" role="tab"
                                          aria-controls="pills-contact" aria-selected="false">Content Writing</button>
                                  </li>
                                  <li className="nav-item" role="presentation">
                                      <button className="nav-link" id="pills-SEO-tab" data-bs-toggle="pill"
                                          data-bs-target="#pills-SEO" type="button" role="tab" aria-controls="pills-SEO"
                                          aria-selected="false">SEO</button>
                                  </li>
                                  <li className="nav-item" role="presentation">
                                      <button className="nav-link" id="pills-PPC-tab" data-bs-toggle="pill"
                                          data-bs-target="#pills-PPC" type="button" role="tab" aria-controls="pills-PPC"
                                          aria-selected="false">PPC</button>
                                  </li>
                              </ul>
                              <div className="tab-content" id="pills-tabContent">
                                  <div className="tab-pane fade show active" id="pills-home" role="tabpanel"
                                      aria-labelledby="pills-home-tab" tabIndex="0">
                                      <div className="row justify-content-center">
                                          <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                              <div className="client-box">
                                                  <div className="box-top">
                                                      <div className="main-img">
                                                          <LazyLoadImage src={client_1} alt="img not found" effect="blur"
                                                              className="img-fluid"/>
                                                      </div>
                                                      <div className="info">
                                                          <h4>Emily Clark</h4>
                                                          <ul>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                                  <div className="box-bottom">
                                                      <p>"I was searching for a website design company that would understand
                                                          my vision and bring it to life. Codex Design Agency exceeded my
                                                          expectations. The final product was exactly what I wanted and more.
                                                          I highly recommend their services."</p>
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                              <div className="client-box">
                                                  <div className="box-top">
                                                      <div className="main-img">
                                                          <LazyLoadImage src={client_2} alt="img not found" effect="blur"
                                                              className="img-fluid"/>
                                                      </div>
                                                      <div className="info">
                                                          <h4>Anna Middleton</h4>
                                                          <ul>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                                  <div className="box-bottom">
                                                      <p>"Working with Codex Design Agency was an absolute pleasure. Their
                                                          team was highly responsive, and professional, and made the entire
                                                          process seamless. I am beyond impressed with the final product and
                                                          can't wait to show it off to my clients."</p>
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                              <div className="client-box">
                                                  <div className="box-top">
                                                      <div className="main-img">
                                                          <LazyLoadImage src={client_3} alt="img not found" effect="blur"
                                                              className="img-fluid"/>
                                                      </div>
                                                      <div className="info">
                                                          <h4>Bruce Williams</h4>
                                                          <ul>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                                  <div className="box-bottom">
                                                      <p>"Codex Design Agency transformed my outdated website into a modern,
                                                          user-friendly platform. Their team was always available to answer
                                                          any questions and made sure that I was satisfied every step of the
                                                          way."</p>
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                              <div className="client-box">
                                                  <div className="box-top">
                                                      <div className="main-img">
                                                          <LazyLoadImage src={client_4} alt="img not found" effect="blur"
                                                              className="img-fluid"/>
                                                      </div>
                                                      <div className="info">
                                                          <h4>Sam Willis</h4>
                                                          <ul>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                                  <div className="box-bottom">
                                                      <p>"I was impressed with the level of creativity and attention to detail
                                                          shown by the team at Codex Design Agency. They worked closely with
                                                          me to bring my vision to life and the final product was stunning."
                                                      </p>
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                              <div className="client-box">
                                                  <div className="box-top">
                                                      <div className="main-img">
                                                          <LazyLoadImage src={client_5} alt="img not found" effect="blur"
                                                              className="img-fluid"/>
                                                      </div>
                                                      <div className="info">
                                                          <h4>Samantha Addams</h4>
                                                          <ul>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                                  <div className="box-bottom">
                                                      <p>"I was skeptical about working with a website development company,
                                                          but Codex Design Agency put all my fears to rest. They were able to
                                                          take my ideas and turn them into a beautiful, functional website
                                                          that exceeded my expectations."</p>
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                              <div className="client-box">
                                                  <div className="box-top">
                                                      <div className="main-img">
                                                          <LazyLoadImage src={client_6} alt="img not found" effect="blur"
                                                              className="img-fluid"/>
                                                      </div>
                                                      <div className="info">
                                                          <h4>Carl Andrews</h4>
                                                          <ul>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                                  <div className="box-bottom">
                                                      <p>"I highly recommend Codex Design Agency for their exceptional
                                                          customer service and top-notch website development skills. Their
                                                          team went above and beyond to ensure that my website was exactly
                                                          what I wanted and I couldn't be happier with the final product."</p>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="tab-pane fade" id="pills-profile" role="tabpanel"
                                      aria-labelledby="pills-profile-tab" tabIndex="0">
                                      <div className="row justify-content-center">
                                          <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                              <div className="client-box">
                                                  <div className="box-top">
                                                      <div className="main-img">
                                                          <LazyLoadImage src={client_7} alt="img not found" effect="blur"
                                                              className="img-fluid"/>
                                                      </div>
                                                      <div className="info">
                                                          <h4>Daisy Bullock</h4>
                                                          <ul>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                                  <div className="box-bottom">
                                                      <p>I am in love with Codex Design Agency' social media marketing
                                                          services. They helped expand my business online in the best way
                                                          possible!</p>
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                              <div className="client-box">
                                                  <div className="box-top">
                                                      <div className="main-img">
                                                          <LazyLoadImage src={client_8} alt="img not found" effect="blur"
                                                              className="img-fluid"/>
                                                      </div>
                                                      <div className="info">
                                                          <h4>Betty Peterson</h4>
                                                          <ul>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                                  <div className="box-bottom">
                                                      <p>I want to take a minute and appreciate Codex Design Agency’ social
                                                          media marketing services. Their team is highly experienced and
                                                          professional!</p>
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                              <div className="client-box">
                                                  <div className="box-top">
                                                      <div className="main-img">
                                                          <LazyLoadImage src={client_9} alt="img not found" effect="blur"
                                                              className="img-fluid"/>
                                                      </div>
                                                      <div className="info">
                                                          <h4>Derick Wills</h4>
                                                          <ul>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                                  <div className="box-bottom">
                                                      <p>My business was doing well but not as well as I had expected. That's
                                                          when I hired Codex Design Agency. Their team offered spectacular
                                                          digital marketing services for my business and I saw immediate and
                                                          positive results</p>
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                              <div className="client-box">
                                                  <div className="box-top">
                                                      <div className="main-img">
                                                          <LazyLoadImage src={client_10}alt="img not found" effect="blur"
                                                              className="img-fluid"/>
                                                      </div>
                                                      <div className="info">
                                                          <h4>Sarah K</h4>
                                                          <ul>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                                  <div className="box-bottom">
                                                      <p>Codex Design Agency has helped take my business to the next level!
                                                          Their social media marketing strategy increased my online visibility
                                                          and brought in a surge of new customers. I highly recommend their
                                                          services."</p>
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                              <div className="client-box">
                                                  <div className="box-top">
                                                      <div className="main-img">
                                                          <LazyLoadImage src={client_12} alt="img not found" effect="blur"
                                                              className="img-fluid"/>
                                                      </div>
                                                      <div className="info">
                                                          <h4>Emily R</h4>
                                                          <ul>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                                  <div className="box-bottom">
                                                      <p>Codex Design Agency exceeded my expectations in every way. Their
                                                          social media content marketing skills brought my small business to
                                                          life and helped me connect with new customers. I'm grateful for
                                                          their help and highly recommend their services."</p>
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                              <div className="client-box">
                                                  <div className="box-top">
                                                      <div className="main-img">
                                                          <LazyLoadImage src={client_11}alt="img not found" effect="blur"
                                                              className="img-fluid"/>
                                                      </div>
                                                      <div className="info">
                                                          <h4>Olivia P</h4>
                                                          <ul>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                                  <div className="box-bottom">
                                                      <p>Codex Design Agency helped me take my creative business to the next
                                                          level. Their social media marketing brought in new clients and
                                                          increased my online exposure. I couldn't be happier with the results
                                                          and highly recommend their services."</p>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="tab-pane fade" id="pills-contact" role="tabpanel"
                                      aria-labelledby="pills-contact-tab" tabIndex="0">
                                      <div className="row justify-content-center">
                                          <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                              <div className="client-box">
                                                  <div className="box-top">
                                                      <div className="main-img">
                                                          <LazyLoadImage src={client_13} alt="img not found" effect="blur"
                                                              className="img-fluid"/>
                                                      </div>
                                                      <div className="info">
                                                          <h4>Becky Hall</h4>
                                                          <ul>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                                  <div className="box-bottom">
                                                      <p>"I am so happy about the quality of the content writing that Codex
                                                          Design Agency produced for my website. Since implementing the new
                                                          landing page, I've seen a significant increase in traffic and
                                                          conversions." </p>
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                              <div className="client-box">
                                                  <div className="box-top">
                                                      <div className="main-img">
                                                          <LazyLoadImage src={client_14} alt="img not found" effect="blur"
                                                              className="img-fluid"/>
                                                      </div>
                                                      <div className="info">
                                                          <h4>Anna Smith</h4>
                                                          <ul>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                                  <div className="box-bottom">
                                                      <p>"As someone who struggles to write compelling content, I was thrilled
                                                          to find Codex Design Agency' copywriting service. I'm so grateful
                                                          for their help.”</p>
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                              <div className="client-box">
                                                  <div className="box-top">
                                                      <div className="main-img">
                                                          <LazyLoadImage src={client_15} alt="img not found" effect="blur"
                                                              className="img-fluid"/>
                                                      </div>
                                                      <div className="info">
                                                          <h4>Henry Anderson</h4>
                                                          <ul>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                                  <div className="box-bottom">
                                                      <p>"Codex Design Agency exceeded my expectations. They take complex
                                                          technical information and turn it into clear, concise documentation.
                                                          I appreciated their attention to detail and quick turnaround times."
                                                      </p>
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                              <div className="client-box">
                                                  <div className="box-top">
                                                      <div className="main-img">
                                                          <LazyLoadImage src={client_16} alt="img not found" effect="blur"
                                                              className="img-fluid"/>
                                                      </div>
                                                      <div className="info">
                                                          <h4>John Paul</h4>
                                                          <ul>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                                  <div className="box-bottom">
                                                      <p>"Not only did the content attract more readers, but it also helped
                                                          position me as an authority in my industry. Thank you Codex Design
                                                          Agency."</p>
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                              <div className="client-box">
                                                  <div className="box-top">
                                                      <div className="main-img">
                                                          <LazyLoadImage src={client_17} alt="img not found" effect="blur"
                                                              className="img-fluid"/>
                                                      </div>
                                                      <div className="info">
                                                          <h4>Yvonne Mercy</h4>
                                                          <ul>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                                  <div className="box-bottom">
                                                      <p>"I was struggling to come up with effective ad content for my latest
                                                          product launch, but Codex Design Agency saved the day. They helped
                                                          me exceed my sales goals."</p>
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                              <div className="client-box">
                                                  <div className="box-top">
                                                      <div className="main-img">
                                                          <LazyLoadImage src={client_18}alt="img not found" effect="blur"
                                                              className="img-fluid"/>
                                                      </div>
                                                      <div className="info">
                                                          <h4>Sean Benjamin</h4>
                                                          <ul>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                                  <div className="box-bottom">
                                                      <p>"Codex Design Agency' attention to detail and ability to write
                                                          compelling product descriptions has led to a significant increase in
                                                          sales and customer engagement."</p>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="tab-pane fade" id="pills-SEO" role="tabpanel" aria-labelledby="pills-SEO-tab"
                                      tabIndex="0">
                                      <div className="row justify-content-center">
                                          <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                              <div className="client-box">
                                                  <div className="box-top">
                                                      <div className="main-img">
                                                          <LazyLoadImage src={client_19} alt="img not found" effect="blur"
                                                              className="img-fluid"/>
                                                      </div>
                                                      <div className="info">
                                                          <h4>Eric Stevenson</h4>
                                                          <ul>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                                  <div className="box-bottom">
                                                      <p>"Thanks to Codex Design Agency' SEO service, our website's traffic
                                                          has increased dramatically. We are now ranking higher in search
                                                          results and getting more qualified leads." </p>
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                              <div className="client-box">
                                                  <div className="box-top">
                                                      <div className="main-img">
                                                          <LazyLoadImage src={client_20} alt="img not found" effect="blur"
                                                              className="img-fluid"/>
                                                      </div>
                                                      <div className="info">
                                                          <h4>Stacy Brown</h4>
                                                          <ul>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                                  <div className="box-bottom">
                                                      <p>"Codex Design Agency' SEO team is top-notch! They were able to
                                                          identify and fix issues with our website's structure and content,
                                                          resulting in a significant increase in organic traffic."</p>
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                              <div className="client-box">
                                                  <div className="box-top">
                                                      <div className="main-img">
                                                          <LazyLoadImage src={client_21} alt="img not found" effect="blur"
                                                              className="img-fluid"/>
                                                      </div>
                                                      <div className="info">
                                                          <h4>Paula Gerald</h4>
                                                          <ul>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                                  <div className="box-bottom">
                                                      <p>"We've tried other SEO services in the past, but none have been as
                                                          effective as Codex Design Agency. Their team is professional,
                                                          knowledgeable, and dedicated to helping us achieve our online
                                                          marketing goals."</p>
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                              <div className="client-box">
                                                  <div className="box-top">
                                                      <div className="main-img">
                                                          <LazyLoadImage src={client_22} alt="img not found" effect="blur"
                                                              className="img-fluid"/>
                                                      </div>
                                                      <div className="info">
                                                          <h4>Lucas Henderson</h4>
                                                          <ul>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                                  <div className="box-bottom">
                                                      <p>"Codex Design Agency has helped us improve our online presence and
                                                          increase our brand awareness. We've seen steady growth in website
                                                          traffic and are now ranking for important keywords in our industry."
                                                      </p>
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                              <div className="client-box">
                                                  <div className="box-top">
                                                      <div className="main-img">
                                                          <LazyLoadImage src={client_23} alt="img not found" effect="blur"
                                                              className="img-fluid"/>
                                                      </div>
                                                      <div className="info">
                                                          <h4>Billy Thomas</h4>
                                                          <ul>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                                  <div className="box-bottom">
                                                      <p>"We highly recommend Codex Design Agency for their outstanding SEO
                                                          service. They take a personalized approach to each client and work
                                                          closely with us to achieve the best possible results."</p>
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                              <div className="client-box">
                                                  <div className="box-top">
                                                      <div className="main-img">
                                                          <LazyLoadImage src={client_24} alt="img not found" effect="blur"
                                                              className="img-fluid"/>
                                                      </div>
                                                      <div className="info">
                                                          <h4>Gen Thompson</h4>
                                                          <ul>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                                  <div className="box-bottom">
                                                      <p>"Working with Codex Design Agency has been a game-changer for our
                                                          business. They have helped us optimize our website for search
                                                          engines and improve our online visibility. We are now attracting
                                                          more customers and seeing a positive ROI on our SEO investment."</p>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="tab-pane fade" id="pills-PPC" role="tabpanel" aria-labelledby="pills-PPC-tab"
                                      tabIndex="0">
                                      <div className="row justify-content-center">
                                          <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                              <div className="client-box">
                                                  <div className="box-top">
                                                      <div className="main-img">
                                                          <LazyLoadImage src={client_25} alt="img not found" effect="blur"
                                                              className="img-fluid"/>
                                                      </div>
                                                      <div className="info">
                                                          <h4>Harry Stephen</h4>
                                                          <ul>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                                  <div className="box-bottom">
                                                      <p>"Codex Design Agency' PPC service has exceeded all of our expectations. The team is knowledgeable and efficient, and they consistently deliver results."</p>
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                              <div className="client-box">
                                                  <div className="box-top">
                                                      <div className="main-img">
                                                          <LazyLoadImage src={client_26} alt="img not found" effect="blur"
                                                              className="img-fluid"/>
                                                      </div>
                                                      <div className="info">
                                                          <h4>Drake Green</h4>
                                                          <ul>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                                  <div className="box-bottom">
                                                      <p>"The PPC campaign that Codex Design Agency created for our business has brought in a significant increase in leads and sales. We highly recommend their services!"</p>
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                              <div className="client-box">
                                                  <div className="box-top">
                                                      <div className="main-img">
                                                          <LazyLoadImage src={client_27} alt="img not found" effect="blur"
                                                              className="img-fluid"/>
                                                      </div>
                                                      <div className="info">
                                                          <h4>John Stewart</h4>
                                                          <ul>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                                  <div className="box-bottom">
                                                      <p>"Codex Design Agency has helped us improve our PPC campaigns and save money on our advertising budget. Their expertise and attention to detail are unmatched."</p>
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                              <div className="client-box">
                                                  <div className="box-top">
                                                      <div className="main-img">
                                                          <LazyLoadImage src={client_28} alt="img not found" effect="blur"
                                                              className="img-fluid"/>
                                                      </div>
                                                      <div className="info">
                                                          <h4> Daisy Williams</h4>
                                                          <ul>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                                  <div className="box-bottom">
                                                      <p>"We've been working with Codex Design Agency for several months now,
                                                          and we've seen a dramatic improvement in our PPC conversion rates.
                                                          Their team is reliable and always accessible."</p>
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                              <div className="client-box">
                                                  <div className="box-top">
                                                      <div className="main-img">
                                                          <LazyLoadImage src={client_29} alt="img not found" effect="blur"
                                                              className="img-fluid"/>
                                                      </div>
                                                      <div className="info">
                                                          <h4>Anna Smith</h4>
                                                          <ul>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                                  <div className="box-bottom">
                                                      <p>"Codex Design Agency is the best PPC service we've ever used. They
                                                          take the time to understand our business needs and tailor their
                                                          strategies to meet our goals."</p>
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                              <div className="client-box">
                                                  <div className="box-top">
                                                      <div className="main-img">
                                                          <LazyLoadImage src={client_30} alt="img not found" effect="blur"
                                                              className="img-fluid"/>
                                                      </div>
                                                      <div className="info">
                                                          <h4>Bobby Cooper</h4>
                                                          <ul>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                              <li><i className="fa-solid fa-star"></i></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                                  <div className="box-bottom">
                                                      <p>"We're so glad we found Codex Design Agency! Their PPC service has
                                                          helped us reach our target audience and increase our ROI. They're a
                                                          valuable partner in our marketing efforts."</p>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <Footer />

    </>
  )
}

export default Reviews
