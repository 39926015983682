import React from 'react'
import annamidd from '../assets/images/annamidd.png';
import star from '../assets/images/star.png';
import review_img from '../assets/images/review-img.png';
import jhon from '../assets/images/jhon.png';
import tl6 from '../assets/images/tl6.png';
import emily from '../assets/images/emily.png';


const testimonial = () => {
  return (
    <>
          <section className="testimonials">
              <div className="container">
                  <div className="row text-center">
                      <div className="head_txt">
                          <h2>Testimonials</h2>
                          <p>Here’s how our clients appreciate our services:</p>
                      </div>
                  </div>
                  <div className="row">
                      <div className="col" data-aos="fade-right">
                          <ul className="nav nav-tabs justify-content-center" id="myTab" role="tablist">
                              <li className="nav-item" role="presentation"><button className="nav-link shadow-sm active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Web Design</button></li>
                              <li className="nav-item" role="presentation"><button className="nav-link shadow-sm" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false" tabIndex="-1">Web Development</button></li>
                              <li className="nav-item" role="presentation"><button className="nav-link shadow-sm" id="testi1-tab" data-bs-toggle="tab" data-bs-target="#testi1" type="button" role="tab" aria-controls="testi1" aria-selected="false" tabIndex="-1">Logo Design</button></li>
                              <li className="nav-item" role="presentation"><button className="nav-link shadow-sm" id="testimonials-tab1" data-bs-toggle="tab" data-bs-target="#tab1" type="button" role="tab" aria-controls="testimonials" aria-selected="false" tabIndex="-1">Seo Serivces</button></li>
                          </ul>
                      </div>
                  </div>
                  <div className="row">
                      <div className="col">
                          <div className="tab-content" id="myTabContent" data-aos="fade-up">
                              <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                  <div className="review_box mt-5">
                                      <div className="d-flex align-items-center justify-content-center">
                                          <div className="img_area">
                                              <div className="px-4">
                                                  <img src={annamidd} alt="img not found"/>
                                              </div>
                                              <div>
                                                  <h4>Anna Midelton</h4>
                                                  <img src={star} alt="img not found"/>
                                              </div>
                                          </div>
                                          <div className="mx-5">
                                              <img src={review_img} alt="img not found"/>
                                          </div>
                                      </div>
                                      <p>"Working with Codex Design Agency was an absolute pleasure. Their team was highly responsive, and professional, and made the entire process seamless. I am beyond impressed with the final product and can't wait
                                          to show it off to my clients."</p>
                                  </div>
                              </div>
                              <div className="tab-pane fade  " id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                  <div className="review_box mt-5">
                                      <div className="d-flex align-items-center justify-content-center">
                                          <div className="img_area">
                                              <div className="px-4">
                                                  <img src={jhon} alt="img not found"/>
                                              </div>
                                              <div>
                                                  <h4>John Clerk</h4>
                                                  <img src={star} alt="img not found"/>
                                              </div>
                                          </div>
                                          <div className="mx-5">
                                              <img src={review_img}alt="img not found"/>
                                          </div>
                                      </div>
                                      <p>"Thanks to Codex Design Agency, our online store is now faster and more user-friendly."</p>
                                  </div>
                              </div>
                              <div className="tab-pane fade  " id="testi1" role="tabpanel" aria-labelledby="testi1-tab">
                                  <div className="review_box mt-5">
                                      <div className="d-flex align-items-center justify-content-center">
                                          <div className="img_area">
                                              <div className="px-4">
                                                  <img src={tl6}alt="img not found"/>
                                              </div>
                                              <div>
                                                  <h4>Emily Stark</h4>
                                                  <img src={star} alt="img not found"/>
                                              </div>
                                          </div>
                                          <div className="mx-5">
                                              <img src={review_img} alt="img not found"/>
                                          </div>
                                      </div>
                                      <p>"I couldn't be happier with the logo Codex Design Agency designed for my business. It's bold, modern, and perfectly captures the essence of my brand."</p>
                                  </div>
                              </div>
                              <div className="tab-pane fade  " id="tab1" role="tabpanel" aria-labelledby="testimonials-tab1">
                                  <div className="review_box mt-5">
                                      <div className="d-flex align-items-center justify-content-center">
                                          <div className="img_area">
                                              <div className="px-4">
                                                  <img src={emily} alt="img not found"/>
                                              </div>
                                              <div>
                                                  <h4>Elizabeth Doe</h4>
                                                  <img src={star} alt="img not found"/>
                                              </div>
                                          </div>
                                          <div className="mx-5">
                                              <img src={review_img} alt="img not found"/>
                                          </div>
                                      </div>
                                      <p>"Codex Design Agency did an excellent job improving our SEO strategy. Within the first month of working with them, we saw an increase in website traffic and leads. Their team is professional, knowledgeable, and easy to work with."</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
    </>
  )
}

export default testimonial
