import React from 'react'

import Header from '../includes/header';
import Footer from '../includes/footer';
import About from '../component/about';
import Pricing from '../component/pricing';
import useDocumentTitle from '../useDocumentTitle.jsx'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
const Packages = () => {
    useDocumentTitle('Packages')

  return (
    <>
          <Header/>
          <section className="inner-banner-sec big-banner">
              <div className="container">
                  <div className="row align-items-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <div className="banner-content">
                              <h1>Select Your Favorite Package!</h1>
                              <p>We are a one-stop solution for all your website development and branding needs! Let your
                                  business attain great
                                  heights with our custom web design & development services.</p>
                              <div className="banner-btn">
                                  <a href="javascript:void(0);" className="btn btn-contact">Get a Quote</a>
                                  <a href="javascript:void(0);" className="btn btn-chat"><i className="fa-solid fa-messages"></i></a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <About />
          <Pricing/>
          <Footer />
    </>
  )
}

export default Packages
