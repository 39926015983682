import React from 'react'


import Header from '../includes/header';
import Footer from '../includes/footer';


import icon_1 from '../assets/images/icon-1.png';
import icon_2 from '../assets/images/icon-2.png';
import icon_3 from '../assets/images/icon-3.png';
import icon_4 from '../assets/images/icon-4.png';
import pricing_bg from '../assets/images/pricing-bg.png';
import useDocumentTitle from '../useDocumentTitle.jsx'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';



const ComboPackages = () => {
    useDocumentTitle('Combo-Packages')

    const mystyle = {
        background: "../assets/images/packages-banner-img.png",
    };
  return (
    <>
          <Header />

          <section className="inner-banner-sec big-banner" style={mystyle} >
              <div className="container">
                  <div className="row align-items-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <div className="banner-content">
                              <h1>We are Loved & Supported by our Customer Base because of our High-End Work</h1>
                              <p>We highly regard our customers' reviews as it helps us to do better in the future and apply
                                  any changes in our work processes if required.</p>
                              <div className="banner-btn">
                                  <a href="javascript:$zopim.livechat.window.show();" className="btn btn-contact">Get a Quote</a>
                                  <a href="javascript:$zopim.livechat.window.show();" className="btn btn-chat"><i className="fa-solid fa-messages"></i></a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <section className="about-sec-wrap">
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">
                          <div className="content">
                              <h2>About Us</h2>
                              <p>Welcome to the Codex Design Agency pricing plan page. We are a team of experts dedicated to
                                  providing high-quality web development and digital marketing services to businesses of all
                                  sizes. Our pricing plans are designed to fit the unique needs and budgets of our clients.
                                  Whether you're just starting or looking to scale your business, we have a plan that will
                                  help you achieve your goals</p>
                          </div>
                      </div>
                  </div>
                  <div className="row justify-content-xl-between justify-content-center">
                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
                          <div className="about-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_1} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>275+</h4>
                                  <p>Projects Completed.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
                          <div className="about-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_2} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>89%</h4>
                                  <p>Customer Retention</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
                          <div className="about-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_3} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>120+</h4>
                                  <p>Professionals</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
                          <div className="about-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_4} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>20+</h4>
                                  <p>Years of Experience</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
                          <div className="about-card">
                              <h5>Schedule A Call
                                  <br/>
                                      Now!
                              </h5>
                              <div className="desc">
                                  <a href="javascript:$zopim.livechat.window.show();" className="btn btn-call"><i className="fa-solid fa-phone-volume"></i> Click
                                      Here!</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="pricing-sec-wrap">
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">
                          <div className="content">
                              <h2>Pricing Plans</h2>
                          </div>
                      </div>
                  </div>
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="pricing-card">
                              <div className="main-box">
                                  <LazyLoadImage src={pricing_bg}alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="title">
                                  <h2>Basic Combo Package</h2>
                              </div>
                              <div className="inner-box">
                                  <h5>STARTING AT</h5>
                                  <h4>$445</h4>
                                  <ul>
                                      <li>5 Custom Logo Design Concepts</li>
                                      <li>By 2 Designers</li>
                                      <li>Icon Design</li>
                                      <li>Business Card, Letterhead, Envelope, Fax Template</li>
                                      <li>MS Word Letterhead</li>
                                      <li>5 Page Website</li>
                                      <li>Mobile Responsive</li>
                                      <li>Team of Expert Designers Developers</li>
                                      <li>8 Stock images</li>
                                      <li>5 Banner Designs</li>
                                      <li>jQuery Sliders</li>
                                      <li>Free Google Friendly Sitemap</li>
                                      <li>Complete Deployment</li>
                                      <li>Facebook Page Design</li>
                                      <li>Twitter Page Design</li>
                                      <li>YouTube Page Design</li>
                                      <li>All Final File Formats</li>
                                      <li>Dedicated Account Manager</li>
                                      <li>100% Ownership Rights</li>
                                      <li>100% Unique Design Guarantee</li>
                                      <li>100% Money Back Guarantee *</li>
                                  </ul>

                                  <div className="delivery">
                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                  </div>
                                  <div className="pricing-btns">
                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="pricing-card">
                              <div className="main-box">
                                  <LazyLoadImage src={pricing_bg} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="title">
                                  <h2>Startup Combo Package</h2>
                              </div>
                              <div className="inner-box">
                                  <h5>STARTING AT</h5>
                                  <h4>$995</h4>
                                  <ul>
                                      <li>Unlimited Logo Design Concepts</li>
                                      <li>+ Social Media Design</li>
                                      <li>Mobile Responsive</li>
                                      <li>3 Dedicated Designers</li>
                                      <li>Icon Design</li>
                                      <li>Business Card, Letterhead, Envelope</li>
                                      <li>MS Word Letterhead</li>
                                      <li>UNLIMITED Pages Website</li>
                                      <li>Content Management System (CMS)</li>
                                      <li>5 Stock Photos + 3 Banner Designs</li>
                                      <li>Complete W3C Certified HTML</li>
                                      <li>Complete Deployment</li>
                                      <li>Facebook Page Design</li>
                                      <li>Twitter Page Design</li>
                                      <li>YouTube Page Design</li>
                                      <li>All Final File Formats</li>
                                      <li>Dedicated Account Manager</li>
                                      <li>100% Ownership Rights</li>
                                      <li>100% Satisfaction Guarantee</li>
                                      <li>100% Unique Design Guarantee</li>
                                      <li>100% Money Back Guarantee *</li>
                                  </ul>

                                  <div className="delivery">
                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                  </div>
                                  <div className="pricing-btns">
                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="pricing-card">
                              <div className="main-box">
                                  <LazyLoadImage src={pricing_bg} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="title">
                                  <h2>Professional Combo Package</h2>
                              </div>
                              <div className="inner-box">
                                  <h5>STARTING AT</h5>
                                  <h4>$1395</h4>
                                  <ul>
                                      <li>Unlimited Logo Concepts</li>
                                      <li>8 Dedicated Designers</li>
                                      <li>Icon Design</li>
                                      <li>2 Free Custom Stationary Designs</li>
                                      <li>MS Word Letterhead</li>
                                      <li>Trifold Brochure Design</li>
                                      <li>Presentation Folder Design</li>
                                      <li>Conceptual and Dynamic Liquid Website</li>
                                      <li>Team of Expert Designers Developers</li>
                                      <li>Mobile Responsive</li>
                                      <li>Online Reservation/Appointment Tool (Optional)</li>
                                      <li>Custom Forms</li>
                                      <li>Lead Capturing Forms (Optional)</li>
                                      <li>Newsfeed Integration</li>
                                      <li>Social Media Integration</li>
                                      <li>Search Engine Submission</li>
                                      <li>15 Stock images</li>
                                      <li>8 Unique Banner Designs</li>
                                      <li>jQuery Sliders</li>
                                      <li>Free Google Friendly Sitemap</li>
                                      <li>Complete W3C Certified HTML</li>
                                      <li>Facebook Page Design</li>
                                      <li>Twitter Page Design</li>
                                      <li>YouTube Page Design</li>
                                      <li>Google+ Page Design</li>
                                      <li>All Final File Formats</li>
                                      <li>Dedicated Account Manager</li>
                                      <li>100% Ownership Rights</li>
                                      <li>100% Satisfaction Guarantee</li>
                                      <li>100% Unique Design Guarantee</li>
                                      <li>100% Money Back Guarantee *</li>
                                  </ul>

                                  <div className="delivery">
                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                  </div>
                                  <div className="pricing-btns">
                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a/> */}
                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="pricing-card">
                              <div className="main-box">
                                  <LazyLoadImage src={pricing_bg} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="title">
                                  <h2>Corporate Combo Package</h2>
                              </div>
                              <div className="inner-box">
                                  <h5>STARTING AT</h5>
                                  <h4>$1995</h4>
                                  <ul>
                                      <li>Unlimited Logo Concepts</li>
                                      <li>8 Dedicated Designers</li>
                                      <li>Icon Design</li>
                                      <li>2 Free Custom Stationary Designs</li>
                                      <li>MS Word Letterhead</li>
                                      <li>Invoice Design</li>
                                      <li>Product Catalog Design</li>
                                      <li>Unlimited Pages Website</li>
                                      <li>Conceptual and Dynamic Website</li>
                                      <li>Content Management System (CMS)</li>
                                      <li>Easy Product Search</li>
                                      <li>Product Reviews</li>
                                      <li>Unlimited Products</li>
                                      <li>Unlimited Categories</li>
                                      <li>Promotional Product Showcase</li>
                                      <li>New Product Showcase</li>
                                      <li>Full Shopping Cart Integration</li>
                                      <li>Payment Module Integration</li>
                                      <li>Sales Inventory Management</li>
                                      <li>Custom Forms</li>
                                      <li>Lead Capturing Forms (Optional)</li>
                                      <li>Newsfeed Integration</li>
                                      <li>Social Media Integration</li>
                                      <li>Search Engine Submission</li>
                                      <li>Team of Dedicated Designers, Developers and Brand Experts</li>
                                      <li>20 Stock images</li>
                                      <li>6 Unique Banner Designs</li>
                                      <li>jQuery Slider</li>
                                      <li>Free Google Friendly Sitemap</li>
                                      <li>Complete W3C Certified HTML</li>
                                      <li>Facebook Page Design</li>
                                      <li>Twitter Page Design</li>
                                      <li>YouTube Page Design</li>
                                      <li>Google+ Page Design</li>
                                      <li>Pinterest Page Design</li>
                                      <li>All Final File Formats</li>
                                      <li>Dedicated Account Manager</li>
                                      <li>100% Ownership Rights</li>
                                      <li>100% Satisfaction Guarantee</li>
                                      <li>100% Unique Design Guarantee</li>
                                      <li>100% Money Back Guarantee *</li>
                                  </ul>

                                  <div className="delivery">
                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                  </div>
                                  <div className="pricing-btns">
                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="pricing-card">
                              <div className="main-box">
                                  <LazyLoadImage src={pricing_bg} alt="img not found" effect="blur" className="img-fluid" />
                              </div>
                              <div className="title">
                                  <h2>Elite Combo Package</h2>
                              </div>
                              <div className="inner-box">
                                  <h5>STARTING AT</h5>
                                  <h4>$2995</h4>
                                  <ul>
                                      <li>Complete Custom Design Development</li>
                                      <li>Client/User Dashboard Area</li>
                                      <li>Custom Coding</li>
                                      <li>Custom PHP Development</li>
                                      <li>Content Management System (CMS)</li>
                                      <li>Online Appointment/Scheduling/Online Ordering Integration (Optional)</li>
                                      <li>Online Payment Integration (Optional)</li>
                                      <li>Multi Lingual (Optional)</li>
                                      <li>Custom Dynamic Forms (Optional)</li>
                                      <li>Signup Area (For Newsletters, Offers etc.)</li>
                                      <li>Search Bar</li>
                                      <li>Live Feeds of Social Networks integration (Optional)</li>
                                      <li>Mobile Responsive Web</li>
                                      <li>Search Engine Submission</li>
                                      <li>Module-wise Architecture</li>
                                      <li>Extensive Admin Panel</li>
                                      <li>Award Winning Team of Expert Designers and Developers</li>
                                      <li>Complete Deployment</li>
                                      <li>Facebook Page Design</li>
                                      <li>Twitter Page Design</li>
                                      <li>YouTube Page Design</li>
                                      <li>100% Custom Designs - No Templates</li>
                                      <li>100% Satisfaction Guarantee</li>
                                      <li>100% Unique Design Guarantee</li>
                                      <li>100% Money Back Guarantee *</li>
                                  </ul>

                                  <div className="delivery">
                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                  </div>
                                  <div className="pricing-btns">
                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <Footer />

      
    </>
  )
}

export default ComboPackages
