import React from 'react'
import badge1 from '../assets/images/award-badge1.png';
import badge2 from '../assets/images/award-badge2.png';
import badge3 from '../assets/images/award-badge3.png';
import badge4 from '../assets/images/award-badge4.png';
import badge5 from '../assets/images/award-badge5.png';

const award_winning = () => {
    return (
        <div>

            <section className="container">
                <div className="award_winning">
                    <div className="award-bg">
                        <h3>Trusted And Reviewed On</h3>
                        <div className="d-flex justify-content-center align-items-center">
                            <img src={badge1} alt="img not found" />
                            <img src={badge2} alt="img not found" />
                            <img src={badge3} alt="img not found" />
                            <img src={badge4} alt="img not found" />
                            <img src={badge5} alt="img not found" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default award_winning
