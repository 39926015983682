import React from 'react'
import Header from '../includes/header';
import Footer from '../includes/footer';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import icon_1 from '../assets/images/icon-1.png';
import icon_2 from '../assets/images/icon-2.png';
import icon_3 from '../assets/images/icon-3.png';
import icon_4 from '../assets/images/icon-4.png';
import useDocumentTitle from '../useDocumentTitle.jsx'


const About = () => {
  useDocumentTitle('About')
  return (
    <div>
        <Header/>
      <section className="inner-banner-sec big-banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div className="banner-content">
                <h1>About Us</h1>
                <p>We are a one-stop solution for all your website development and branding needs! Let your business attain great heights with our custom web design & development services.</p>
                <div className="banner-btn">
                  <a href="javascript:void(0);" className="btn btn-contact">Get a Quote</a>
                  <a href="javascript:void(0);" className="btn btn-chat"><i className="fa-solid fa-messages"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-sec-wrap">
        <div className="container">
          <div className="row justify-content-xl-between justify-content-center">
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
              <div className="about-card">
                <div className="main-img">
                  <LazyLoadImage src={icon_1} effect="blur"  alt="img not found" className="img-fluid"/>
                </div>
                <div className="desc">
                  <h4>275+</h4>
                  <p>Projects Completed.</p>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
              <div className="about-card">
                <div className="main-img">
                  <LazyLoadImage src={icon_2} effect="blur" alt="img not found" className="img-fluid"/>
                </div>
                <div className="desc">
                  <h4>89%</h4>
                  <p>Customer Retention</p>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
              <div className="about-card">
                <div className="main-img">
                  <LazyLoadImage src={icon_3} effect="blur" alt="img not found" className="img-fluid"/>
                </div>
                <div className="desc">
                  <h4>120+</h4>
                  <p>Professionals</p>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
              <div className="about-card">
                <div className="main-img">
                  <LazyLoadImage src={icon_4} effect="blur" alt="img not found" className="img-fluid"/>
                </div>
                <div className="desc">
                  <h4>20+</h4>
                  <p>Years of Experience</p>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
              <div className="about-card">
                <h5>Schedule A Call
                  <br/>
                    Now!
                </h5>
                <div className="desc">
                  <a href="javascript:void(0);" className="btn btn-call"><i className="fa-solid fa-phone-volume"></i> Click
                    Here!</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="vision-sec-warp">
        <div className="container">
          <div className="row justify-content-around">
            <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5 col-xxl-5">
              <div className="desc">
                <h2>Our Vision</h2>
                <p>At Codex Design Agency, our vision is to provide our clients with innovative and
                  effective solutions to help them achieve their business goals. We strive to be the go-to
                  agency
                  for all our client's digital needs and to deliver outstanding results that exceed their
                  expectations</p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5 col-xxl-5">
              <div className="desc">
                <h2>Our Mission</h2>
                <p>Our mission is to stay up-to-date with the latest trends and technologies in the
                  digital world and implement them in our work to provide the best solutions for our clients.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
        <Footer/>
    </div>
  )
}

export default About
