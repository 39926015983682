import React from "react";
import review from '../assets/images/review.png';
import goodfirm from '../assets/images/goodfirm.png';
import google from '../assets/images/google.png';
import bark from '../assets/images/bark.png';
import clutch from '../assets/images/clutch.png';
import rereview from '../assets/images/rereview.png';
import trustpilot from '../assets/images/trustpilot.png';
import icon1 from '../assets/images/icon1.png';
import icon2 from '../assets/images/icon2.png';
import icon3 from '../assets/images/icon3.png';
import icon4 from '../assets/images/icon4.png';

const platform = () => {
    return (
        <>
            <section className="platform">
                <div className="container">
                    <div className="platform_images">
                        <h3>Trusted And Reviewed On</h3>
                        <div className="wrap">
                            <img src={review} alt="img not found" />
                            <img src={goodfirm} alt="img not found" />
                            <img src={google} alt="img not found" />
                            <img src={bark} alt="img not found" />
                            <img src={clutch} alt="img not found" />
                            <img src={rereview} alt="img not found" />
                            <img src={trustpilot} alt="img not found" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-6 col-md-3">
                            <div className="company-detail-box shadow-lg">
                                <img src={icon1} alt="img not found" />
                                <h4>12+ Years</h4>
                                <p>In The Business</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6 col-md-3">
                            <div className="company-detail-box shadow-lg">
                                <img src={icon2} alt="img not found" />
                                <h4>750+</h4>
                                <p>Solutions Delivered</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6 col-md-3">
                            <div className="company-detail-box shadow-lg">
                                <img src={icon3} alt="img not found" />
                                <h4>200+</h4>
                                <p>Technology Experts</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6 col-md-3">
                            <div className="company-detail-box shadow-lg">
                                <img src={icon4} alt="img not found" />
                                <h4>$50M+</h4>
                                <p>Business Delivered</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default platform;
