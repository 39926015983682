import React from 'react'
import auto_pop_bg_2 from '../assets/images/auto-pop-bg-2.png';

const modal = () => {
  return (
    <>
          <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog">
                  <div className="modal-content">
                      <div className="modal-header d-flex justify-content-end">
                          <div className="modal-close-btn"><button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>
                      </div>
                      <div className="modal-body">
                          <div className="row">
                              <div className="col-lg-6 col-12">
                                  <h3 className="discount">Avail Upto 50% Discount</h3>
                                  <h2 className="modal-heading white">Contact Us Now</h2>
                                  <form action="https://leads.infinityprojectmanager.com/brand/codexdesignagency/lead" className="popup-form CrudForm cmxform">
                                    <input type="hidden" name="brand_name" defaultValue="codexdesignagency.com"/> 
                                    <input type="hidden" id="lead_area" name="lead_area" defaultValue="https://codexdesignagency.com/"/>
                                      <div className="modal-form-wrap">
                                          <div className="mb-3"><input className="form-control" name="name" placeholder="Name" required/></div>
                                          <div className="mb-3"><input type="email" className="form-control" name="email" placeholder="Email" required/></div>
                                          <div className="mb-3"><input type="tel" className="form-control" name="phone" placeholder="Phone" required/></div>
                                          <button className="btn-bg btn-contact-form">Send Message</button></div>
                                  </form>
                                  </div>
                                      <div className="col-6">
                                  <img src={auto_pop_bg_2} className="img-fluid d-none d-lg-block" alt="img not found"/></div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
    </>
  )
}

export default modal
