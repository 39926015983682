import React from 'react'


import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


import web1 from '../assets/images/portfolio/web/1.webp';
import web2 from '../assets/images/portfolio/web/2.webp';
import web3 from '../assets/images/portfolio/web/3.webp';
import web4 from '../assets/images/portfolio/web/4.webp';
import web5 from '../assets/images/portfolio/web/5.webp';
import web6 from '../assets/images/portfolio/web/6.webp';
import web7 from '../assets/images/portfolio/web/7.webp';
import web8 from '../assets/images/portfolio/web/8.webp';


import logo1 from '../assets/images/portfolio/logo/1.png';
import logo2 from '../assets/images/portfolio/logo/2.png';
import logo3 from '../assets/images/portfolio/logo/3.png';
import logo4 from '../assets/images/portfolio/logo/4.png';
import logo5 from '../assets/images/portfolio/logo/5.png';
import logo6 from '../assets/images/portfolio/logo/9.png';
import logo7 from '../assets/images/portfolio/logo/7.png';
import logo8 from '../assets/images/portfolio/logo/8.png';


import eco1 from '../assets/images/portfolio/ecommerce/1.webp';
import eco2 from '../assets/images/portfolio/ecommerce/2.webp';
import eco3 from '../assets/images/portfolio/ecommerce/3.webp';
import eco4 from '../assets/images/portfolio/ecommerce/4.webp';
import eco5 from '../assets/images/portfolio/ecommerce/5.webp';
import eco6 from '../assets/images/portfolio/ecommerce/6.webp';
import eco7 from '../assets/images/portfolio/ecommerce/7.webp';
import eco8 from '../assets/images/portfolio/ecommerce/8.webp';


import ins1 from '../assets/images/portfolio/insurance/1.webp';
import ins2 from '../assets/images/portfolio/insurance/2.webp';
import ins3 from '../assets/images/portfolio/insurance/3.webp';
import ins4 from '../assets/images/portfolio/insurance/4.webp';
import ins5 from '../assets/images/portfolio/insurance/5.webp';
import ins6 from '../assets/images/portfolio/insurance/6.webp';
import ins7 from '../assets/images/portfolio/insurance/7.webp';
import ins8 from '../assets/images/portfolio/insurance/8.webp';



import ft1 from '../assets/images/portfolio/fitness/1.webp';
import ft2 from '../assets/images/portfolio/fitness/2.webp';
import ft4 from '../assets/images/portfolio/fitness/3.webp';
import ft5 from '../assets/images/portfolio/fitness/4.webp';
import ft3 from '../assets/images/portfolio/fitness/5.webp';
import ft6 from '../assets/images/portfolio/fitness/6.webp';
import ft7 from '../assets/images/portfolio/fitness/7.webp';
import ft8 from '../assets/images/portfolio/fitness/8.webp';


import rl1 from '../assets/images/portfolio/real-estate/1.webp';
import rl2 from '../assets/images/portfolio/real-estate/2.webp';
import rl4 from '../assets/images/portfolio/real-estate/3.webp';
import rl5 from '../assets/images/portfolio/real-estate/4.webp';
import rl6 from '../assets/images/portfolio/real-estate/5.webp';
import rl3 from '../assets/images/portfolio/real-estate/6.webp';
import rl7 from '../assets/images/portfolio/real-estate/7.webp';
import rl8 from '../assets/images/portfolio/real-estate/8.webp';



const portfolio = () => {
  return (
    <div>
          <div className="v-line v-line-right">
              <div className="">
                  <div className="tabs_portfolio">
                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                          <li className="nav-item" role="presentation">
                              <button className="nav-link" id="home-tab" data-bs-toggle="tab" data-bs-target="#web" type="button" role="tab" aria-controls="web" aria-selected="false" tabIndex="-1">Website Design</button>
                          </li>
                          <li className="nav-item" role="presentation">
                              <button className="nav-link" id="logo-tab" data-bs-toggle="tab" data-bs-target="#logo" type="button" role="tab" aria-controls="logo" aria-selected="false" tabIndex="-1">Logo Design</button>
                          </li>
                          <li className="nav-item" role="presentation">
                              <button className="nav-link active" id="profile-tab" data-bs-toggle="tab" data-bs-target="#ecommerce" type="button" role="tab" aria-controls="ecommerce" aria-selected="true">E-Commerce</button>
                          </li>
                          <li className="nav-item" role="presentation">
                              <button className="nav-link" id="insurance-tab" data-bs-toggle="tab" data-bs-target="#insurance" type="button" role="tab" aria-controls="insurance" aria-selected="false" tabIndex="-1">Insurance</button>
                          </li>
                          <li className="nav-item" role="presentation">
                              <button className="nav-link" id="fitness-tab" data-bs-toggle="tab" data-bs-target="#fitness" type="button" role="tab" aria-controls="fitness" aria-selected="false" tabIndex="-1">Fitness</button>
                          </li>
                          <li className="nav-item" role="presentation">
                              <button className="nav-link" id="realesate-tab" data-bs-toggle="tab" data-bs-target="#realesate" type="button" role="tab" aria-controls="realesate" aria-selected="false" tabIndex="-1">Real-Estate</button>
                          </li>


                      </ul>
                      <div className="tab-content" id="myTabContent">
                          <div className="tab-pane fade" id="web" role="tabpanel" aria-labelledby="web-tab">
                              <div className="row">
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={web1} data-fancybox="group" data-caption="Website Design">
                                              <LazyLoadImage src={web1}  alt="portfolio"  />
                                          </a>
                                      </div>

                                  </div>
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={web3} data-fancybox="group" data-caption="Website Design">
                                              <LazyLoadImage src={web3}  alt="portfolio"  />
                                          </a>
                                      </div>

                                  </div>
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={web2} data-fancybox="group" data-caption="Website Design">
                                              <LazyLoadImage src={web2}  alt="portfolio"  />
                                          </a>
                                      </div>

                                  </div>
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={web4} data-fancybox="group" data-caption="Website Design">
                                              <LazyLoadImage src={web4}  alt="portfolio"  />
                                          </a>
                                      </div>

                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={web5} data-fancybox="group" data-caption="Website Design">
                                              <LazyLoadImage src={web5}  alt="portfolio"  />
                                          </a>
                                      </div>

                                  </div>
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={web6} data-fancybox="group" data-caption="Website Design">
                                              <LazyLoadImage src={web6}  alt="portfolio"  />
                                          </a>
                                      </div>

                                  </div>
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={web7} data-fancybox="group" data-caption="Website Design">
                                              <LazyLoadImage src={web7}  alt="portfolio"  />
                                          </a>
                                      </div>

                                  </div>
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={web8} data-fancybox="group" data-caption="Website Design">
                                              <LazyLoadImage src={web8}  alt="portfolio"  />
                                          </a>
                                      </div>

                                  </div>
                              </div>
                          </div>
                          <div className="tab-pane fade" id="logo" role="tabpanel" aria-labelledby="logo-tab">
                              <div className="row">
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={logo1} data-fancybox="group" data-caption="Logo Design">
                                              <LazyLoadImage src={logo1} alt="logo"/>
                                          </a>
                                      </div>

                                  </div>
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={logo2} data-fancybox="group" data-caption="Logo Design">
                                              <LazyLoadImage src={logo2} alt="logo" />
                                          </a>
                                      </div>

                                  </div>
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={logo3} data-fancybox="group" data-caption="Logo Design">
                                              <LazyLoadImage src={logo3} alt="logo" />
                                          </a>
                                      </div>

                                  </div>
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={logo4} data-fancybox="group" data-caption="Logo Design">
                                              <LazyLoadImage src={logo4} alt="logo" />
                                          </a>
                                      </div>

                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={logo5} data-fancybox="group" data-caption="Logo Design">
                                              <LazyLoadImage src={logo5} alt="logo" />
                                          </a>
                                      </div>

                                  </div>
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={logo6} data-fancybox="group" data-caption="Logo Design">
                                              <LazyLoadImage src={logo6} alt="logo" />
                                          </a>
                                      </div>

                                  </div>
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={logo7} data-fancybox="group" data-caption="Logo Design">
                                              <LazyLoadImage src={logo7} alt="logo" />
                                          </a>
                                      </div>

                                  </div>
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={logo8} data-fancybox="group" data-caption="Logo Design">
                                              <LazyLoadImage src={logo8} alt="logo" />
                                          </a>
                                      </div>

                                  </div>
                              </div>
                          </div>
                          <div className="tab-pane fade active show" id="ecommerce" role="tabpanel" aria-labelledby="ecommerce-tab">
                              <div className="row">
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={eco1} data-fancybox="group" data-caption="Ecommerce Design">
                                              <LazyLoadImage src={eco1}  alt="portfolio"  />
                                          </a>
                                      </div>
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={eco2} data-fancybox="group" data-caption="Ecommerce Design">
                                              <LazyLoadImage src={eco2}  alt="portfolio"  />
                                          </a>
                                      </div>
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={eco3} data-fancybox="group" data-caption="Ecommerce Design">
                                              <LazyLoadImage src={eco3}  alt="portfolio"  />
                                          </a>
                                      </div>
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={eco4} data-fancybox="group" data-caption="Ecommerce Design">
                                              <LazyLoadImage src={eco4}  alt="portfolio"  />
                                          </a>
                                      </div>
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={eco5} data-fancybox="group" data-caption="Ecommerce Design">
                                              <LazyLoadImage src={eco5}  alt="portfolio"  />
                                          </a>
                                      </div>
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={eco6} data-fancybox="group" data-caption="Ecommerce Design">
                                              <LazyLoadImage src={eco6}  alt="portfolio"  />
                                          </a>
                                      </div>
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={eco7} data-fancybox="group" data-caption="Ecommerce Design">
                                              <LazyLoadImage src={eco7}  alt="portfolio"  />
                                          </a>
                                      </div>
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={eco8} data-fancybox="group" data-caption="Ecommerce Design">
                                              <LazyLoadImage src={eco8}  alt="portfolio"  />
                                          </a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="tab-pane fade" id="insurance" role="tabpanel" aria-labelledby="insurance-tab">
                              <div className="row">
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={ins1} data-fancybox="group" data-caption="Website Design">
                                              <LazyLoadImage src={ins1}  alt="portfolio"  />
                                          </a>
                                      </div>

                                  </div>
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={ins2} data-fancybox="group" data-caption="Website Design">
                                              <LazyLoadImage src={ins2}  alt="portfolio"  />
                                          </a>
                                      </div>

                                  </div>
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={ins3} data-fancybox="group" data-caption="Website Design">
                                              <LazyLoadImage src={ins3}  alt="portfolio"  />
                                          </a>
                                      </div>

                                  </div>
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={ins4} data-fancybox="group" data-caption="Website Design">
                                              <LazyLoadImage src={ins4}  alt="portfolio"  />
                                          </a>
                                      </div>

                                  </div>
                              </div>

                              <div className="row">
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={ins5} data-fancybox="group" data-caption="Website Design">
                                              <LazyLoadImage src={ins5}  alt="portfolio"  />
                                          </a>
                                      </div>

                                  </div>
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={ins6} data-fancybox="group" data-caption="Website Design">
                                              <LazyLoadImage src={ins6}  alt="portfolio"  />
                                          </a>
                                      </div>

                                  </div>
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={ins7} data-fancybox="group" data-caption="Website Design">
                                              <LazyLoadImage src={ins7}   alt="portfolio"  />
                                          </a>
                                      </div>

                                  </div>
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={ins8}  data-fancybox="group" data-caption="Website Design">
                                              <LazyLoadImage src={ins8}   alt="portfolio"  />
                                          </a>
                                      </div>

                                  </div>
                              </div>

                          </div>
                          <div className="tab-pane fade" id="fitness" role="tabpanel" aria-labelledby="fitness-tab">
                              <div className="row">
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={ft1} data-fancybox="group" data-caption="Website Design">
                                              <LazyLoadImage src={ft1}  alt="portfolio"  />
                                          </a>
                                      </div>

                                  </div>
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={ft2} data-fancybox="group" data-caption="Website Design">
                                              <LazyLoadImage src={ft2}  alt="portfolio"  />
                                          </a>
                                      </div>

                                  </div>
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={ft3} data-fancybox="group" data-caption="Website Design">
                                              <LazyLoadImage src={ft3}  alt="portfolio"  />
                                          </a>
                                      </div>

                                  </div>
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={ft4} data-fancybox="group" data-caption="Website Design">
                                              <LazyLoadImage src={ft4}  alt="portfolio"  />
                                          </a>
                                      </div>

                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={ft5} data-fancybox="group" data-caption="Website Design">
                                              <LazyLoadImage src={ft5}  alt="portfolio"  />
                                          </a>
                                      </div>

                                  </div>
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={ft6} data-fancybox="group" data-caption="Website Design">
                                              <LazyLoadImage src={ft6}  alt="portfolio"  />
                                          </a>
                                      </div>

                                  </div>
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={ft7} data-fancybox="group" data-caption="Website Design">
                                              <LazyLoadImage src={ft7}  alt="portfolio"  />
                                          </a>
                                      </div>

                                  </div>
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={ft8} data-fancybox="group" data-caption="Website Design">
                                              <LazyLoadImage src={ft8}  alt="portfolio"  />
                                          </a>
                                      </div>

                                  </div>
                              </div>
                          </div>
                          <div className="tab-pane fade" id="realesate" role="tabpanel" aria-labelledby="realesate-tab">
                              <div className="row">
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={rl1} data-fancybox="group" data-caption="real-estate Design">
                                              <LazyLoadImage src={rl1}  alt="portfolio"  />
                                          </a>
                                      </div>
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={rl2} data-fancybox="group" data-caption="real-estate Design">
                                              <LazyLoadImage src={rl2}  alt="portfolio"  />
                                          </a>
                                      </div>
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={rl3} data-fancybox="group" data-caption="real-estate Design">
                                              <LazyLoadImage src={rl3}  alt="portfolio"  />
                                          </a>
                                      </div>
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={rl4} data-fancybox="group" data-caption="real-estate Design">
                                              <LazyLoadImage src={rl4}  alt="portfolio"  />
                                          </a>
                                      </div>
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={rl5} data-fancybox="group" data-caption="real-estate Design">
                                              <LazyLoadImage src={rl5}  alt="portfolio"  />
                                          </a>
                                      </div>
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={rl6} data-fancybox="group" data-caption="real-estate Design">
                                              <LazyLoadImage src={rl6}  alt="portfolio"  />
                                          </a>
                                      </div>
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                            <a href={rl7} data-fancybox="group" data-caption="real-estate Design">
                                                <LazyLoadImage src={rl7}  alt="portfolio"  />
                                          </a>
                                      </div>
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 col-lg-3">
                                      <div className="portfolio">
                                          <a href={rl8} data-fancybox="group" data-caption="real-estate Design">
                                              <LazyLoadImage src={rl8}  alt="portfolio"  />
                                          </a>
                                      </div>
                                  </div>
                              </div>
                          </div>

                      </div>
                  </div>
              </div>
          </div>
      
    </div>
  )
}

export default portfolio
