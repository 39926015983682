import React from 'react'

import Header from '../includes/header';
import Footer from '../includes/footer';

import brand_img from '../assets/images/brand-img.png';
import inner_banner_img from '../assets/images/inner-banner-img.png';
import icon_1 from '../assets/images/icon-1.png';
import icon_2 from '../assets/images/icon-2.png';
import icon_3 from '../assets/images/icon-3.png';
import icon_4 from '../assets/images/icon-4.png';

import icon_5 from '../assets/images/icon-5.png';
import icon_6 from '../assets/images/icon-6.png';
import icon_7 from '../assets/images/icon-7.png';
import icon_8 from '../assets/images/icon-8.png';

import icon_9 from '../assets/images/icon-9.png';
import icon_10 from '../assets/images/icon-10.png';
import icon_11 from '../assets/images/icon-11.png';
import icon_12 from '../assets/images/icon-12.png';

import icon_13 from '../assets/images/icon-13.png';
import icon_14 from '../assets/images/icon-14.png';
import icon_15 from '../assets/images/icon-15.png';
import icon_16 from '../assets/images/icon-16.png';
import icon_17 from '../assets/images/icon-17.png';
import icon_18 from '../assets/images/icon-18.png';


import pricing_bg from '../assets/images/pricing-bg.png';
import profund_bg from '../assets/images/profund-bg.png';
import useDocumentTitle from '../useDocumentTitle.jsx'


import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const DigitalMarketing = () => {
    useDocumentTitle('Ditital-Marketing')

  return (
    <>
          <Header/>
          <section className="inner-banner-sec">
              <div className="container">
                  <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="banner-content">
                              <h1>Premium Digital Marketing Services for Your Business</h1>
                              <p>Our digital marketing company can enhance your online presence and grow your brand/business with tailored marketing strategies. Our team of dynamic and versatile marketers provides exceptional solutions to bring your vision to life.</p>
                              <div className="brand-rating">
                                  <div className="rating">
                                      <h6>5.0 </h6>
                                      <ul>
                                          <li><i className="fa-solid fa-star"></i></li>
                                          <li><i className="fa-solid fa-star"></i></li>
                                          <li><i className="fa-solid fa-star"></i></li>
                                          <li><i className="fa-solid fa-star"></i></li>
                                          <li><i className="fa-solid fa-star"></i></li>
                                      </ul>
                                      <h6> rating on</h6>
                                  </div>
                                  <div className="brand-img">
                                      <LazyLoadImage src={brand_img}alt="img not found" effect="blur"  className="img-fluid"/>
                                  </div>
                              </div>
                              <div className="banner-btn">
                                  <a href="javascript:$zopim.livechat.window.show();" className="btn btn-contact">Contact Us today</a>
                                  <a href="javascript:$zopim.livechat.window.show();" className="btn btn-chat"><i className="fa-solid fa-messages"></i></a>
                              </div>
                          </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="main-img">
                              <LazyLoadImage src={inner_banner_img} className="img-fluid" alt="img not found" effect="blur" />
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="about-sec-wrap">
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">
                          <div className="content">
                              <h2>About Us</h2>
                              <h3>Best Digital Marketing Services You Can Get</h3>
                              <p>Having a website gives businesses a competitive edge, allowing them to communicate their vision and mission, and helping audiences understand their services and products. It's an opportunity for brands to connect with their target audience and gather feedback. Enter Codex Design Agency, who can help your brand thrive in the digital world by gaining online recognition. Our digital marketing consultants provide not only the finest website development services but also ongoing maintenance and monitoring to boost website traffic.</p>
                              <p>Our philosophy is straightforward: we aim to enhance your online business with cost-effective and efficient digital marketing solutions.</p>
                          </div>
                      </div>
                  </div>
                  <div className="row justify-content-xl-between justify-content-center">
                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
                          <div className="about-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_1} alt="img not found" effect="blur"  className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>275+</h4>
                                  <p>Projects Completed.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
                          <div className="about-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_2} alt="img not found" effect="blur"  className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>89%</h4>
                                  <p>Customer Retention</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
                          <div className="about-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_3} alt="img not found" effect="blur"  className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>120+</h4>
                                  <p>Professionals</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
                          <div className="about-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_4} alt="img not found" effect="blur"  className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>20+</h4>
                                  <p>Years of Experience</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
                          <div className="about-card">
                              <h5>Schedule A Call
                                  <br/>
                                      Now!
                              </h5>
                              <div className="desc">
                                  <a href="javascript:$zopim.livechat.window.show();" className="btn btn-call"><i className="fa-solid fa-phone-volume"></i> Click
                                      Here!</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="Premium-ppc-sec">
              <div className="container-fluid">
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="content">
                              <h3>Best Digital Marketing Services for You!</h3>
                              <p>You can count on our digital marketing specialists to provide you with a range of services</p>
                          </div>
                      </div>
                  </div>
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_5} alt="img not found" effect="blur"  className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>
                                      SEO Services</h4>
                                  <p>Boost your website's search engine ranking with our customized SEO strategies.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_6} alt="img not found" effect="blur"  className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>PPC Services</h4>
                                  <p>Drive targeted traffic to your website through our effective PPC campaigns.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_7} alt="img not found" effect="blur"  className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Social Media Marketing</h4>
                                  <p>Reach your target audience on social media platforms with our comprehensive social media marketing services.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_8}alt="img not found" effect="blur"  className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Content Marketing services</h4>
                                  <p>Engage your audience with high-quality, informative content that reflects your brand’s message.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_9} alt="img not found" effect="blur"  className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Video Production</h4>
                                  <p>Enhance your online presence with engaging and informative videos that showcase your brand’s offerings.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_10} alt="img not found" effect="blur"  className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>eCommerce Marketing</h4>
                                  <p>We can help you drive sales and increase conversions through our customized eCommerce marketing strategies.
                                  </p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_11} alt="img not found" effect="blur"  className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Conversion Rate Optimization (CRO)</h4>
                                  <p>Don’t Risk Having A Generic Website That Blends With Everything Else In The Online Realm. </p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_12} alt="img not found" effect="blur"  className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Email Marketing</h4>
                                  <p>Our team can help you connect with your target audience through effective email campaigns that drive conversions.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_13} alt="img not found" effect="blur"  className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Online Reputation Management (ORM)</h4>
                                  <p>Our ORM services can protect and enhance your brand's online reputation. </p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_14} alt="img not found" effect="blur"  className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Affiliate Marketing</h4>
                                  <p>Our digital marketers can expand your reach and generate new leads with our affiliate marketing strategies.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_15} alt="img not found" effect="blur"  className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Web Hosting</h4>
                                  <p>Enjoy fast, secure, and reliable web hosting services that ensure your website is always up and running..</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_16} alt="img not found" effect="blur"  className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Web design and Development</h4>
                                  <p>Create a website that reflects your brand's image and message with our professional web design and development services.</p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="profund-sec-wrap">
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">
                          <div className="content">
                              <h2>Learn About Our Digital Marketing Process</h2>
                          </div>
                      </div>
                  </div>
                  <div className="row justify-content-center">
                      <div className="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3 col-xxl-3">
                          <div className="profund-card">
                              <div className="step">
                                  <h5>01</h5>
                              </div>
                              <div className="inner-box">
                                  <div className="main-img">
                                      <LazyLoadImage src={icon_17} alt="img not found" effect="blur"  className="img-fluid"/>
                                  </div>
                                  <div className="desc">
                                      <h4>Free consultation</h4>
                                      <p>Schedule a free consultation session on our website to discuss premium digital marketing services for your organization</p>
                                  </div>
                                  <div className="bg-img">
                                      <LazyLoadImage src={profund_bg} alt="img not found" effect="blur"  className="img-fluid"/>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3 col-xxl-3">
                          <div className="profund-card">
                              <div className="step">
                                  <h5>02</h5>
                              </div>
                              <div className="inner-box">
                                  <div className="main-img">
                                      <LazyLoadImage src={icon_18} alt="img not found" effect="blur"  className="img-fluid"/>
                                  </div>
                                  <div className="desc">
                                      <h4>Strategy development</h4>
                                      <p>Our digital marketer will evaluate your business history and design custom marketing plans that align with your vision and goals.</p>
                                  </div>
                                  <div className="bg-img">
                                      <LazyLoadImage src={profund_bg} alt="img not found" effect="blur"  className="img-fluid" />
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3 col-xxl-3">
                          <div className="profund-card">
                              <div className="step">
                                  <h5>03</h5>
                              </div>
                              <div className="inner-box">
                                  <div className="main-img">
                                      <LazyLoadImage src={icon_6} alt="img not found" effect="blur"  className="img-fluid" />
                                  </div>
                                  <div className="desc">
                                      <h4>Implementation and progress</h4>
                                      <p>: After gathering insights on your target audience, services, and products, we'll implement the planned digital marketing tactics</p>
                                  </div>
                                  <div className="bg-img">
                                      <LazyLoadImage src={profund_bg} alt="img not found" effect="blur"  className="img-fluid" />
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3 col-xxl-3">
                          <div className="profund-card">
                              <div className="step">
                                  <h5>04</h5>
                              </div>
                              <div className="inner-box">
                                  <div className="main-img">
                                      <LazyLoadImage src={icon_17} alt="img not found" effect="blur"  className="img-fluid" />
                                  </div>
                                  <div className="desc">
                                      <h4>Conversion-oriented</h4>
                                      <p>Our marketing strategies are designed to convert potential customers into paying ones, increasing your business's ROI.</p>
                                  </div>
                                  <div className="bg-img">
                                      <LazyLoadImage src={profund_bg} alt="img not found" effect="blur"  className="img-fluid" />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="pricing-sec-wrap">
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">
                          <div className="content">
                              <h2>Pricing Plans</h2>
                          </div>
                      </div>
                  </div>
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <div className="pricing-top-btn">
                              <a href="javascript:$zopim.livechat.window.show();" className="btn btn-top-pricing">Digital Marketing</a>
                          </div>
                      </div>
                  </div>
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="pricing-card">
                              <div className="main-box">
                                  <LazyLoadImage src={pricing_bg} alt="img not found" effect="blur"  className="img-fluid" />

                              </div>
                              <div className="title">
                                  <h2>Startup Social Package</h2>
                              </div>
                              <div className="inner-box">
                                  <h5>STARTING AT</h5>
                                  <h4>$99</h4>
                                  <ul>
                                      <li>Cover Photo Design</li>
                                      <li>Profile Picture Design</li>
                                      <li>Welcome Page Design</li>
                                      <li>Unlimited Revisions</li>
                                      <li>Dedicated Project Manager</li>
                                      <li>100% Ownership Rights</li>
                                      <li>100% Satisfaction Guarantee</li>
                                      <li>100% Money Back Guarantee</li>
                                      <li>*NO MONTHLY OR ANY HIDDEN FEE*</li>
                                  </ul>

                                  <div className="delivery">
                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                  </div>
                                  <div className="pricing-btns">
                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="pricing-card">
                              <div className="main-box">
                                  <LazyLoadImage src={pricing_bg} alt="img not found" effect="blur"  className="img-fluid" />
                              </div>
                              <div className="title">
                                  <h2>Classic Social Package</h2>
                              </div>
                              <div className="inner-box">
                                  <h5>STARTING AT</h5>
                                  <h4>$149</h4>
                                  <ul>
                                      <li>Cover Photo Design</li>
                                      <li>Profile Picture Design</li>
                                      <li>Welcome Page Design</li>
                                      <li>Twitter Page Design</li>
                                      <li>Unlimited Revisions</li>
                                      <li>100% Money Back Guarantee *</li>
                                  </ul>

                                  <div className="delivery">
                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                  </div>
                                  <div className="pricing-btns">
                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="pricing-card">
                              <div className="main-box">
                                  <LazyLoadImage src={pricing_bg} alt="img not found" effect="blur"  className="img-fluid" />
                              </div>
                              <div className="title">
                                  <h2>Premium Social Package</h2>
                              </div>
                              <div className="inner-box">
                                  <h5>STARTING AT</h5>
                                  <h4>$199</h4>
                                  <ul>
                                      <li>Cover Photo Design</li>
                                      <li>rofile Picture Design</li>
                                      <li>Welcome Page Design</li>
                                      <li>Twitter Page Design</li>
                                      <li>YouTube Page Design</li>
                                      <li>Unlimited Revisions</li>
                                  </ul>

                                  <div className="delivery">
                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                  </div>
                                  <div className="pricing-btns">
                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="pricing-card">
                              <div className="main-box">
                                  <LazyLoadImage src={pricing_bg} alt="img not found" effect="blur"  className="img-fluid" />
                              </div>
                              <div className="title">
                                  <h2>Unlimited Social Package</h2>
                              </div>
                              <div className="inner-box">
                                  <h5>STARTING AT</h5>
                                  <h4>$999</h4>
                                  <ul>
                                      <li>2 Cover Photo Designs</li>
                                      <li>Profile Picture Design</li>
                                      <li>Welcome Page Design</li>
                                      <li>Twitter Page Design</li>
                                      <li>YouTube Page Design</li>
                                      <li>Google+ Page Design</li>
                                  </ul>

                                  <div className="delivery">
                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                  </div>
                                  <div className="pricing-btns">
                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <Footer />

    </>
  )
}

export default DigitalMarketing
