import React from 'react'

import Header from '../includes/header';
import Footer from '../includes/footer';

import brand_img from '../assets/images/brand-img.png';
import inner_banner_img from '../assets/images/inner-banner-img.png';


import icon_1 from '../assets/images/icon-1.png';
import icon_2 from '../assets/images/icon-2.png';
import icon_3 from '../assets/images/icon-3.png';
import icon_4 from '../assets/images/icon-4.png';

import icon_5 from '../assets/images/icon-5.png';
import icon_6 from '../assets/images/icon-6.png';
import icon_7 from '../assets/images/icon-7.png';
import icon_8 from '../assets/images/icon-8.png';

import icon_9 from '../assets/images/icon-9.png';
import icon_10 from '../assets/images/icon-10.png';
import icon_11 from '../assets/images/icon-11.png';
import icon_12 from '../assets/images/icon-12.png';

import icon_13 from '../assets/images/icon-13.png';
import icon_14 from '../assets/images/icon-14.png';
import icon_15 from '../assets/images/icon-15.png';
import icon_16 from '../assets/images/icon-16.png';
import icon_17 from '../assets/images/icon-17.png';
import icon_18 from '../assets/images/icon-18.png';

import pricing_bg from '../assets/images/pricing-bg.png';
import profund_bg from '../assets/images/profund-bg.png';
import useDocumentTitle from '../useDocumentTitle.jsx';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Ecommerce = () => {
    useDocumentTitle('Ecommerce')

  return (
    <>
      <Header />
          <section className="inner-banner-sec">
              <div className="container">
                  <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="banner-content">
                              <h1>Premium Ecommerce Development Services</h1>
                              <p>Codex Design Agency offer customized e-commerce website design and development services that cater to your specific business needs. Our Ecommerce development company creates unique storefronts, digitizes e-commerce operations, and designs scalable micro-services to enhance customer experience and optimize back-office operations.</p>
                              <div className="brand-rating">
                                  <div className="rating">
                                      <h6>5.0 </h6>
                                      <ul>
                                          <li><i className="fa-solid fa-star"></i></li>
                                          <li><i className="fa-solid fa-star"></i></li>
                                          <li><i className="fa-solid fa-star"></i></li>
                                          <li><i className="fa-solid fa-star"></i></li>
                                          <li><i className="fa-solid fa-star"></i></li>
                                      </ul>
                                      <h6> rating on</h6>
                                  </div>
                                  <div className="brand-img">
                                      <LazyLoadImage src={brand_img} alt="img not found" effect="blur"  className="img-fluid"/>
                                  </div>
                              </div>
                              <div className="banner-btn">
                                  <a href="javascript:$zopim.livechat.window.show();" className="btn btn-contact">Get a quote now</a>
                                  <a href="javascript:$zopim.livechat.window.show();" className="btn btn-chat"><i className="fa-solid fa-messages"></i></a>
                              </div>
                          </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="main-img">
                              <LazyLoadImage src={inner_banner_img}className="img-fluid" alt="img not found" effect="blur" />
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="about-sec-wrap">
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">
                          <div className="content">
                              <h2>About Us</h2>
                              <p>Codex Design Agency provides bespoke e-commerce development services, offering universal and distinctive features to ensure seamless customer journeys. With expertise in a wide range of business and technology models, our team can flawlessly deliver solutions tailored to your specific requirements. Our e-commerce solutions are designed to be fast, stable, and reliable, ensuring they never crash.</p>
                              <p>Codex Design Agency is a reputable provider of e-commerce development services, having launched over 750 websites and generating more than $3.5 billion in revenue for clients. We are a trusted partner for businesses seeking reliable and effective e-commerce solutions. To find out more about collaborating with our award-winning team of project managers, web designers, and web developers, contact us online today!</p>
                          </div>
                      </div>
                  </div>
                  <div className="row justify-content-xl-between justify-content-center">
                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
                          <div className="about-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_1} alt="img not found" effect="blur"  className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>275+</h4>
                                  <p>Projects Completed.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
                          <div className="about-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_2} alt="img not found" effect="blur"  className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>89%</h4>
                                  <p>Customer Retention</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
                          <div className="about-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_3} alt="img not found" effect="blur"  className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>120+</h4>
                                  <p>Professionals</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
                          <div className="about-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_4} alt="img not found" effect="blur"  className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>20+</h4>
                                  <p>Years of Experience</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
                          <div className="about-card">
                              <h5>Schedule A Call
                                  <br/>
                                      Now!
                              </h5>
                              <div className="desc">
                                  <a href="javascript:$zopim.livechat.window.show();" className="btn btn-call"><i className="fa-solid fa-phone-volume"></i> Click
                                      Here!</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="Premium-ppc-sec">
              <div className="container-fluid">
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="content">
                              <h3>Flawless E-commerce Solutions</h3>
                              <p>Professional, easy, and quick e-commerce solutions are brought your way</p>
                          </div>
                      </div>
                  </div>
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_5} alt="img not found" effect="blur"  className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>E-commerce consulting</h4>
                                  <p>We guide business, technology, and operational aspects to help you optimize operational efficiency and enhance the customer experience with your company.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_6} alt="img not found" effect="blur"  className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Custom e-commerce development</h4>
                                  <p>We adopt flexible and agile decoupled architectures, and are proficient in advanced technologies such as voice recognition and 3D modeling, to help differentiate your brand and create bespoke e-commerce solutions..</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_7} alt="img not found" effect="blur"  className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Platform-based e-commerce development</h4>
                                  <p>While there are many e-commerce platforms to choose from, we exclusively work with Adobe Commerce to develop scalable and future-proof e-commerce solutions that can grow with your business ambitions..</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_8} alt="img not found" effect="blur"  className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Mobile ecommerce app development</h4>
                                  <p>We can create a branded mobile space for your store, enabling customers to conveniently shop anytime and anywhere..</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_9} alt="img not found" effect="blur"  className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>E-commerce front-end development</h4>
                                  <p>Using programming languages such as HTML, CSS, and JavaScript, along with JavaScript frameworks like AngularJS, ReactJS, and Vue.js, we design modern and user-friendly digital storefronts.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_10} alt="img not found" effect="blur"  className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>E-commerce back-end development</h4>
                                  <p>We design the business logic of your e-commerce app using back-end programming languages like PHP, Java, Python, C++, Node.js, Go, and . NET.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_11} alt="img not found" effect="blur"  className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>E-commerce integrations</h4>
                                  <p>We offer API-based integration services to connect separate systems and facilitate automated data exchange, strengthening our expertise in e-commerce store development</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_12} alt="img not found" effect="blur"  className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>E-commerce migration</h4>
                                  <p>If your current e-commerce solution is hindering business development, we can help you transition to a more flexible and scalable architecture and transfer business data.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_13} alt="img not found" effect="blur"  className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>E-commerce audit</h4>
                                  <p>We examine e-commerce websites and infrastructure for code defects, security vulnerabilities, performance, and UX issues, and prioritize and plan solutions to detected problems.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_14} alt="img not found" effect="blur"  className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>E-commerce support and evolution</h4>
                                  <p>Through a long-standing collaboration, we monitor the performance of your e-commerce app and conduct troubleshooting work.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_15} alt="img not found" effect="blur"  className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>E-commerce ERP</h4>
                                  <p>We specialize in handling intricate e-commerce workflows, supply chain management, price management, project management, and human resource management.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_16} alt="img not found" effect="blur"  className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>E-commerce website design</h4>
                                  <p>Our e-commerce website design services encompass UX and UI aspects. We follow the best practices of target audience research, competitor analysis, UI kit branding, and usability testing.</p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="pricing-sec-wrap">
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">
                          <div className="content">
                              <h2>Pricing Plans</h2>
                          </div>
                      </div>
                  </div>
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <div className="pricing-top-btn">
                              <a href="javascript:$zopim.livechat.window.show();" className="btn btn-top-pricing">Ecommerce</a>
                          </div>
                      </div>
                  </div>
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="pricing-card">
                              <div className="main-box">
                                  <LazyLoadImage src={pricing_bg} alt="img not found" effect="blur"  className="img-fluid"/>
                              </div>
                              <div className="title">
                                  <h2>E-Commerce Start Up Package</h2>
                              </div>
                              <div className="inner-box">
                                  <h5>STARTING AT</h5>
                                  <h4>$3499</h4>
                                  <ul>
                                      <li>E-Commerce Website Design and Development</li>
                                      <li>Customized Tailor-Made Design</li>
                                      <li>Professional</li>
                                      <li>Modern</li>
                                      <li>Interactive</li>
                                      <li>Dynamic</li>
                                      <li>Unique Design</li>
                                      <li>20 Banner Designs</li>
                                      <li>Sliding Banner</li>
                                      <li>20 Stock Photos</li>
                                      <li>Unlimited Revisions</li>
                                      <li>Special Hover Effects</li>
                                      <li>Content/Inventory Management System</li>
                                      <li>Mobile Responsive</li>
                                      <li>Unlimited Products</li>
                                      <li>Unlimited Categories</li>
                                      <li>Product Reviews Ratings</li>
                                      <li>Product Summary Reports (Out of stock / Most Sold / Lowest sale etc) By Date</li>
                                      <li>Multiple Filtrations Option</li>
                                      <li>Multiple Shorting Options (Newest / Price / Alphabetical / Rating)</li>
                                      <li>Product View with Multiple Views</li>
                                      <li>Product Rating</li>
                                      <li>Product Detail with Similar Product Range</li>
                                      <li>Coupon Code Generation (by category and product)</li>
                                      <li>Product Comparison</li>
                                      <li>User Signup Area</li>
                                      <li>User Sign-in Area</li>
                                      <li>User Dashboard Area</li>
                                      <li>Auto Email for Profile Validation Notification(s)</li>
                                      <li>Last Purchase Order Details (download invoice)</li>
                                      <li>Customer Login / Registration via Social Login Facebook and Google+</li>
                                      <li>Customer profile management (From Backend and Frontend)</li>
                                      <li>Discounted Products Showcase</li>
                                      <li>New Arrival Products Showcase</li>
                                      <li>Discount Coupons</li>
                                      <li>Easy Product Search Bar</li>
                                      <li>Shopping Cart Integration</li>
                                      <li>Payment Module Integration</li>
                                      <li>Multiple Currency Integration</li>
                                      <li>Direct Checkout</li>
                                      <li>Sign up Checkout</li>
                                      <li>Tax calculator Integration</li>
                                      <li>Shipping calculator Integration</li>
                                      <li>Wishlist</li>
                                      <li>Import/Export Order</li>
                                      <li>Bulk Import/export of Order information</li>
                                      <li>Bulk Product Upload From Excel Sheet</li>
                                      <li>Export order into excel sheet</li>
                                      <li>Live Chat Integration</li>
                                      <li>Search Engine Submission</li>
                                      <li>SEO Friendly</li>
                                      <li>Web Analytics Integration</li>
                                      <li>Monthly Traffic Reporting</li>
                                      <li>Social Media Pages Integration</li>
                                      <li>Facebook Shop Setup</li>
                                      <li>Facebook “Like” Plugin integration</li>
                                      <li>Blog page (If Required)</li>
                                      <li>Sitemap</li>
                                      <li>Complete W3C Validation</li>
                                      <li>Dedicated Team of Designers and Developers</li>
                                      <li>Complete Deployment</li>
                                      <li>Complete Source Files</li>
                                      <li>Dedicated Project Manager</li>
                                      <li>100% Ownership Rights</li>
                                      <li>100% Satisfaction Guarantee</li>
                                      <li>100% Money Back Guarantee</li>
                                      <li>*NO MONTHLY OR ANY HIDDEN FEE*</li>
                                  </ul>

                                  <div className="delivery">
                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                  </div>
                                  <div className="pricing-btns">
                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="pricing-card">
                              <div className="main-box">
                                  <LazyLoadImage src={pricing_bg} alt="img not found" effect="blur"  className="img-fluid"/>
                              </div>
                              <div className="title">
                                  <h2>Automated/Interactive E-Commerce Package</h2>
                              </div>
                              <div className="inner-box">
                                  <h5>STARTING AT</h5>
                                  <h4>$7999</h4>
                                  <ul>
                                      <li>E-Commerce Website Design and Development</li>
                                      <li>Customized Tailor-Made Design</li>
                                      <li>Professional</li>
                                      <li>Modern</li>
                                      <li>Interactive</li>
                                      <li>Dynamic</li>
                                      <li>Unique Design</li>
                                      <li>User-friendly Navigation</li>
                                      <li>20 Banner Designs</li>
                                      <li>Sliding Banner</li>
                                      <li>20 Stock Photos</li>
                                      <li>Unlimited Revisions</li>
                                      <li>Special Hover Effects</li>
                                      <li>Content/Inventory Management System</li>
                                      <li>Mobile Responsive</li>
                                      <li>Unlimited Products</li>
                                      <li>Unlimited Categories</li>
                                      <li>Product Reviews Ratings</li>
                                      <li>Product Summary Reports (Out of stock / Most Sold / Lowest sale etc) By Date</li>
                                      <li>Multiple Filtrations Option</li>
                                      <li>Multiple Shorting Options (Newest / Price / Alphabetical / Rating)</li>
                                      <li>Product View with Multiple Views</li>
                                      <li>Product Rating</li>
                                      <li>Product Detail with Similar Product Range</li>
                                      <li>Coupon Code Generation (by category and product)</li>
                                      <li>Product Comparison</li>
                                      <li>User Signup Area</li>
                                      <li>User Sign-in Area</li>
                                      <li>User Dashboard Area</li>
                                      <li>Auto Email for Profile Validation Notification(s)</li>
                                      <li>Last Purchase Order Details (download invoice)</li>
                                      <li>Customer Login / Registration via Social Login Facebook and Google+</li>
                                      <li>Customer profile management (From Backend and Frontend)</li>
                                      <li>Discounted Products Showcase</li>
                                      <li>New Arrival Products Showcase</li>
                                      <li>Discount Coupons</li>
                                      <li>Easy Product Search Bar</li>
                                      <li>Shopping Cart Integration</li>
                                      <li>Payment Module Integration</li>
                                      <li>Multiple Currency Integration</li>
                                      <li>Direct Checkout</li>
                                      <li>Sign up Checkout</li>
                                      <li>Tax calculator Integration</li>
                                      <li>Shipping calculator Integration</li>
                                      <li>Wishlist</li>
                                      <li>Import/Export Order</li>
                                      <li>Bulk Import/export of Order information</li>
                                      <li>Bulk Product Upload From Excel Sheet</li>
                                      <li>Export order into excel sheet</li>
                                      <li>Live Chat Integration</li>
                                      <li>Search Engine Submission</li>
                                      <li>SEO Friendly</li>
                                      <li>Web Analytics Integration</li>
                                      <li>Monthly Traffic Reporting</li>
                                      <li>Social Media Pages Integration</li>
                                      <li>Facebook Shop Setup</li>
                                      <li>Facebook “Like” Plugin integration</li>
                                      <li>Blog page (If Required)</li>
                                      <li>Sitemap</li>
                                      <li>Complete W3C Validation</li>
                                      <li>Dedicated Team of Designers and Developers</li>
                                      <li>Complete Deployment</li>
                                      <li>Complete Source Files</li>
                                      <li>Dedicated Project Manager</li>
                                      <li>100% Ownership Rights</li>
                                      <li>100% Satisfaction Guarantee</li>
                                      <li>100% Money Back Guarantee</li>
                                      <li>*NO MONTHLY OR ANY HIDDEN FEE*</li>
                                  </ul>

                                  <div className="delivery">
                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                  </div>
                                  <div className="pricing-btns">
                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="pricing-card">
                              <div className="main-box">
                                  <LazyLoadImage src={pricing_bg} alt="img not found" effect="blur"  className="img-fluid"/>
                              </div>
                              <div className="title">
                                  <h2>E-Commerce Elite Package</h2>
                              </div>
                              <div className="inner-box">
                                  <h5>STARTING AT</h5>
                                  <h4>$15000</h4>
                                  <ul>
                                      <li>E-Commerce Website Design and Development</li>
                                      <li>Customized Tailor-Made Design</li>
                                      <li>Professional</li>
                                      <li>Modern</li>
                                      <li>Unique Design</li>
                                      <li>10 Banner Designs</li>
                                      <li>Sliding Banner</li>
                                      <li>10 Stock Photos</li>
                                      <li>Unlimited Revisions</li>
                                      <li>Special Hover Effects</li>
                                      <li>Up to 500 Products</li>
                                      <li>Up to 25 Categories</li>
                                      <li>Product Reviews Ratings</li>
                                      <li>Content/Inventory Management System</li>
                                      <li>Mobile Responsive</li>
                                      <li>User Signup Area</li>
                                      <li>User Sign-in Area</li>
                                      <li>User Dashboard Area</li>
                                      <li>Auto Email for Profile Validation Notification(s)</li>
                                      <li>Easy Product Search Bar</li>
                                      <li>Shopping Cart Integration</li>
                                      <li>Payment Module Integration</li>
                                      <li>Direct Checkout</li>
                                      <li>Sign up Checkout</li>
                                      <li>Tax calculator Integration</li>
                                      <li>Shipping calculator Integration</li>
                                      <li>Wishlist</li>
                                      <li>Import/Export Order</li>
                                      <li>Bulk Import/export of Order information</li>
                                      <li>Search Engine Submission</li>
                                      <li>SEO Friendly</li>
                                      <li>Web Analytics Integration</li>
                                      <li>Monthly Traffic Reporting</li>
                                      <li>Social Media Pages Integration</li>
                                      <li>Facebook “Like” Plugin integration</li>
                                      <li>Blog page (If Required)</li>
                                      <li>Sitemap</li>
                                      <li>Complete W3C Validation</li>
                                      <li>Dedicated Team of Designers and Developers</li>
                                      <li>Complete Deployment</li>
                                      <li>Complete Source Files</li>
                                      <li>Dedicated Project Manager</li>
                                      <li>100% Ownership Rights</li>
                                      <li>100% Satisfaction Guarantee</li>
                                      <li>100% Money Back Guarantee</li>
                                      <li>*NO MONTHLY OR ANY HIDDEN FEE*</li>
                                  </ul>

                                  <div className="delivery">
                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                  </div>
                                  <div className="pricing-btns">
                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="profund-sec-wrap">
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">
                          <div className="content">
                              <h2>Our E-commerce Web Development Process</h2>
                          </div>
                      </div>
                  </div>
                  <div className="row justify-content-center">
                      <div className="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3 col-xxl-3">
                          <div className="profund-card">
                              <div className="step">
                                  <h5>01</h5>
                              </div>
                              <div className="inner-box">
                                  <div className="main-img">
                                      <LazyLoadImage src={icon_17} alt="img not found" effect="blur"  className="img-fluid"/>
                                  </div>
                                  <div className="desc">
                                      <h4>Know your Business Goals</h4>
                                      <p>We first seek to understand your business goals to ensure that our e-commerce solution aligns with your overall business strategy.</p>
                                  </div>
                                  <div className="bg-img">
                                      <LazyLoadImage src={profund_bg} alt="img not found" effect="blur"  className="img-fluid"/>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3 col-xxl-3">
                          <div className="profund-card">
                              <div className="step">
                                  <h5>02</h5>
                              </div>
                              <div className="inner-box">
                                  <div className="main-img">
                                      <LazyLoadImage src={icon_18} alt="img not found" effect="blur"  className="img-fluid"/>
                                  </div>
                                  <div className="desc">
                                      <h4>Learn Your intended shopping experience</h4>
                                      <p>We learn your intended shopping experience for your customers to create a seamless shopping experience.</p>
                                  </div>
                                  <div className="bg-img">
                                      <LazyLoadImage src={profund_bg} alt="img not found" effect="blur"  className="img-fluid"/>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3 col-xxl-3">
                          <div className="profund-card">
                              <div className="step">
                                  <h5>03</h5>
                              </div>
                              <div className="inner-box">
                                  <div className="main-img">
                                      <LazyLoadImage src={icon_6} alt="img not found" effect="blur"  className="img-fluid"/>
                                  </div>
                                  <div className="desc">
                                      <h4>Discuss payment and packages</h4>
                                      <p>We discuss the various platform options available for your e-commerce website, taking into consideration your budget and other requirements</p>
                                  </div>
                                  <div className="bg-img">
                                      <LazyLoadImage src={profund_bg} alt="img not found" effect="blur"  className="img-fluid"/>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3 col-xxl-3">
                          <div className="profund-card">
                              <div className="step">
                                  <h5>04</h5>
                              </div>
                              <div className="inner-box">
                                  <div className="main-img">
                                      <LazyLoadImage src={icon_17} alt="img not found" effect="blur"  className="img-fluid"/>
                                  </div>
                                  <div className="desc">
                                      <h4>QA & Launch</h4>
                                      <p>We thoroughly test it to ensure it meets quality assurance standards. Once testing is complete, the website is launched to the public.</p>
                                  </div>
                                  <div className="bg-img">
                                      <LazyLoadImage src={profund_bg} alt="img not found" effect="blur"  className="img-fluid"/>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
      <Footer />
    </>
  )
}

export default Ecommerce
