import React from 'react'

const process = () => {
  return (
    <>
          <section className="our-process">
              <div className="container">
                  <div className="row text-center justify-content-center">
                      <div className="col-10 head_area">
                          <h2>Our Process</h2>
                          <p>Our process comprises 4 simple steps, giving you professional web design solutions
                              for your business!</p>
                      </div>
                  </div>
                  <div className="our-process-bg">
                      <div className="row justify-content-between">
                          <div className="col-lg-3 col-12">
                              <div className="our-process-step step1">
                                  <h4>Analysis</h4>
                                  <p>Codex Design Agency starts with thorough research and analysis to understand clients' needs
                                      and goals.</p>
                              </div>
                          </div>
                          <div className="col-lg-3 col-12">
                              <div className="our-process-step step2">
                                  <h4>Design</h4>
                                  <p>Our team then creates an eye-catching design to ensure a unique and attractive website</p>
                              </div>
                          </div>
                      </div>
                      <div className="row justify-content-between">
                          <div className="col-lg-3 col-12">
                              <div className="our-process-step step3">
                                  <h4>Development</h4>
                                  <p>The design is brought to life with advanced development techniques that make your website
                                      functional.</p>
                              </div>
                          </div>
                          <div className="col-lg-3 col-12">
                              <div className="our-process-step step4">
                                  <h4>Launch</h4>
                                  <p>Finally, the website is launched and optimized for maximum impact, helping you achieve your
                                      goals.</p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
    </>
  )
}

export default process
