import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
// import $ from 'jquery';
// import Popper from 'popper.js';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// some stuff to help react js 
// https://dev.to/luispa/how-to-add-a-dynamic-title-on-your-react-app-1l7k
// https://www.freecodecamp.org/news/react-router-in-5-minutes 
// https://www.geeksforgeeks.org/how-to-install-bootstrap-in-react-js/
// https://dev.to/slimpython/how-to-add-zendesk-chat-widget-in-react-js-1jhm
reportWebVitals();
