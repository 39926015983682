import React from 'react'
import author_website_img from '../assets/images/author-website-img.png';
import Header from '../includes/header';
import Footer from '../includes/footer';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import professional_img from '../assets/images/professional-img.png';
import tire_img_1 from '../assets/images/tire-img-1.png';
import tire_img_2 from '../assets/images/tire-img-2.png';
import tire_img_3 from '../assets/images/tire-img-3.png';
import cta_img from '../assets/images/cta-img.webp';
import useDocumentTitle from '../useDocumentTitle.jsx'
import Book_Sliders from '.././json/book_slider.json'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';




const AuthorWebsite = () => {
    useDocumentTitle('Author-Website')
    const Book_Slider = {
        loop: true,
        margin: 20,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        nav: false,
        dots: false,
        autoplay: true,
        autoplaySpeed: 1000,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        smartSpeed: 450,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1
            },
            575: {
                items: 2
            },
            767: {
                items: 2
            },
            991: {
                items: 3
            },
            1199: {
                items: 4
            },
            1399: {
                items: 4
            }
        }
    };

  return (
    <>
        <Header/>
          <section className="inner-banner-sec">
              <div className="container">
                  <div className="row align-items-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="banner-content">
                              <h1>The World Awaits Your Amazing Story! Best Professional Ghostwriting Service</h1>
                              <p>We will incorporate Creativity with Matchless Excellence. Let our ghostwriters fire the
                                  imagination of your audience with their killer writing techniques. We will make your story
                                  heard and keep the readers hooked on it until the end.</p>
                              <div className="banner-btn">
                                  <a href="javascript:void(0);" className="btn btn-contact">Connect with us now!</a>
                                  <a href="javascript:void(0);" className="btn btn-chat"><i className="fa-solid fa-messages"></i></a>
                              </div>

                              <div className="banner-form">
                                  <form action="https://leads.infinityprojectmanager.com/br/and/codexdesignagency/lead" className="popup-form CrudForm cmxform">
                                      <input type="hidden" name="br/and_name" defaultValue="codexdesignagency.com" />
                                      <input type="hidden" id="lead_area" name="lead_area" defaultValue="https://codexdesignagency.com/" />
                                      <div className="row">
                                          <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 p-md-0">
                                              <div className="form-group">
                                                  <input type="text" className="form-control" placeholder="Enter Name" name="name" required />
                                              </div>
                                          </div>
                                          <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 pe-md-0">
                                              <div className="form-group">
                                                  <input type="email" className="form-control" placeholder="Enter Email" name="email" required />
                                              </div>
                                          </div>
                                          <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 pe-md-0">
                                              <div className="form-group">
                                                  <input type="text" className="form-control" placeholder="Phone Number" name="number" required />
                                              </div>
                                          </div>
                                          <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 pe-md-0">
                                              <div className="form-btn">
                                                  <button type="submit" className="btn btn-form">Submit</button>
                                              </div>
                                          </div>
                                      </div>
                                  </form>
                              </div>
                          </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="main-img">
                              <LazyLoadImage src={author_website_img} effect="blur"className="img-fluid" alt="img not found"/>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="book-sec-wrap">
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">
                          <div className="content">
                              <h2>Let’s together share your story with the world as our eloquent writers give words to your
                                  ideas.</h2>
                              <p>Our writing experts provide end-to-end writing and publishing services so that you can enjoy
                                  the masterpiece that we create</p>
                          </div>
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <div className="book-slider">
                              <OwlCarousel {...Book_Slider} className="owl-carousel owl-theme">
                                  {
                                      Book_Sliders.map((item) => {
                                          return (
                                              <div className="item" key={item.id}>
                                                  <div className="book-card">
                                                      <div className="mian-img">
                                                          <LazyLoadImage src={item.img} className="img-fluid" alt="img not found" />
                                                      </div>
                                                      <div className="desc">
                                                          <h4>{item.title}</h4>
                                                          <p>{item.desc}</p>
                                                      </div>
                                                  </div>
                                              </div>
                                          )
                                      })

                                  }
                              </OwlCarousel>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="professional-sec-wrap">
              <div className="container">
                  <div className="row align-items-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xxl-7">
                          <div className="content">
                              <h2>WITH A PROFESSIONAL AND RELIABLE AUTHOR WEBSITE, YOU CAN GAIN WORLDWIDE
                                  RECOGNITION</h2>
                              <p>A website for an author is the quickest and easiest way for the rest of the
                                  world to learn about you. If you don't already own a professional website, you can
                                  step up your game and hire a web designer to create your author's website. Then,
                                  immediately get in touch with the Codex Design Agency to give your name a
                                  professional look and become the brand you've always wanted to be.</p>
                              <div className="professional-btn">
                                  <a href="javascript:void(0);" className="btn btn-professional"><i className="fa-solid fa-envelope"></i> Get
                                      a Free Proposal</a>
                                  <a href="javascript:void(0);" className="btn btn-professional"><i className="fa-solid fa-headphones"></i>
                                      Talk to an Expert</a>
                                  <a href="javascript:void(0);" className="btn btn-professional"><i className="fas fa-phone-volume"></i>
                                      +1-(844)-452-0008</a>
                              </div>
                          </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-5">
                          <div className="main-img">
                              <LazyLoadImage src={professional_img} className="img-fluid" alt="img not found"/>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="cta-sec-wrap">
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-5">
                          <div className="cta-img">
                              <LazyLoadImage src={cta_img} className="img-fluid" alt="img not found"/>
                          </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="cta-content">
                              <h4>ARE YOU HAVING DIFFICULTY GETTING YOUR AUTHOR'S WEBSITE UP AND RUNNING?</h4>
                              <p>Why not hire a professional website developer to help you develop your professionalbrand?.
                              </p>
                              <div className="cta-btn">
                                  <a href="javascript:void(0);" className="btn btn-cta">Get Started</a>
                                  <a href="javascript:void(0);" className="btn btn-cta">Live Chat</a>
                              </div>
                              <div className="cta-number">
                                  <a href="tel:+1-(844)-452-0008">Call Us +1-(844)-452-0008</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="tier-sec-wrap">
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">
                          <div className="title">
                              <h2>WITH OUR EXPERT WEBSITE DEVELOPERS, WE HOPE TO TURN YOU INTO A RECORD-BREAKING AUTHOR!</h2>
                          </div>
                      </div>
                  </div>

                  <div className="row align-items-center">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                          <div className="tier-card active">
                              <div className="tier-img">
                                  <LazyLoadImage src={tire_img_1} className="img-fluid" alt="img not found"/>
                              </div>
                              <div className="desc">
                                  <h4>User-Friendly Interface</h4>
                                  <p>Our professionals will design your website with your clients in mind, ensuring a
                                      smooth and seamless customer experience.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                          <div className="tier-card">
                              <div className="tier-img">
                                  <LazyLoadImage src={tire_img_2}className="img-fluid" alt="img not found"/>
                              </div>
                              <div className="desc">
                                  <h4>Appealing Layout Design</h4>
                                  <p>Give us the design you have in mind, and we'll make it a reality. If not, we may create
                                      one for you based on your preferences</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                          <div className="tier-card">
                              <div className="tier-img">
                                  <LazyLoadImage src={tire_img_3} className="img-fluid" alt="img not found"/>
                              </div>
                              <div className="desc">
                                  <h4>Light On Your Wallet</h4>
                                  <p>Money will not be an issue; Book Writing Experts do not believe in profiting from their
                                      clients' requirements. As a result, we provide reasonably priced packages to our
                                      clients.</p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="timeline-sec">
              <div className="container">
                  <div className="row justify-content-center text-center">
                      <div className="col-md-8 mx-auto">
                          <h4 className="heading">OUR PROCESS</h4>
                      </div>
                  </div>
                  <div className="row mt-4">
                      <div className="col-md-12 mx-auto">
                          <div className="row mt-4 timelineSec__box">
                              <div className="col-md-5">
                                  <div className="card timeline-right-arrow">
                                      <div className="card-body">
                                          <div className="card-title">Extensive Study as well as a Draught Outline</div>
                                          <p>We look forward to learning about and understanding your needs in order to create
                                              a well-written plot following considerable research.</p>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-md-5 ms-auto"><span
                                  className="text-orange d-flex h-100 justify-content-start align-items-center">01</span>
                              </div>
                          </div>
                          <div className="row pt-4 timelineSec__box">
                              <div className="col-md-5 order-md-2">
                                  <div className="card timeline-left-arrow">
                                      <div className="card-body">
                                          <div className="card-title">Original Content Creation</div>
                                          <p>Our storytellers will begin composing client’s first draught involving ideas
                                              shared by the client as soon as client approval on the well researched plan.</p>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-md-5 me-auto order-md-1 text-end"><span
                                  className="text-orange d-flex h-100 justify-content-end align-items-center">02</span></div>
                          </div>
                          <div className="row pt-4 timelineSec__box">
                              <div className="col-md-5">
                                  <div className="card timeline-right-arrow">
                                      <div className="card-body">
                                          <div className="card-title">Editing, Proofreading, and Evaluative Review</div>
                                          <p>For the sake of improving our writing process, we continue to modify draughts in
                                              several revisions as per the client demand and satisfaction.</p>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-md-5 ms-auto"><span
                                  className="text-orange d-flex h-100 justify-content-start align-items-center">03</span>
                              </div>
                          </div>
                          <div className="row pt-4 timelineSec__box">
                              <div className="col-md-5 order-md-2">
                                  <div className="card timeline-left-arrow">
                                      <div className="card-body">
                                          <div className="card-title">Significant Layout, Design, and Typography</div>
                                          <p>When the first text has been finished, edited, proofread, and approved by our
                                              client, we will begin formatting it in great detail.</p>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-md-5 me-auto order-md-1 text-end"><span
                                  className="text-orange d-flex h-100 justify-content-end align-items-center">04</span></div>
                          </div>
                          <div className="row pt-4 timelineSec__box">
                              <div className="col-md-5">
                                  <div className="card">
                                      <div className="card-body">
                                          <div className="card-title">Final submission To The Client</div>
                                          <p>The formatted manuscript is then sent to the client for final approval. Once the
                                              client is satisfied with the overall outcome, is it sent for the final process
                                              of publishing.</p>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-md-5 ms-auto"><span
                                  className="text-orange d-flex h-100 justify-content-start align-items-center">05</span>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="cta-form-wrap">
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">
                          <div className="title">
                              <h2>Let’s Get Started with Codex Design Agency</h2>
                          </div>
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <div className="content">
                              <form action="https://leads.infinityprojectmanager.com/brand/codexdesignagency/lead"
                                  className="popup-form CrudForm cmxform">
                                    <input type="hidden" name="brand_name"defaultValue="codexdesignagency.com"/> 
                                      <input type="hidden" id="lead_area" name="lead_area" defaultValue="https://codexdesignagency.com/"/>

                                          <div className="row">
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                                                  <div className="row">
                                                      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                                          <div className="form-group">
                                                              <input type="text" className="form-control" placeholder="full Name" name="name" required/>
                                                          </div>
                                                      </div>

                                                      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                                          <div className="form-group">
                                                      <input type="email" className="form-control" placeholder="email" name="email" required />
                                                          </div>
                                                      </div>

                                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                                          <div className="form-group">
                                                      <input type="text" className="form-control" placeholder="Phone number" name="number" required />
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                                                  <div className="row">
                                                      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                                          <div className="form-group">
                                                      <textarea className="form-control" rows="4" placeholder="Message" name="message" required></textarea>
                                                          </div>
                                                      </div>

                                                      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                                          <div className="cta-form-btn">
                                                              <button type="submit" className="btn btn-form">Submit</button>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </form>
                                  </div>
                          </div>
                      </div>
                  </div>
          </section>
        <Footer/>

    </>
  )
}

export default AuthorWebsite
