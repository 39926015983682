import React from 'react'
import Header from '../includes/header';
import Footer from '../includes/footer';

import conact_banner_img from '../assets/images/conact-banner-img.png';
import contact_img from '../assets/images/contact-img.png';
import useDocumentTitle from '../useDocumentTitle.jsx'

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Contact = () => {
    useDocumentTitle('Contact')
  return (
    <>
        <Header/>
            <section className="inner-banner-sec">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <div className="banner-content">
                                <h1>Contact Us</h1>
                                <p>Contact our customer support team to register your project!</p>
                                <div className="banner-btn">
                                    <a href="javascript:void(0);" className="btn btn-contact">Schedule A Meeting</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <div className="main-img">
                              <LazyLoadImage src={conact_banner_img} className="img-fluid" effect="blur" alt="img not found"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact-sec-wrap">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                            <div className="contact-info">
                                <h2>Get In Touch</h2>
                                <h3>Send us a Message!</h3>
                                <p>Fill in your contact details below and let us know about your ideas that you want us to
                                    enhance or work over!</p>
                                     <form action="https://leads.infinityprojectmanager.com/br/and/codexdesignagency/lead" className="popup-form CrudForm cmxform">
                                        <input type="hidden" name="br/and_name" defaultValue="codexdesignagency.com" />
                                        <input type="hidden" id="lead_area" name="lead_area" defaultValue="https://codexdesignagency.com/" />
                                            <div className="row">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                                            <div className="form-group">
                                              <input type="text" className="form-control" placeholder="Name" name="name" required />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                                            <div className="form-group">
                                              <input type="email" className="form-control" placeholder="Email" name="email" required />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                                            <div className="form-group">
                                              <input type="text" className="form-control" placeholder="Contact Number" name="number" required />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                                            <div className="form-group">
                                                <select className="form-select" name="" aria-label="Default select example">
                                                    <option selected="">Select Services</option>
                                                    <option defaultValue="Web Design">Web Design</option>
                                                    <option defaultValue="Web development">Web development</option>
                                                    <option defaultValue="Logo Design">Logo Design</option>
                                                    <option defaultValue="Ecommerce Solutions">Ecommerce Solutions</option>
                                                    <option defaultValue="Animation">Animation</option>
                                                    <option defaultValue="PPC">PPC</option>
                                                    <option defaultValue="SEO Services">SEO Services</option>
                                                    <option defaultValue="Digital Marketing">Digital Marketing</option>
                                                    <option defaultValue="Creative Copywriting">Creative Copywriting</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                            <div className="form-group">
                                                <textarea className="form-control" name="message" rows="3" placeholder="Message" required></textarea>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" defaultValue="" id="flexCheckDefault"/>
                                                    <label className="form-check-label" for="flexCheckDefault">
                                                        Please send me a Non Disclouser Agreement for a Confidential Consultation.
                                                    </label>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                            <div className="contact-btn">
                                                <a href="javascript:void(0);" className="btn btn-contact">Send Message</a>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <div className="main-img">
                              <LazyLoadImage src={contact_img} alt="img not found" className="img-fluid" effect="blur"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="details-sec-wrap">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-2 col-xl-2 col-xxl-2">
                            <div className="information">
                                <h4>Call Us</h4>
                                <div className="info">
                                    <i className="fas fa-phone"></i><a href="tel:+1-(844)-452-0008">+1-(844)-452-0008</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4 d-flex justify-content-sm-center">
                            <div className="information">
                                <h4>Email</h4>
                                <div className="info">
                                    <i className="fas fa-envelope"></i>
                                    <a href="mailto: info@codexdesignagency.com">
                                        <span> info@codexdesignagency.com</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                            <div className="information">
                                <h4>Address</h4>
                                <div className="info">
                                    <i className="fas fa-location-dot"></i>
                                    <p>4900 California Avenue,
                                        Tower B, 2nd Floor, Bakersfield, California, 93309, United States of America</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-2 col-xl-2 col-xxl-2">
                            <div className="information">
                                <h4>Live Chat</h4>
                                <div className="info">
                                    <i className="fas fa-comments"></i><a href="void:;">Start Chat</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        <Footer/>
    </>
  )
}

export default Contact
