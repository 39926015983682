import React from 'react'
import web_img from '../assets/images/web-img.png';
const cta = () => {
  return (
    <div>
          <section className="cta">
              <div className="container">
                  <div className="cta-bg shadow-lg">
                      <div className="row align-items-center justify-content-between">
                          <div className="col-lg-6 col-12 col-md-7">
                              <h3>So Are You Ready To Generate More Revenue?</h3>
                              <p>Our custom website designing agency &amp; writing services is just a call away!</p>
                              <div className="d-flex">
                                  <button data-bs-toggle="modal" data-bs-target="#exampleModal">Get A Quote</button>
                                  <a href="javascript:$zopim.livechat.window.show();">
                                      <i className="fa-solid fa-messages"></i>
                                  </a>
                              </div>
                          </div>
                          <div className="col-lg-6 col-12 col-md-5">
                              <img src={web_img} className="img-fluid" alt="img not found"/>
                          </div>
                      </div>

                  </div>

              </div>
          </section>
    </div>
  )
}

export default cta
