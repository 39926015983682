import './App.css';
import { Route, Routes } from "react-router-dom"
// import Zendesk, { ZendeskAPI } from "./ZedeskConfig";
import Home from './Pages/Home';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Review from './Pages/Review';
import Packages from './Pages/Packages';
import ComboPackages from './Pages/ComboPackages';
import Animation from './Pages/Animation';
import Author_Website from './Pages/AuthorWebsite';
import CreativeWritting from './Pages/CreativeWritting';
import DigitalMarketing from './Pages/DigitalMarketing';
import Seo from './Pages/Seo';
import PPC from './Pages/ppc';
import Logo_design from './Pages/LogoDesign';
import Web_Design from './Pages/WebDesign';
import Ecommerce from './Pages/Ecommerce';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermsCondition from './Pages/TermsCondition';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope, faAngleUp } from "@fortawesome/free-solid-svg-icons";
library.add(faEnvelope, faAngleUp);
// const ZENDESK_KEY = "bdc23535-5173-4c6f-b2c0-f40dce54897f";


function App() {
  // const handleLoaded = () => {
  //   ZendeskAPI("messenger", "open");
  // };

  return ( 
    <>
      {/* <Zendesk defer zendeskKey={ZENDESK_KEY} onLoaded={handleLoaded} /> */}
        <Routes>
          <Route path="/" element={<Home />}  />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="/review" element={<Review />} />
          <Route path="/combo-packages" element={<ComboPackages />} />
          <Route path="/packages" element={<Packages />} />
          <Route path="/animation" element={<Animation />} />
          <Route path="/author-website" element={<Author_Website />} />
          <Route path="/creative-writting" element={<CreativeWritting />} />
          <Route path="/digital-marketing" element={<DigitalMarketing />} />
          <Route path="/seo" element={<Seo />} />
          <Route path="/ppc" element={<PPC />} />
          <Route path="/logo-design" element={<Logo_design />} />
          <Route path="/web-design" element={<Web_Design />} />
          <Route path="/ecommerce" element={<Ecommerce />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-condition" element={<TermsCondition />} />
        </Routes>
    </>
  );
}

export default App;
