import React from 'react'

import pricing_bg from '../assets/images/pricing-bg.png';


const pricing = () => {
  return (
    <>

          <section className="pricing-sec-wrap">
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">
                          <div className="content">
                              <h2>Pricing Plans</h2>
                          </div>
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                              <li className="nav-item" role="presentation">
                                  <button className="nav-link active" id="pills-WebDesign-tab" data-bs-toggle="pill"
                                      data-bs-target="#pills-WebDesign" type="button" role="tab"
                                      aria-controls="pills-WebDesign" aria-selected="true">Web Design</button>
                              </li>
                              <li className="nav-item" role="presentation">
                                  <button className="nav-link" id="pills-LogoDesign-tab" data-bs-toggle="pill"
                                      data-bs-target="#pills-LogoDesign" type="button" role="tab"
                                      aria-controls="pills-LogoDesign" aria-selected="false">Logo Design</button>
                              </li>
                              <li className="nav-item" role="presentation">
                                  <button className="nav-link" id="pills-Ecommerce-tab" data-bs-toggle="pill"
                                      data-bs-target="#pills-Ecommerce" type="button" role="tab"
                                      aria-controls="pills-Ecommerce" aria-selected="false">Ecommerce</button>
                              </li>
                              <li className="nav-item" role="presentation">
                                  <button className="nav-link" id="pills-SeoServices-tab" data-bs-toggle="pill"
                                      data-bs-target="#pills-SeoServices" type="button" role="tab"
                                      aria-controls="pills-SeoServices" aria-selected="false">Seo Services</button>
                              </li>
                              <li className="nav-item" role="presentation">
                                  <button className="nav-link" id="pills-CreativeWriting-tab" data-bs-toggle="pill"
                                      data-bs-target="#pills-CreativeWriting" type="button" role="tab"
                                      aria-controls="pills-CreativeWriting" aria-selected="false">Creative Writing</button>
                              </li>
                              <li className="nav-item" role="presentation">
                                  <button className="nav-link" id="pills-VideoAnimation-tab" data-bs-toggle="pill"
                                      data-bs-target="#pills-VideoAnimation" type="button" role="tab"
                                      aria-controls="pills-VideoAnimation" aria-selected="false">Video Animation</button>
                              </li>
                              <li className="nav-item" role="presentation">
                                  <button className="nav-link" id="pills-DigitalMarketing-tab" data-bs-toggle="pill"
                                      data-bs-target="#pills-DigitalMarketing" type="button" role="tab"
                                      aria-controls="pills-DigitalMarketing" aria-selected="false">Digital Marketing</button>
                              </li>
                          </ul>
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <div className="tab-content" id="pills-tabContent">
                              <div className="tab-pane fade show active" id="pills-WebDesign" role="tabpanel"
                                  aria-labelledby="pills-WebDesign-tab" tabIndex="0">
                                  <div className="row justify-content-center">
                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>Startup Website Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$245</h4>
                                                  <ul>
                                                      <li>5 Stock Photos</li>
                                                      <li>5 Page Website</li>
                                                      <li>3 Banner Design</li>
                                                      <li>1 jQuery Slider Banner</li>
                                                      <li>FREE Google Friendly Sitemap</li>
                                                      <li>Complete W3C Certified HTML</li>
                                                      <li>Facebook Page Design</li>
                                                      <li>Twitter Page Design</li>
                                                      <li>YouTube Page Design</li>
                                                      <li>100% Satisfaction Guarantee</li>
                                                      <li>100% Unique Design Guarantee</li>
                                                      <li>100% Money Back Guarantee</li>
                                                      <li>Mobile Responsive will be Additional $99</li>
                                                      <li>CMS will be Additional $149</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>Professional Website Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$695</h4>
                                                  <ul>
                                                      <li>10 Unique Pages Website</li>
                                                      <li>Mobile Responsive</li>
                                                      <li>5 Stock images</li>
                                                      <li>3 Banner Designs</li>
                                                      <li>1 jQuery Slider Banner</li>
                                                      <li>FREE Google Friendly Sitemap</li>
                                                      <li>Complete W3C Certified HTML</li>
                                                      <li>Facebook Page Design</li>
                                                      <li>Twitter Page Design</li>
                                                      <li>YouTube Page Design</li>
                                                      <li>Complete Deployment</li>
                                                      <li>100% Satisfaction Guarantee</li>
                                                      <li>100% Unique Design Guarantee</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>Elite Website Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$995</h4>
                                                  <ul>
                                                      <li>Upto 15 Unique Pages Website</li>
                                                      <li>Conceptual and Dynamic Website</li>
                                                      <li>Mobile Responsive</li>
                                                      <li>Online Reservation/Appointment Tool (Optional)</li>
                                                      <li>Online Payment Integration (Optional)</li>
                                                      <li>Custom Forms</li>
                                                      <li>Lead Capturing Forms (Optional)</li>
                                                      <li>Striking Hover Effects</li>
                                                      <li>Newsletter Subscription (Optional)</li>
                                                      <li>Newsfeed Integration</li>
                                                      <li>Social Media Integration</li>
                                                      <li>Search Engine Submission</li>
                                                      <li>8 Stock Photos</li>
                                                      <li>5 Unique Banner Design</li>
                                                      <li>1 jQuery Slider Banner</li>
                                                      <li>Complete W3C Certified HTML</li>
                                                      <li>Facebook Page Design</li>
                                                      <li>Twitter Page Design</li>
                                                      <li>YouTube Page Design</li>
                                                      <li>Complete Deployment</li>
                                                      <li>100% Satisfaction Guarantee</li>
                                                      <li>100% Unique Design Guarantee</li>
                                                      <li>100% Money Back Guarantee *</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>Silver Website Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$1595</h4>
                                                  <ul>
                                                      <li>15 to 20 Pages Website</li>
                                                      <li>Custom Made</li>
                                                      <li>Interactive</li>
                                                      <li>Dynamic High End Design</li>
                                                      <li>Custom WP (or) Custom PHP Development</li>
                                                      <li>1 jQuery Slider Banner</li>
                                                      <li>Up to 10 Custom Made Banner Designs</li>
                                                      <li>10 Stock Images</li>
                                                      <li>Unlimited Revisions</li>
                                                      <li>Special Hoover Effects</li>
                                                      <li>Content Management System (CMS)</li>
                                                      <li>Online Appointment/Scheduling/Online Ordering Integration (Optional)
                                                      </li>
                                                      <li>Online Payment Integration (Optional)</li>
                                                      <li>Multi Lingual (Optional)</li>
                                                      <li>Custom Dynamic Forms (Optional)</li>
                                                      <li>Signup Area (For Newsletters</li>
                                                      <li>Offers etc.)</li>
                                                      <li>Search Bar</li>
                                                      <li>Live Feeds of Social Networks integration (Optional)</li>
                                                      <li>Mobile Responsive</li>
                                                      <li>Free Google Friendly Sitemap</li>
                                                      <li>Search Engine Submission</li>
                                                      <li>Complete W3C Certified HTML</li>
                                                      <li>Industry Specified Team of Expert Designers and Developers</li>
                                                      <li>Complete Deployment</li>
                                                      <li>Dedicated Accounts Manager</li>
                                                      <li>Facebook Page Design</li>
                                                      <li>Twitter Page Design</li>
                                                      <li>YouTube Page Design</li>
                                                      <li>100% Ownership Rights</li>
                                                      <li>100% Satisfaction Guarantee</li>
                                                      <li>100% Unique Design Guarantee</li>
                                                      <li>100% Money Back Guarantee *</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>Business Website Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$2495</h4>
                                                  <ul>
                                                      <li>15 to 20 Pages Website</li>
                                                      <li>Custom Made</li>
                                                      <li>Interactive</li>
                                                      <li>Dynamic High End Design</li>
                                                      <li>Custom WP (or) Custom PHP Development</li>
                                                      <li>1 jQuery Slider Banner</li>
                                                      <li>Up to 10 Custom Made Banner Designs</li>
                                                      <li>10 Stock Images</li>
                                                      <li>Unlimited Revisions</li>
                                                      <li>Special Hoover Effects</li>
                                                      <li>Content Management System (CMS)</li>
                                                      <li>Online Appointment/Scheduling/Online Ordering Integration (Optional)
                                                      </li>
                                                      <li>Online Payment Integration (Optional)</li>
                                                      <li>Multi Lingual (Optional)</li>
                                                      <li>Custom Dynamic Forms (Optional)</li>
                                                      <li>Signup Area (For Newsletters</li>
                                                      <li>Offers etc.)</li>
                                                      <li>Search Bar</li>
                                                      <li>Live Feeds of Social Networks integration (Optional)</li>
                                                      <li>Mobile Responsive</li>
                                                      <li>15 Seconds 2D Explainer Video</li>
                                                      <li>Voice - Over Sound Effects</li>
                                                      <li>Professional Script Writing</li>
                                                      <li>Storyboard</li>
                                                      <li>SEO Meta Tags</li>
                                                      <li>Free Google Friendly Sitemap</li>
                                                      <li>Search Engine Submission</li>
                                                      <li>Complete W3C Certified HTML</li>
                                                      <li>Industry Specified Team of Expert Designers and Developers</li>
                                                      <li>Complete Deployment</li>
                                                      <li>Dedicated Accounts Manager</li>
                                                      <li>100% Ownership Rights</li>
                                                      <li>100% Satisfaction Guarantee</li>
                                                      <li>100% Unique Design Guarantee</li>
                                                      <li>100% Money Back Guarantee *</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>Automated/Interactive Conferencing Portal Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$4995</h4>
                                                  <ul>
                                                      <li>Unlimited Page Website</li>
                                                      <li>Custom Content Management System (CMS)</li>
                                                      <li>Unique Pages and UI Design</li>
                                                      <li>Complete Custom Development</li>
                                                      <li>Process Automation Tools</li>
                                                      <li>Newsfeed Integration</li>
                                                      <li>Social Media Plugins Integration</li>
                                                      <li>Upto 40 Stock images</li>
                                                      <li>10 Unique Banner Designs</li>
                                                      <li>JQuery Slider</li>
                                                      <li>Search Engine Submission</li>
                                                      <li>Free Google Friendly Sitemap</li>
                                                      <li>Custom Email Addresses</li>
                                                      <li>Social Media Page Designs (Facebook/ Twitter/ Instagram)</li>
                                                      <li>Complete W3C Certified HTML</li>
                                                      <li>Complete Deployment</li>
                                                      <li>100% Satisfaction Guarantee</li>
                                                      <li>100% Unique Design Guarantee</li>
                                                      <li>Money Back Guarantee</li>
                                                      <li>Key features</li>
                                                      <li>Automated Course Creation</li>
                                                      <li>Video Conferencing</li>
                                                      <li>Skills/Certification Tracking</li>
                                                      <li>Mobile Learning</li>
                                                      <li>Asynchronous Learning</li>
                                                      <li>CRM Features</li>
                                                      <li>Gamification</li>
                                                      <li>Social Learning/Message Boards</li>
                                                      <li>Motivational Triggers</li>
                                                      <li>Forums And Webinars</li>
                                                      <li>E-commerce And Subscriptions</li>
                                                      <li>Online Course Booking</li>
                                                      <li>Excellent Reporting</li>
                                                      <li>Invoicing Integration</li>
                                                      <li>Financial Integrations</li>
                                                      <li>Student Information management</li>
                                                      <li>Automated communications</li>
                                                      <li>Learning Management System</li>
                                                      <li>Quick And Easy Course Scheduling</li>
                                                      <li>Reporting And Data Analysis</li>
                                                      <li>Assessment Management Live Feedback</li>
                                                      <li>Gradebooks</li>
                                                      <li>Quick User Integration</li>
                                                      <li>Easy Payment Methods</li>
                                                      <li>Online Communities Social Engagement</li>
                                                      <li>Curation of Resources And Adding Own Resources</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              <div className="tab-pane fade" id="pills-LogoDesign" role="tabpanel"
                                  aria-labelledby="pills-LogoDesign-tab" tabIndex="0">
                                  <div className="row justify-content-center">
                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>Basic Logo Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$35</h4>
                                                  <ul>
                                                      <li>3 Custom Logo Design Concepts</li>
                                                      <li>1 Dedicated Designer</li>
                                                      <li>4 Revisions</li>
                                                      <li>48 to 72 hours TAT</li>
                                                      <li>100% Unique Design Guarantee</li>
                                                      <li>100% Satisfaction Guarantee</li>
                                                      <li>100% Money Back Guarantee *</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>Startup Logo Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$75</h4>
                                                  <ul>
                                                      <li>5 Custom Logo Design Concepts</li>
                                                      <li>By 2 Designers</li>
                                                      <li>UNLIMITED Revisions</li>
                                                      <li>48 to 72 hours TAT</li>
                                                      <li>All Final Files Format (All/ PSD/ EPS/ PNG/ GIF/ JPG/ PDF)</li>
                                                      <li>100% Satisfaction Guarantee</li>
                                                      <li>100% Unique Design Guarantee</li>
                                                      <li>100% Money Back Guarantee *</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>Professional Logo Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$125</h4>
                                                  <ul>
                                                      <li>UNLIMITED Logo Design Concepts</li>
                                                      <li>By 4 Designers</li>
                                                      <li>UNLIMITED Revisions</li>
                                                      <li>Stationary Design (Business Card</li>
                                                      <li>Letterhead</li>
                                                      <li>Envelope)</li>
                                                      <li>FREE MS Word Letterhead</li>
                                                      <li>48 to 72 hours TAT</li>
                                                      <li>All Final Files Format (All/ PSD/ EPS/ PNG/ GIF/ JPG/ PDF)</li>
                                                      <li>100% Satisfaction Guarantee</li>
                                                      <li>100% Unique Design Guarantee</li>
                                                      <li>100% Money Back Guarantee *</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>Elite Logo Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$149</h4>
                                                  <ul>
                                                      <li>Website Mockups</li>
                                                      <li>UNLIMITED Logo Design Concepts</li>
                                                      <li>By 5 Award Winning Designers</li>
                                                      <li>Stationary Design (Business Card</li>
                                                      <li>Letterhead</li>
                                                      <li>Envelope)</li>
                                                      <li>Icon Design</li>
                                                      <li>UNLIMITED Revisions</li>
                                                      <li>FREE MS Word Letterhead</li>
                                                      <li>Free Email Signature</li>
                                                      <li>All Final Files Format (All/ PSD/ EPS/ PNG/ GIF/ JPG/ PDF)</li>
                                                      <li>100% Satisfaction Guarantee</li>
                                                      <li>100% Unique Design Guarantee</li>
                                                      <li>100% Money Back Guarantee *</li>
                                                      <li>48 to 72 hours TAT</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>Business Logo Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$199</h4>
                                                  <ul>
                                                      <li>Website Mockup</li>
                                                      <li>UNLIMITED Logo Design Concepts</li>
                                                      <li>By 8 Award Winning Designers</li>
                                                      <li>UNLIMITED Revisions</li>
                                                      <li>2 Stationary Design Sets (Business Card</li>
                                                      <li>Letterhead</li>
                                                      <li>Envelope)</li>
                                                      <li>Icon Design</li>
                                                      <li>Double Sided Flyer Design / Bi-Fold Brochure</li>
                                                      <li>FREE MS Word Letterhead</li>
                                                      <li>Free Email Signature</li>
                                                      <li>All Final Files Format (All / PSD / EPS / PNG / GIF / JPG / PDF)
                                                      </li>
                                                      <li>100% Ownership Rights</li>
                                                      <li>100% Satisfaction Guarantee</li>
                                                      <li>100% Unique Design Guarantee</li>
                                                      <li>100% Money Back Guarantee *</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>Gold Logo Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$425</h4>
                                                  <ul>
                                                      <li>UNLIMITED Logo Design Concepts</li>
                                                      <li>By 8 Award Winning Designers</li>
                                                      <li>Icon Design</li>
                                                      <li>UNLIMITED Revisions</li>
                                                      <li>2 Stationary Design Sets (Business Card</li>
                                                      <li>Letterhead</li>
                                                      <li>Envelope)</li>
                                                      <li>FREE MS Word Letterhead</li>
                                                      <li>Free Email Signature</li>
                                                      <li>3 Page Custom Website</li>
                                                      <li>2 Stock Photos</li>
                                                      <li>2 Banner Designs</li>
                                                      <li>jQuery Slider</li>
                                                      <li>All Final Files Format (All/ PSD/ EPS/ PNG/ GIF/ JPG/ PDF)</li>
                                                      <li>100% Ownership Rights</li>
                                                      <li>100% Satisfaction Guarantee</li>
                                                      <li>100% Unique Design Guarantee</li>
                                                      <li>100% Money Back Guarantee *</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              <div className="tab-pane fade" id="pills-Ecommerce" role="tabpanel"
                                  aria-labelledby="pills-Ecommerce-tab" tabIndex="0">
                                  <div className="row justify-content-center">
                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>E-Commerce Start Up Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$794</h4>
                                                  <ul>
                                                      <li>E-Commerce Website Design and Development</li>
                                                      <li>High-end Theme Based Design</li>
                                                      <li>5 Banner Designs</li>
                                                      <li>Sliding Banner</li>
                                                      <li>5 Stock Photos</li>
                                                      <li>12 Revisions</li>
                                                      <li>Hover Effects</li>
                                                      <li>Up to 25 Products</li>
                                                      <li>Up to 5 Categories</li>
                                                      <li>Content/Inventory Management System</li>
                                                      <li>Easy Product Search Bar</li>
                                                      <li>Shopping Cart Integration</li>
                                                      <li>Payment Module Integration</li>
                                                      <li>Direct Checkout</li>
                                                      <li>Sign up Checkout</li>
                                                      <li>Tax calculator Integration</li>
                                                      <li>Shipping calculator Integration</li>
                                                      <li>Wishlist</li>
                                                      <li>Search Engine Submission</li>
                                                      <li>Social Media Pages Integration</li>
                                                      <li>Facebook “Like” Plugin integration</li>
                                                      <li>Sitemap</li>
                                                      <li>Complete W3C Validation</li>
                                                      <li>Dedicated Team of Designers and Developers</li>
                                                      <li>Complete Deployment</li>
                                                      <li>Complete Source Files</li>
                                                      <li>Dedicated Project Manager</li>
                                                      <li>100% Ownership Rights</li>
                                                      <li>100% Satisfaction Guarantee</li>
                                                      <li>100% Money Back Guarantee</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>E-Commerce Professional Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$1499</h4>
                                                  <ul>
                                                      <li>E-Commerce Website Design and Development</li>
                                                      <li>Customized Theme Based Design</li>
                                                      <li>7 Banner Designs</li>
                                                      <li>Sliding Banner</li>
                                                      <li>7 Stock Photos</li>
                                                      <li>Unlimited Revisions</li>
                                                      <li>Hover Effects</li>
                                                      <li>Up to 100 Products</li>
                                                      <li>Up to 10 Categories</li>
                                                      <li>Content/Inventory Management System</li>
                                                      <li>Mobile Responsive</li>
                                                      <li>Easy Product Search Bar</li>
                                                      <li>Shopping Cart Integration</li>
                                                      <li>Payment Module Integration</li>
                                                      <li>Direct Checkout</li>
                                                      <li>Sign up Checkout</li>
                                                      <li>Tax calculator Integration</li>
                                                      <li>Shipping calculator Integration</li>
                                                      <li>Wishlist</li>
                                                      <li>Import/Export Order</li>
                                                      <li>Bulk Import/export of Order information</li>
                                                      <li>Search Engine Submission</li>
                                                      <li>SEO Friendly</li>
                                                      <li>Web Analytics Integration</li>
                                                      <li>Monthly Traffic Reporting</li>
                                                      <li>Social Media Pages Integration</li>
                                                      <li>Facebook “Like” Plugin integration</li>
                                                      <li>Sitemap</li>
                                                      <li>Complete W3C Validation</li>
                                                      <li>Dedicated Team of Designers and Developers</li>
                                                      <li>Complete Deployment</li>
                                                      <li>Complete Source Files</li>
                                                      <li>Dedicated Project Manager</li>
                                                      <li>100% Ownership Rights</li>
                                                      <li>100% Satisfaction Guarantee</li>
                                                      <li>100% Money Back Guarantee</li>
                                                      <li>*NO MONTHLY OR ANY HIDDEN FEE*</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>E-Commerce Elite Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$2999</h4>
                                                  <ul>
                                                      <li>E-Commerce Website Design and Development</li>
                                                      <li>Customized Tailor-Made Design</li>
                                                      <li>Professional</li>
                                                      <li>Modern</li>
                                                      <li>Unique Design</li>
                                                      <li>10 Banner Designs</li>
                                                      <li>Sliding Banner</li>
                                                      <li>10 Stock Photos</li>
                                                      <li>Unlimited Revisions</li>
                                                      <li>Special Hover Effects</li>
                                                      <li>Up to 500 Products</li>
                                                      <li>Up to 25 Categories</li>
                                                      <li>Product Reviews Ratings</li>
                                                      <li>Content/Inventory Management System</li>
                                                      <li>Mobile Responsive</li>
                                                      <li>User Signup Area</li>
                                                      <li>User Sign-in Area</li>
                                                      <li>User Dashboard Area</li>
                                                      <li>Auto Email for Profile Validation Notification(s)</li>
                                                      <li>Easy Product Search Bar</li>
                                                      <li>Shopping Cart Integration</li>
                                                      <li>Payment Module Integration</li>
                                                      <li>Direct Checkout</li>
                                                      <li>Sign up Checkout</li>
                                                      <li>Tax calculator Integration</li>
                                                      <li>Shipping calculator Integration</li>
                                                      <li>Wishlist</li>
                                                      <li>Import/Export Order</li>
                                                      <li>Bulk Import/export of Order information</li>
                                                      <li>Search Engine Submission</li>
                                                      <li>SEO Friendly</li>
                                                      <li>Web Analytics Integration</li>
                                                      <li>Monthly Traffic Reporting</li>
                                                      <li>Social Media Pages Integration</li>
                                                      <li>Facebook “Like” Plugin integration</li>
                                                      <li>Blog page (If Required)</li>
                                                      <li>Sitemap</li>
                                                      <li>Complete W3C Validation</li>
                                                      <li>Dedicated Team of Designers and Developers</li>
                                                      <li>Complete Deployment</li>
                                                      <li>Complete Source Files</li>
                                                      <li>Dedicated Project Manager</li>
                                                      <li>100% Ownership Rights</li>
                                                      <li>100% Satisfaction Guarantee</li>
                                                      <li>100% Money Back Guarantee</li>
                                                      <li>*NO MONTHLY OR ANY HIDDEN FEE*</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>E-Commerce Identity Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$3499</h4>
                                                  <ul>
                                                      <li>E-Commerce Website Design and Development</li>
                                                      <li>Customized Tailor-Made Design</li>
                                                      <li>Professional</li>
                                                      <li>Modern</li>
                                                      <li>Interactive</li>
                                                      <li>Dynamic</li>
                                                      <li>Unique Design</li>
                                                      <li>20 Banner Designs</li>
                                                      <li>Sliding Banner</li>
                                                      <li>20 Stock Photos</li>
                                                      <li>Unlimited Revisions</li>
                                                      <li>Special Hover Effects</li>
                                                      <li>Content/Inventory Management System</li>
                                                      <li>Mobile Responsive</li>
                                                      <li>Unlimited Products</li>
                                                      <li>Unlimited Categories</li>
                                                      <li>Product Reviews Ratings</li>
                                                      <li>Product Summary Reports (Out of stock / Most Sold / Lowest sale etc)
                                                          By Date</li>
                                                      <li>Multiple Filtrations Option</li>
                                                      <li>Multiple Shorting Options (Newest / Price / Alphabetical / Rating)
                                                      </li>
                                                      <li>Product View with Multiple Views</li>
                                                      <li>Product Rating</li>
                                                      <li>Product Detail with Similar Product Range</li>
                                                      <li>Coupon Code Generation (by category and product)</li>
                                                      <li>Product Comparison</li>
                                                      <li>User Signup Area</li>
                                                      <li>User Sign-in Area</li>
                                                      <li>User Dashboard Area</li>
                                                      <li>Auto Email for Profile Validation Notification(s)</li>
                                                      <li>Last Purchase Order Details (download invoice)</li>
                                                      <li>Customer Login / Registration via Social Login Facebook and Google+
                                                      </li>
                                                      <li>Customer profile management (From Backend and Frontend)</li>
                                                      <li>Discounted Products Showcase</li>
                                                      <li>New Arrival Products Showcase</li>
                                                      <li>Discount Coupons</li>
                                                      <li>Easy Product Search Bar</li>
                                                      <li>Shopping Cart Integration</li>
                                                      <li>Payment Module Integration</li>
                                                      <li>Multiple Currency Integration</li>
                                                      <li>Direct Checkout</li>
                                                      <li>Sign up Checkout</li>
                                                      <li>Tax calculator Integration</li>
                                                      <li>Shipping calculator Integration</li>
                                                      <li>Wishlist</li>
                                                      <li>Import/Export Order</li>
                                                      <li>Bulk Import/export of Order information</li>
                                                      <li>Bulk Product Upload From Excel Sheet</li>
                                                      <li>Export order into excel sheet</li>
                                                      <li>Live Chat Integration</li>
                                                      <li>Search Engine Submission</li>
                                                      <li>SEO Friendly</li>
                                                      <li>Web Analytics Integration</li>
                                                      <li>Monthly Traffic Reporting</li>
                                                      <li>Social Media Pages Integration</li>
                                                      <li>Facebook Shop Setup</li>
                                                      <li>Facebook “Like” Plugin integration</li>
                                                      <li>Blog page (If Required)</li>
                                                      <li>Sitemap</li>
                                                      <li>Complete W3C Validation</li>
                                                      <li>Dedicated Team of Designers and Developers</li>
                                                      <li>Complete Deployment</li>
                                                      <li>Complete Source Files</li>
                                                      <li>Dedicated Project Manager</li>
                                                      <li>100% Ownership Rights</li>
                                                      <li>100% Satisfaction Guarantee</li>
                                                      <li>100% Money Back Guarantee</li>
                                                      <li>*NO MONTHLY OR ANY HIDDEN FEE*</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>Automated/Interactive E-Commerce Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$7999</h4>
                                                  <ul>
                                                      <li>E-Commerce Website Design and Development</li>
                                                      <li>Customized Tailor-Made Design</li>
                                                      <li>Professional</li>
                                                      <li>Modern</li>
                                                      <li>Interactive</li>
                                                      <li>Dynamic</li>
                                                      <li>Unique Design</li>
                                                      <li>User-friendly Navigation</li>
                                                      <li>20 Banner Designs</li>
                                                      <li>Sliding Banner</li>
                                                      <li>20 Stock Photos</li>
                                                      <li>Unlimited Revisions</li>
                                                      <li>Special Hover Effects</li>
                                                      <li>Content/Inventory Management System</li>
                                                      <li>Mobile Responsive</li>
                                                      <li>Unlimited Products</li>
                                                      <li>Unlimited Categories</li>
                                                      <li>Product Reviews Ratings</li>
                                                      <li>Product Summary Reports (Out of stock / Most Sold / Lowest sale etc)
                                                          By Date</li>
                                                      <li>Multiple Filtrations Option</li>
                                                      <li>Multiple Shorting Options (Newest / Price / Alphabetical / Rating)
                                                      </li>
                                                      <li>Product View with Multiple Views</li>
                                                      <li>Product Rating</li>
                                                      <li>Product Detail with Similar Product Range</li>
                                                      <li>Coupon Code Generation (by category and product)</li>
                                                      <li>Product Comparison</li>
                                                      <li>User Signup Area</li>
                                                      <li>User Sign-in Area</li>
                                                      <li>User Dashboard Area</li>
                                                      <li>Auto Email for Profile Validation Notification(s)</li>
                                                      <li>Last Purchase Order Details (download invoice)</li>
                                                      <li>Customer Login / Registration via Social Login Facebook and Google+
                                                      </li>
                                                      <li>Customer profile management (From Backend and Frontend)</li>
                                                      <li>Discounted Products Showcase</li>
                                                      <li>New Arrival Products Showcase</li>
                                                      <li>Discount Coupons</li>
                                                      <li>Easy Product Search Bar</li>
                                                      <li>Shopping Cart Integration</li>
                                                      <li>Payment Module Integration</li>
                                                      <li>Multiple Currency Integration</li>
                                                      <li>Direct Checkout</li>
                                                      <li>Sign up Checkout</li>
                                                      <li>Tax calculator Integration</li>
                                                      <li>Shipping calculator Integration</li>
                                                      <li>Wishlist</li>
                                                      <li>Import/Export Order</li>
                                                      <li>Bulk Import/export of Order information</li>
                                                      <li>Bulk Product Upload From Excel Sheet</li>
                                                      <li>Export order into excel sheet</li>
                                                      <li>Live Chat Integration</li>
                                                      <li>Search Engine Submission</li>
                                                      <li>SEO Friendly</li>
                                                      <li>Web Analytics Integration</li>
                                                      <li>Monthly Traffic Reporting</li>
                                                      <li>Social Media Pages Integration</li>
                                                      <li>Facebook Shop Setup</li>
                                                      <li>Facebook “Like” Plugin integration</li>
                                                      <li>Blog page (If Required)</li>
                                                      <li>Sitemap</li>
                                                      <li>Complete W3C Validation</li>
                                                      <li>Dedicated Team of Designers and Developers</li>
                                                      <li>Complete Deployment</li>
                                                      <li>Complete Source Files</li>
                                                      <li>Dedicated Project Manager</li>
                                                      <li>100% Ownership Rights</li>
                                                      <li>100% Satisfaction Guarantee</li>
                                                      <li>100% Money Back Guarantee</li>
                                                      <li>*NO MONTHLY OR ANY HIDDEN FEE*</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>E-Commerce Elite Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$15000</h4>
                                                  <ul>
                                                      <li>E-Commerce Website Design and Development</li>
                                                      <li>Customized Tailor-Made Design</li>
                                                      <li>Professional</li>
                                                      <li>Modern</li>
                                                      <li>Unique Design</li>
                                                      <li>10 Banner Designs</li>
                                                      <li>Sliding Banner</li>
                                                      <li>10 Stock Photos</li>
                                                      <li>Unlimited Revisions</li>
                                                      <li>Special Hover Effects</li>
                                                      <li>Up to 500 Products</li>
                                                      <li>Up to 25 Categories</li>
                                                      <li>Product Reviews Ratings</li>
                                                      <li>Content/Inventory Management System</li>
                                                      <li>Mobile Responsive</li>
                                                      <li>User Signup Area</li>
                                                      <li>User Sign-in Area</li>
                                                      <li>User Dashboard Area</li>
                                                      <li>Auto Email for Profile Validation Notification(s)</li>
                                                      <li>Easy Product Search Bar</li>
                                                      <li>Shopping Cart Integration</li>
                                                      <li>Payment Module Integration</li>
                                                      <li>Direct Checkout</li>
                                                      <li>Sign up Checkout</li>
                                                      <li>Tax calculator Integration</li>
                                                      <li>Shipping calculator Integration</li>
                                                      <li>Wishlist</li>
                                                      <li>Import/Export Order</li>
                                                      <li>Bulk Import/export of Order information</li>
                                                      <li>Search Engine Submission</li>
                                                      <li>SEO Friendly</li>
                                                      <li>Web Analytics Integration</li>
                                                      <li>Monthly Traffic Reporting</li>
                                                      <li>Social Media Pages Integration</li>
                                                      <li>Facebook “Like” Plugin integration</li>
                                                      <li>Blog page (If Required)</li>
                                                      <li>Sitemap</li>
                                                      <li>Complete W3C Validation</li>
                                                      <li>Dedicated Team of Designers and Developers</li>
                                                      <li>Complete Deployment</li>
                                                      <li>Complete Source Files</li>
                                                      <li>Dedicated Project Manager</li>
                                                      <li>100% Ownership Rights</li>
                                                      <li>100% Satisfaction Guarantee</li>
                                                      <li>100% Money Back Guarantee</li>
                                                      <li>*NO MONTHLY OR ANY HIDDEN FEE*</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              <div className="tab-pane fade" id="pills-SeoServices" role="tabpanel"
                                  aria-labelledby="pills-SeoServices-tab" tabIndex="0">
                                  <div className="row justify-content-center">
                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>Startup SEO Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$445</h4>
                                                  <ul>
                                                      <li>5 Keywords</li>
                                                      <li>Guaranteed Ranking on Google</li>
                                                      <li>Off-site Optimization</li>
                                                      <li>Link Building</li>
                                                      <li>Social Bookmarking</li>
                                                      <li>Basic Analytical Report</li>
                                                      <li>In-depth Site Analysis</li>
                                                      <li>Content Duplicity Check</li>
                                                      <li>Initial Backlinks analysis</li>
                                                      <li>Google Penalty Check</li>
                                                      <li>Mobile Usability Check</li>
                                                      <li>Competition Analysis</li>
                                                      <li>Keyword Research</li>
                                                      <li>nformational Content Writing Sharing (2 - Per Month)</li>
                                                      <li>Blog Writing (1 - Per Month)</li>
                                                      <li>Press Release Social Bookmarking (5 - Per Month)</li>
                                                      <li>NAP Syndication</li>
                                                      <li>Google My Business / Bing Local Listing</li>
                                                      <li>Citation Building</li>
                                                      <li>classNameified Submissions</li>
                                                      <li>Google Analytics Analysis Report</li>
                                                      <li>SEO Reports</li>
                                                      <li>Search Engine Rank Report</li>
                                                      <li>Dedicated Accounts Manager</li>
                                                      <li>Monthly Action Plan</li>
                                                      <li>Activity Report</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>Identity SEO Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$845</h4>
                                                  <ul>
                                                      <li>2 Stationery Design Set</li>
                                                      <li>10 Keywords</li>
                                                      <li>Guaranteed Ranking on Google</li>
                                                      <li>Off-site Optimization</li>
                                                      <li>On-site Optimization</li>
                                                      <li>Link Building</li>
                                                      <li>Social Bookmarking</li>
                                                      <li>In-depth Site Analysis</li>
                                                      <li>Content Duplicacy Check</li>
                                                      <li>Initial Backlinks analysis</li>
                                                      <li>Google Penalty Check</li>
                                                      <li>Mobile Usability Check</li>
                                                      <li>Competition Analysis</li>
                                                      <li>Keyword Research</li>
                                                      <li>Title Meta Tags Optimization</li>
                                                      <li>Content Optimization</li>
                                                      <li>Page Speed Analysis Optimization</li>
                                                      <li>HTML Code Cleanup Optimization</li>
                                                      <li>Internal Link Structuring Optimization</li>
                                                      <li>Pages H tags Optimization</li>
                                                      <li>Canonicalization/301 Redirect</li>
                                                      <li>Website Page Load Optimization</li>
                                                      <li>Schema Markup Implementation</li>
                                                      <li>Image Hyperlink Optimization</li>
                                                      <li>Robots.txt Creation/Analysis</li>
                                                      <li>Blog Writing (2 - Per Month)</li>
                                                      <li>Informational Content Writing Sharing (1 Per Month)</li>
                                                      <li>Press Release Writing Distribution</li>
                                                      <li>Press Release Social Bookmarking</li>
                                                      <li>Google Webmaster Tools Setup</li>
                                                      <li>Google Analytics Setup Integration</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>Elite SEO Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$1545</h4>
                                                  <ul>
                                                      <li>20 Keywords</li>
                                                      <li>Guaranteed Ranking on Google</li>
                                                      <li>Off-site Optimization</li>
                                                      <li>On-site Optimization</li>
                                                      <li>Link Building</li>
                                                      <li>Social Bookmarking</li>
                                                      <li>In-depth Site Analysis</li>
                                                      <li>Content Duplicacy Check</li>
                                                      <li>Initial Backlinks analysis</li>
                                                      <li>Google Penalty Check</li>
                                                      <li>Mobile Usability Check</li>
                                                      <li>Competition Analysis</li>
                                                      <li>Keyword Research</li>
                                                      <li>Page Speed Analysis Optimization</li>
                                                      <li>Title Meta Tags Optimization</li>
                                                      <li>Content Optimization</li>
                                                      <li>HTML Code Cleanup Optimization</li>
                                                      <li>Internal Link Structuring Optimization</li>
                                                      <li>Pages H tags Optimization</li>
                                                      <li>Canonicalization/301 Redirect</li>
                                                      <li>Website Page Load Optimization</li>
                                                      <li>Robots.txt Creation/Analysis</li>
                                                      <li>Press Release Writing Distribution</li>
                                                      <li>Press Release Social Bookmarking</li>
                                                      <li>Schema Markup Implementation</li>
                                                      <li>Image Hyperlink Optimization</li>
                                                      <li>Google Webmaster Tools Setup</li>
                                                      <li>Google Analytics Setup Integration</li>
                                                      <li>Blog Writing (2 - Per Month)</li>
                                                      <li>Informational Content Writing Sharing (1 Per Month)</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>Professional SEO Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$2145</h4>
                                                  <ul>
                                                      <li>30 Keywords</li>
                                                      <li>Guaranteed Ranking on Google</li>
                                                      <li>Off-site Optimization</li>
                                                      <li>On-site Optimization</li>
                                                      <li>Link Building</li>
                                                      <li>Social Bookmarking</li>
                                                      <li>In-depth Site Analysis</li>
                                                      <li>Content Duplicacy Check</li>
                                                      <li>Initial Backlinks analysis</li>
                                                      <li>Google Penalty Check</li>
                                                      <li>Mobile Usability Check</li>
                                                      <li>Competition Analysis</li>
                                                      <li>Keyword Research</li>
                                                      <li>Page Speed Analysis Optimization</li>
                                                      <li>Title Meta Tags Optimization</li>
                                                      <li>Content Optimization</li>
                                                      <li>HTML Code Cleanup Optimization</li>
                                                      <li>Internal Link Structuring Optimization</li>
                                                      <li>Pages H tags Optimization</li>
                                                      <li>Canonicalization/301 Redirect</li>
                                                      <li>Website Page Load Optimization</li>
                                                      <li>Robots.txt Creation/Analysis</li>
                                                      <li>Press Release Writing Distribution</li>
                                                      <li>Press Release Social Bookmarking</li>
                                                      <li>Schema Markup Implementation</li>
                                                      <li>Image Hyperlink Optimization</li>
                                                      <li>Google Webmaster Tools Setup</li>
                                                      <li>Google Analytics Setup Integration</li>
                                                      <li>Blog Writing (2 - Per Month)</li>
                                                      <li>Informational Content Writing Sharing (1 Per Month)</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              <div className="tab-pane fade" id="pills-CreativeWriting" role="tabpanel"
                                  aria-labelledby="pills-CreativeWriting-tab" tabIndex="0">
                                  <div className="row justify-content-center">
                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>Web Content Basic Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$129</h4>
                                                  <ul>
                                                      <li>1 Page Professional Copywriting Service</li>
                                                      <li>Creative Well-Written by 1 Professional Copywriter</li>
                                                      <li>Industry Specified Expert Copywriter</li>
                                                      <li>300 Words Per Page</li>
                                                      <li>5 Revisions</li>
                                                      <li>3 to 4 Business Days Rotation</li>
                                                      <li>According To Your Exact Requirements</li>
                                                      <li>Proofing by our in-house experts</li>
                                                      <li>100% Ownership Rights</li>
                                                      <li>100% Original Content</li>
                                                      <li>100% Approval Assurance</li>
                                                      <li>30 Days Refund Warranty</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>Web Content Start Up Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$499</h4>
                                                  <ul>
                                                      <li>5 Pages Professional Copywriting Service</li>
                                                      <li>Creative Well-Written by 2 Professional Copywriters</li>
                                                      <li>Industry Specified Expert Copywriters</li>
                                                      <li>300 Words Per Page</li>
                                                      <li>10 Revisions</li>
                                                      <li>5 to 7 Business Days Rotation</li>
                                                      <li>According To Your Exact Requirements</li>
                                                      <li>Proofing by our in-house experts</li>
                                                      <li>FREE Meta details – With each custom page</li>
                                                      <li>we will provide a catchy title</li>
                                                      <li>keywords and page description.</li>
                                                      <li>SEO friendly – Your keyword(s) will be placed in the title</li>
                                                      <li>the first last paragraphs and throughout the web copy in a natural
                                                          and fluent manner</li>
                                                      <li>100% Ownership Rights</li>
                                                      <li>100% Original Content</li>
                                                      <li>100% Approval Assurance</li>
                                                      <li>30 Days Refund Warranty</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>Web Content Professional Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$849</h4>
                                                  <ul>
                                                      <li>10 Pages Professional Copywriting Service</li>
                                                      <li>Creative Well-Written by 2 Professional Copywriters</li>
                                                      <li>Industry Specified Expert Copywriters</li>
                                                      <li>300 Words Per Page</li>
                                                      <li>Unlimited Revisions</li>
                                                      <li>7 to 10 Business Days Rotation</li>
                                                      <li>According To Your Exact Requirements</li>
                                                      <li>Proofing by our in-house experts</li>
                                                      <li>FREE Meta details – With each custom page</li>
                                                      <li>we will provide a catchy title</li>
                                                      <li>keywords and page description.</li>
                                                      <li>SEO friendly – Your keyword(s) will be placed in the title</li>
                                                      <li>the first last paragraphs and throughout the web copy in a natural
                                                          and fluent manner</li>
                                                      <li>100% Ownership Rights</li>
                                                      <li>100% Original Content</li>
                                                      <li>100% Approval Assurance</li>
                                                      <li>30 Days Refund Warranty</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>Web Content Elite Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$1849</h4>
                                                  <ul>
                                                      <li>Up to 20 Pages Professional Copywriting Service</li>
                                                      <li>Creative Well-Written by 3 Professional Copywriters</li>
                                                      <li>Industry Specified Expert Copywriters</li>
                                                      <li>300 Words Per Page</li>
                                                      <li>Unlimited Revisions</li>
                                                      <li>7 to 10 Business Days Rotation</li>
                                                      <li>According To Your Exact Requirements</li>
                                                      <li>Proofing by our in-house experts</li>
                                                      <li>FREE Meta details – With each custom page</li>
                                                      <li>we will provide a catchy title</li>
                                                      <li>keywords and page description.</li>
                                                      <li>SEO friendly – Your keyword(s) will be placed in the title</li>
                                                      <li>the first last paragraphs and throughout the web copy in a natural
                                                          and fluent manner</li>
                                                      <li>100% Ownership Rights</li>
                                                      <li>100% Original Content</li>
                                                      <li>100% Approval Assurance</li>
                                                      <li>30 Days Refund Warranty</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>Web Content Identity Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$149</h4>
                                                  <ul>
                                                      <li>1 Creative</li>
                                                      <li>Fresh Well-Written Article</li>
                                                      <li>By 1 Professional Copywriter</li>
                                                      <li>Industry Specified Expert Copywriter</li>
                                                      <li>400 Words Per Page</li>
                                                      <li>3 to 5 Business Days Rotation</li>
                                                      <li>5 Revisions</li>
                                                      <li>According To Your Exact Requirements</li>
                                                      <li>Written on Your Specified Topic/Keyword</li>
                                                      <li>Proofing by our in-house experts</li>
                                                      <li>Free Submission on any article marketing directories /blog (If
                                                          required)</li>
                                                      <li>SEO friendly – Your keyword(s) will be placed in the title</li>
                                                      <li>the first last paragraphs and throughout the web copy in a natural
                                                          and fluent manner</li>
                                                      <li>100% Ownership Rights</li>
                                                      <li>100% Original Content</li>
                                                      <li>100% Approval Assurance</li>
                                                      <li>30 Days Refund Warranty</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>Web Content Corporate Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$129</h4>
                                                  <ul>
                                                      <li>1 Creative</li>
                                                      <li>Fresh Well-Written Article</li>
                                                      <li>By 1 Professional Copywriter</li>
                                                      <li>Industry Specified Expert Copywriter</li>
                                                      <li>400 Words Per Page</li>
                                                      <li>3 to 5 Business Days Rotation</li>
                                                      <li>5 Revisions</li>
                                                      <li>According To Your Exact Requirements</li>
                                                      <li>Written on Your Specified Topic/Keyword</li>
                                                      <li>Proofing by our in-house experts</li>
                                                      <li>Free Submission on any article marketing directories /blog (If
                                                          required)</li>
                                                      <li>SEO friendly – Your keyword(s) will be placed in the title</li>
                                                      <li>the first last paragraphs and throughout the web copy in a natural
                                                          and fluent manner</li>
                                                      <li>100% Ownership Rights</li>
                                                      <li>100% Original Content</li>
                                                      <li>100% Approval Assurance</li>
                                                      <li>30 Days Refund Warranty</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              <div className="tab-pane fade" id="pills-VideoAnimation" role="tabpanel"
                                  aria-labelledby="pills-VideoAnimation-tab" tabIndex="0">
                                  <div className="row justify-content-center">
                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>Teaser/Intro Video Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$145</h4>
                                                  <ul>
                                                      <li>10 Seconds Video</li>
                                                      <li>Professional Script</li>
                                                      <li>Sample Theme</li>
                                                      <li>Storyboard</li>
                                                      <li>Custom Artwork</li>
                                                      <li>Animation</li>
                                                      <li>Professional Voice - Over Sound Effects</li>
                                                      <li>1 week Delivery</li>
                                                      <li>Unlimited Revisions</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>Startup Video Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$195</h4>
                                                  <ul>
                                                      <li>30s Duration - HD 1080</li>
                                                      <li>Professional Script</li>
                                                      <li>Storyboard</li>
                                                      <li>Sample Themes</li>
                                                      <li>Custom Setting</li>
                                                      <li>Characters Graphics</li>
                                                      <li>Animation Effects Visualization</li>
                                                      <li>Voice - Over Sound Effects (All accents) (M/F)</li>
                                                      <li>Unlimited Revisions</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>classNameic Video Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$395</h4>
                                                  <ul>
                                                      <li>60 Second Video - HD 1080</li>
                                                      <li>Professional Script</li>
                                                      <li>Sample Theme</li>
                                                      <li>Storyboard</li>
                                                      <li>Animation</li>
                                                      <li>Voice - Over Sound Effects</li>
                                                      <li>5 weeks Delivery</li>
                                                      <li>Unlimited Revisions</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>Premium Video Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$695</h4>
                                                  <ul>
                                                      <li>90 Second Video - HD 1080</li>
                                                      <li>Professional Script</li>
                                                      <li>Sample Theme</li>
                                                      <li>Storyboard</li>
                                                      <li>Animation</li>
                                                      <li>Voice - Over Sound Effects</li>
                                                      <li>6 Weeks Delivery</li>
                                                      <li>Unlimited Revisions</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>Deluxe Video Package Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$895</h4>
                                                  <ul>
                                                      <li>120 Second Video - HD 1080</li>
                                                      <li>Professional Script</li>
                                                      <li>Sample Theme</li>
                                                      <li>Storyboard</li>
                                                      <li>Animation</li>
                                                      <li>Voice - Over Sound Effects</li>
                                                      <li>Unlimited Revisions</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              <div className="tab-pane fade" id="pills-DigitalMarketing" role="tabpanel"
                                  aria-labelledby="pills-DigitalMarketing-tab" tabIndex="0">
                                  <div className="row justify-content-center">
                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>Startup Social Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$99</h4>
                                                  <ul>
                                                      <li>Cover Photo Design</li>
                                                      <li>Profile Picture Design</li>
                                                      <li>Welcome Page Design</li>
                                                      <li>Unlimited Revisions</li>
                                                      <li>Dedicated Project Manager</li>
                                                      <li>100% Ownership Rights</li>
                                                      <li>100% Satisfaction Guarantee</li>
                                                      <li>100% Money Back Guarantee</li>
                                                      <li>*NO MONTHLY OR ANY HIDDEN FEE*</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>classNameic Social Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$149</h4>
                                                  <ul>
                                                      <li>Cover Photo Design</li>
                                                      <li>Profile Picture Design</li>
                                                      <li>Welcome Page Design</li>
                                                      <li>Twitter Page Design</li>
                                                      <li>Unlimited Revisions</li>
                                                      <li>100% Money Back Guarantee *</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>Premium Social Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$199</h4>
                                                  <ul>
                                                      <li>Cover Photo Design</li>
                                                      <li>Profile Picture Design</li>
                                                      <li>Welcome Page Design</li>
                                                      <li>Twitter Page Design</li>
                                                      <li>YouTube Page Design</li>
                                                      <li>Unlimited Revisions</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                                          <div className="pricing-card">
                                              <div className="main-box">
                                                  <img src={pricing_bg} alt="img not found" className="img-fluid"/>
                                              </div>
                                              <div className="title">
                                                  <h2>Unlimited Social Package</h2>
                                              </div>
                                              <div className="inner-box">
                                                  <h5>STARTING AT</h5>
                                                  <h4>$999</h4>
                                                  <ul>
                                                      <li>2 Cover Photo Designs</li>
                                                      <li>Profile Picture Design</li>
                                                      <li>Welcome Page Design</li>
                                                      <li>Twitter Page Design</li>
                                                      <li>YouTube Page Design</li>
                                                      <li>Google+ Page Design</li>
                                                  </ul>

                                                  <div className="delivery">
                                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                                  </div>
                                                  <div className="pricing-btns">
                                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>




    </>
  )
}

export default pricing
