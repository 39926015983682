import React from 'react'
import trust from '../assets/images/trust.webp';
import google from '../assets/images/google.webp';
import bings from '../assets/images/bings-logo.webp';


const home_banner = () => {
    const setButtonURL =()=> {
        // $zopim.livechat.window.show();
    }
  return (
    <>
          <section className="banner-wrap">
              <div className="container-fluid">
                  <div className="row align-items-center">
                      <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                          <div className="banner-content">
                              <h1>Trusted <span>Web Design Agency</span> for Your Business</h1>
                              <p>Our goal is to create exceptional websites for our clients that yield significant returns on investment. Our web design solutions assist businesses with constructing e-commerce and lead-generation websites.</p>
                              <ul className="banner-list">
                                  <li><i className="fas fa-chevron-circle-right"></i>
                                      <p>Attractive web designs</p>
                                  </li>
                                  <li><i className="fas fa-chevron-circle-right"></i>
                                      <p>Competitive Pricing</p>
                                  </li>
                                  <li><i className="fas fa-chevron-circle-right"></i>
                                      <p>Certified web developers</p>
                                  </li>
                              </ul>
                              <div className="banner-btns">
                                  <button className="popup-btn" data-bs-toggle="modal" data-bs-target="#exampleModal">Get a Quote</button>
                                  <a className="chat-btn" href="javascript:$zopim.livechat.window.show();">Chat With Us</a>
                              </div>
                              <div className="banner-brands">
                                  <img src={trust} alt="brand" className="img-fluid"/>
                                  <img src={google} alt="brand" className="img-fluid"/>
                                  <img src={bings} alt="brand" className="img-fluid"/>
                                          </div>
                                      </div>
                    </div>
                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                        <div className="banner-form">
                            <span>Avail 50% OFF</span>
                            <h3>Let’s Get Started Exclusive Offer</h3>
                               <form action="https://leads.infinityprojectmanager.com/brand/codexdesignagency/lead" method="GET">
                                    <input type="hidden" name="brand_name" defaultValue="codexdesignagency.com"/>
                                    <input type="hidden" id="lead_area" name="lead_area" defaultValue="https://codexdesignagency.com/"/>
                                        <div className="form-group">
                                            <input type="text" name="name" placeholder="Full Name" required/>
                                        </div>
                                        <div className="form-group">
                                            <input type="email" name="email" placeholder="Email Address" required/>
                                        </div>
                                        <div className="form-group">
                                            <input type="phone" name="phone" placeholder="Phone" required/>
                                        </div>
                                        <div className="form-group">
                                            <textarea name="message" placeholder="Description"></textarea>
                                        </div>
                                        <div className="form-group">
                                            <button type="submit-btn" className="submit-btn">Get a Quote</button>
                                        </div>
                                    </form>
                                </div>
                        </div>
                    </div>
                </div>
            </section>
      
    </>
  )
}

export default home_banner
