import React from 'react'
import Header from '../includes/header';
import Footer from '../includes/footer';
import brand_img from '../assets/images/brand-img.png';
import inner_banner_img from '../assets/images/inner-banner-img.png';
import icon_1 from '../assets/images/icon-1.png';
import icon_2 from '../assets/images/icon-2.png';
import icon_3 from '../assets/images/icon-3.png';
import icon_4 from '../assets/images/icon-4.png';

import icon_5 from '../assets/images/icon-5.png';
import icon_6 from '../assets/images/icon-6.png';
import icon_7 from '../assets/images/icon-7.png';
import icon_8 from '../assets/images/icon-8.png';

import icon_9 from '../assets/images/icon-9.png';
import icon_10 from '../assets/images/icon-10.png';
import icon_11 from '../assets/images/icon-11.png';
import icon_12 from '../assets/images/icon-12.png';

import icon_13 from '../assets/images/icon-13.png';
import icon_14 from '../assets/images/icon-14.png';
import icon_15 from '../assets/images/icon-15.png';
import icon_16 from '../assets/images/icon-16.png';
import icon_17 from '../assets/images/icon-17.png';
import icon_18 from '../assets/images/icon-18.png';
import pricing_bg from '../assets/images/pricing-bg.png';


import profund_bg from '../assets/images/profund-bg.png';
import useDocumentTitle from '../useDocumentTitle.jsx'

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const CreativeWritting = () => {
  useDocumentTitle('Content-Writting')

  return (
    <>
    <Header />

      <section className="inner-banner-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
              <div className="banner-content">
                <h1>Clean & Crisp Website Copywriting Services</h1>
                <p>Whether it's producing impactful website copy, crafting SEO-friendly product descriptions to
                  improve your search rankings, designing concise yet powerful email campaigns, or creating
                  informative blog posts to enhance your visibility and reputation as an authority, we can
                  provide a range of effective copywriting services.</p>
                <div className="brand-rating">
                  <div className="rating">
                    <h6>5.0 </h6>
                    <ul>
                      <li><i className="fa-solid fa-star"></i></li>
                      <li><i className="fa-solid fa-star"></i></li>
                      <li><i className="fa-solid fa-star"></i></li>
                      <li><i className="fa-solid fa-star"></i></li>
                      <li><i className="fa-solid fa-star"></i></li>
                    </ul>
                    <h6> rating on</h6>
                  </div>
                  <div className="brand-img">
                    <LazyLoadImage src={brand_img} alt="img not found" className="img-fluid" effect="blur"/>
                  </div>
                </div>
                <div className="banner-btn">
                  <a href="javascript:$zopim.livechat.window.show();" className="btn btn-contact">Contact Us today</a>
                  <a href="javascript:$zopim.livechat.window.show();" className="btn btn-chat"><i className="fa-solid fa-messages"></i></a>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
              <div className="main-img">
                <LazyLoadImage src={inner_banner_img} className="img-fluid" effect="blur" alt="img not found"/>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-sec-wrap">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">
              <div className="content">
                <h2>About Us</h2>
                <p>Welcome to Codex Design Agency, where we specialize in crafting high-quality web copywriting
                  services for businesses and individuals alike. Our team of content marketing professionals
                  possesses the ability to not only write skillfully but also create content that caters to
                  both readers and search engine algorithms. At Codex Design Agency, we believe that great
                  copywriting services for websites are more than just putting words on a page. It's about
                  creating a meaningful connection between your brand and your target audience. Whether you
                  need website copy, blog posts, product descriptions, or email campaigns, our talented
                  writers will work with you to create content that is tailored to your specific needs and
                  goals.</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-xl-between justify-content-center">
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
              <div className="about-card">
                <div className="main-img">
                  <LazyLoadImage src={icon_1} alt="img not found" className="img-fluid" effect="blur"/>
                </div>
                <div className="desc">
                  <h4>275+</h4>
                  <p>Projects Completed.</p>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
              <div className="about-card">
                <div className="main-img">
                  <LazyLoadImage src={icon_2} alt="img not found" className="img-fluid" effect="blur" />
                </div>
                <div className="desc">
                  <h4>89%</h4>
                  <p>Customer Retention</p>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
              <div className="about-card">
                <div className="main-img">
                  <LazyLoadImage src={icon_3} alt="img not found" className="img-fluid" effect="blur" />
                </div>
                <div className="desc">
                  <h4>120+</h4>
                  <p>Professionals</p>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
              <div className="about-card">
                <div className="main-img">
                  <LazyLoadImage src={icon_4} alt="img not found" className="img-fluid" effect="blur" />
                </div>
                <div className="desc">
                  <h4>20+</h4>
                  <p>Years of Experience</p>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
              <div className="about-card">
                <h5>Schedule A Call
                  <br/>
                    Now!
                </h5>
                <div className="desc">
                  <a href="javascript:$zopim.livechat.window.show();" className="btn btn-call"><i className="fa-solid fa-phone-volume"></i> Click
                    Here!</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="Premium-ppc-sec">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
              <div className="content">
                <h3>Custom Web Copywriting Services</h3>
                <p>Here are our complete copywriting services that you can choose from:</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
              <div className="ppc-card">
                <div className="main-img">
                  <LazyLoadImage src={icon_5} alt="img not found" className="img-fluid" effect="blur" />
                </div>
                <div className="desc">
                  <h4>Brand copywriting</h4>
                  <p>We create compelling, unique, and consistent brand copywriting services, including
                    creating brand stories, taglines, company profiles, and brand guidelines.</p>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
              <div className="ppc-card">
                <div className="main-img">
                  <LazyLoadImage src={icon_6} alt="img not found" className="img-fluid" effect="blur" />
                </div>
                <div className="desc">
                  <h4>Marketing Copywriting</h4>
                  <p>We develop persuasive copy for various marketing materials such as brochures, flyers,
                    social media posts, PPC ads, landing pages, and other promotional campaigns.</p>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
              <div className="ppc-card">
                <div className="main-img">
                  <LazyLoadImage src={icon_7} alt="img not found" className="img-fluid" effect="blur" />
                </div>
                <div className="desc">
                  <h4>Technical Copywriting</h4>
                  <p>Our technical copywriting services include creating user manuals, white papers, FAQs,
                    technical product descriptions, and other technical documentation.</p>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
              <div className="ppc-card">
                <div className="main-img">
                  <LazyLoadImage src={icon_8} alt="img not found" className="img-fluid" effect="blur" />
                </div>
                <div className="desc">
                  <h4>Blogs</h4>
                  <p>We provide a range of blog writing services, from developing a content strategy to
                    writing SEO-optimized, informative, and engaging blog posts that help establish your
                    brand in the industry.</p>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
              <div className="ppc-card">
                <div className="main-img">
                  <LazyLoadImage src={icon_9} alt="img not found" className="img-fluid" effect="blur" />
                </div>
                <div className="desc">
                  <h4>Website Content</h4>
                  <p>Our website content is optimized for search engines and designed to engage your audience.
                    This includes developing website copy, landing pages, product pages, and other website
                    content.</p>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
              <div className="ppc-card">
                <div className="main-img">
                  <LazyLoadImage src={icon_10} alt="img not found" className="img-fluid" effect="blur" />
                </div>
                <div className="desc">
                  <h4>Press Releases</h4>
                  <p>We help you announce new products, services, events, or other company news with
                    compelling press releases that get noticed by the media and your target audience.</p>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
              <div className="ppc-card">
                <div className="main-img">
                  <LazyLoadImage src={icon_11} alt="img not found" className="img-fluid" effect="blur" />
                </div>
                <div className="desc">
                  <h4>mails & Newsletters</h4>
                  <p>Our compelling email campaigns and newsletters are designed to build relationships,
                    increase engagement, and drive conversions.</p>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
              <div className="ppc-card">
                <div className="main-img">
                  <LazyLoadImage src={icon_12} alt="img not found" className="img-fluid" effect="blur" />
                </div>
                <div className="desc">
                  <h4>Product Descriptions</h4>
                  <p>We can help you sell your products by developing product descriptions that highlight the
                    features, benefits, and unique selling points of your products..</p>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
              <div className="ppc-card">
                <div className="main-img">
                  <LazyLoadImage src={icon_13} alt="img not found" className="img-fluid" effect="blur" />
                </div>
                <div className="desc">
                  <h4>Employee Manuals</h4>
                  <p>We help companies create employee manuals that are clear, concise, and easy to
                    understand, covering policies, procedures, and expectations for employees. </p>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
              <div className="ppc-card">
                <div className="main-img">
                  <LazyLoadImage src={icon_14} alt="img not found" className="img-fluid" effect="blur" />
                </div>
                <div className="desc">
                  <h4>B2B Sales Collateral</h4>
                  <p>We develop persuasive sales collateral that helps you communicate your value proposition
                    and close more deals, including sales presentations, case studies, white papers, and
                    more. .</p>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
              <div className="ppc-card">
                <div className="main-img">
                  <LazyLoadImage src={icon_15} alt="img not found" className="img-fluid" effect="blur" />
                </div>
                <div className="desc">
                  <h4>Ad Copy/Sales Pages</h4>
                  <p>Our ad copy and sales pages are designed to convert, using persuasive language and
                    effective calls to action.</p>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
              <div className="ppc-card">
                <div className="main-img">
                  <LazyLoadImage src={icon_16} alt="img not found" className="img-fluid" effect="blur" />
                </div>
                <div className="desc">
                  <h4>Promotional Videos</h4>
                  <p>We develop compelling promotional videos that tell your brand story, showcase your
                    products or services, and engage your audience.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pricing-sec-wrap">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">
              <div className="content">
                <h2>Pricing Plans</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div className="pricing-top-btn">
                <a href="javascript:$zopim.livechat.window.show();" className="btn btn-top-pricing">Creative Writing</a>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
              <div className="pricing-card">
                <div className="main-box">
                  <LazyLoadImage src={pricing_bg} alt="img not found" className="img-fluid" effect="blur"/>
                </div>
                <div className="title">
                  <h2>Web Content Basic Package</h2>
                </div>
                <div className="inner-box">
                  <h5>STARTING AT</h5>
                  <h4>$129</h4>
                  <ul>
                    <li>1 Page Professional Copywriting Service</li>
                    <li>Creative & Well-Written by 1 Professional Copywriter</li>
                    <li>Industry Specified Expert Copywriter</li>
                    <li>300 Words Per Page</li>
                    <li>5 Revisions</li>
                    <li>3 to 4 Business Days Rotation</li>
                    <li>According To Your Exact Requirements</li>
                    <li>Proofing by our in-house experts</li>
                    <li>100% Ownership Rights</li>
                    <li>100% Original Content</li>
                    <li>100% Approval Assurance</li>
                    <li>30 Days Refund Warranty</li>
                  </ul>

                  <div className="delivery">
                    <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                  </div>
                  <div className="pricing-btns">
                    {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                    <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
              <div className="pricing-card">
                <div className="main-box">
                  <LazyLoadImage src={pricing_bg} alt="img not found" className="img-fluid" effect="blur"/>
                </div>
                <div className="title">
                  <h2>Web Content Start Up Package</h2>
                </div>
                <div className="inner-box">
                  <h5>STARTING AT</h5>
                  <h4>$499</h4>
                  <ul>
                    <li>5 Pages Professional Copywriting Service</li>
                    <li>Creative & Well-Written by 2 Professional Copywriters</li>
                    <li>Industry Specified Expert Copywriters</li>
                    <li>300 Words Per Page</li>
                    <li>10 Revisions</li>
                    <li>5 to 7 Business Days Rotation</li>
                    <li>According To Your Exact Requirements</li>
                    <li>Proofing by our in-house experts</li>
                    <li>FREE Meta details – With each custom page</li>
                    <li>we will provide a catchy title</li>
                    <li>keywords and page description.</li>
                    <li>SEO friendly – Your keyword(s) will be placed in the title</li>
                    <li>the first & last paragraphs and throughout the web copy in a natural and fluent manner </li>
                    <li>100% Ownership Rights</li>
                    <li>100% Original Content</li>
                    <li>100% Approval Assurance</li>
                    <li>30 Days Refund Warranty</li>
                  </ul>

                  <div className="delivery">
                    <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                  </div>
                  <div className="pricing-btns">
                    {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                    <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
              <div className="pricing-card">
                <div className="main-box">
                  <LazyLoadImage src={pricing_bg} alt="img not found" className="img-fluid" effect="blur"/>
                </div>
                <div className="title">
                  <h2>Web Content Professional Package</h2>
                </div>
                <div className="inner-box">
                  <h5>STARTING AT</h5>
                  <h4>$849</h4>
                  <ul>
                    <li>10 Pages Professional Copywriting Service</li>
                    <li>Creative & Well-Written by 2 Professional Copywriters</li>
                    <li>Industry Specified Expert Copywriters</li>
                    <li>300 Words Per Page</li>
                    <li>Unlimited Revisions</li>
                    <li>7 to 10 Business Days Rotation</li>
                    <li>According To Your Exact Requirements</li>
                    <li>Proofing by our in-house experts</li>
                    <li>FREE Meta details – With each custom page</li>
                    <li>we will provide a catchy title</li>
                    <li>keywords and page description.</li>
                    <li>SEO friendly – Your keyword(s) will be placed in the title</li>
                    <li>the first & last paragraphs and throughout the web copy in a natural and fluent manner</li>
                    <li>100% Ownership Rights</li>
                    <li>100% Original Content</li>
                    <li>100% Approval Assurance</li>
                    <li>30 Days Refund Warranty</li>
                  </ul>

                  <div className="delivery">
                    <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                  </div>
                  <div className="pricing-btns">
                    {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                    <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
              <div className="pricing-card">
                <div className="main-box">
                  <LazyLoadImage src={pricing_bg} alt="img not found" className="img-fluid" effect="blur"/>
                </div>
                <div className="title">
                  <h2>Web Content Elite Package</h2>
                </div>
                <div className="inner-box">
                  <h5>STARTING AT</h5>
                  <h4>$1849</h4>
                  <ul>
                    <li>Up to 20 Pages Professional Copywriting Service</li>
                    <li>Creative & Well-Written by 3 Professional Copywriters</li>
                    <li>Industry Specified Expert Copywriters</li>
                    <li>300 Words Per Page</li>
                    <li>Unlimited Revisions</li>
                    <li>7 to 10 Business Days Rotation</li>
                    <li>According To Your Exact Requirements</li>
                    <li>Proofing by our in-house experts</li>
                    <li>FREE Meta details – With each custom page</li>
                    <li>we will provide a catchy title</li>
                    <li>keywords and page description.</li>
                    <li>SEO friendly – Your keyword(s) will be placed in the title</li>
                    <li>the first & last paragraphs and throughout the web copy in a natural and fluent manner</li>
                    <li>100% Ownership Rights</li>
                    <li>100% Original Content</li>
                    <li>100% Approval Assurance</li>
                    <li>30 Days Refund Warranty</li>
                  </ul>

                  <div className="delivery">
                    <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                  </div>
                  <div className="pricing-btns">
                    {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                    <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
              <div className="pricing-card">
                <div className="main-box">
                  <LazyLoadImage src={pricing_bg} alt="img not found" className="img-fluid" effect="blur"/>
                </div>
                <div className="title">
                  <h2>Web Content Identity Package</h2>
                </div>
                <div className="inner-box">
                  <h5>STARTING AT</h5>
                  <h4>$149</h4>
                  <ul>
                    <li>1 Creative</li>
                    <li>Fresh & Well-Written Article</li>
                    <li>By 1 Professional Copywriter</li>
                    <li>Industry Specified Expert Copywriter</li>
                    <li>400 Words Per Page</li>
                    <li>3 to 5 Business Days Rotation</li>
                    <li>5 Revisions</li>
                    <li>According To Your Exact Requirements</li>
                    <li>Written on Your Specified Topic/Keyword</li>
                    <li>Proofing by our in-house experts</li>
                    <li>Free Submission on any article marketing directories /blog (If required)</li>
                    <li>SEO friendly – Your keyword(s) will be placed in the title</li>
                    <li>the first & last paragraphs and throughout the web copy in a natural and fluent manner</li>
                    <li>100% Ownership Rights</li>
                    <li>100% Original Content</li>
                    <li>100% Approval Assurance</li>
                    <li>30 Days Refund Warranty</li>
                  </ul>

                  <div className="delivery">
                    <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                  </div>
                  <div className="pricing-btns">
                    {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                    <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
              <div className="pricing-card">
                <div className="main-box">
                  <LazyLoadImage src={pricing_bg} alt="img not found" className="img-fluid" effect="blur"/>
                </div>
                <div className="title">
                  <h2>Web Content Corporate Package</h2>
                </div>
                <div className="inner-box">
                  <h5>STARTING AT</h5>
                  <h4>$129</h4>
                  <ul>
                    <li>1 Creative</li>
                    <li>Fresh & Well-Written Article</li>
                    <li>By 1 Professional Copywriter</li>
                    <li>Industry Specified Expert Copywriter</li>
                    <li>400 Words Per Page</li>
                    <li>3 to 5 Business Days Rotation</li>
                    <li>5 Revisions</li>
                    <li>According To Your Exact Requirements </li>
                    <li>Written on Your Specified Topic/Keyword</li>
                    <li>Proofing by our in-house experts</li>
                    <li>Free Submission on any article marketing directories /blog (If required)</li>
                    <li>SEO friendly – Your keyword(s) will be placed in the title</li>
                    <li>the first & last paragraphs and throughout the web copy in a natural and fluent manner</li>
                    <li>100% Ownership Rights</li>
                    <li>100% Original Content</li>
                    <li>100% Approval Assurance</li>
                    <li>30 Days Refund Warranty</li>
                  </ul>

                  <div className="delivery">
                    <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                  </div>
                  <div className="pricing-btns">
                    {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                    <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="profund-sec-wrap">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">
              <div className="content">
                <h2>Our copywriting process</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3 col-xxl-3">
              <div className="profund-card">
                <div className="step">
                  <h5>01</h5>
                </div>
                <div className="inner-box">
                  <div className="main-img">
                    <LazyLoadImage src={icon_17} alt="img not found" className="img-fluid" effect="blur"/>
                  </div>
                  <div className="desc">
                    <h4>Research</h4>
                    <p>We research your brand to develop a content strategy that aligns with your business goals and communicates your unique value proposition.</p>
                  </div>
                  <div className="bg-img">
                    <LazyLoadImage src={profund_bg} alt="img not found" className="img-fluid" effect="blur"/>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3 col-xxl-3">
              <div className="profund-card">
                <div className="step">
                  <h5>02</h5>
                </div>
                <div className="inner-box">
                  <div className="main-img">
                    <LazyLoadImage src={icon_18}alt="img not found" className="img-fluid" effect="blur"/>
                  </div>
                  <div className="desc">
                    <h4>Write</h4>
                    <p>We use a collaborative approach, working with you to ensure that our copy meets your expectations and captures the essence of your brand.</p>
                  </div>
                  <div className="bg-img">
                    <LazyLoadImage src={profund_bg} alt="img not found" className="img-fluid" effect="blur"/>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3 col-xxl-3">
              <div className="profund-card">
                <div className="step">
                  <h5>03</h5>
                </div>
                <div className="inner-box">
                  <div className="main-img">
                    <LazyLoadImage src={icon_6} alt="img not found" className="img-fluid" effect="blur"/>
                  </div>
                  <div className="desc">
                    <h4>Implement</h4>
                    <p>This step involves optimizing the copy for search engines, ensuring that it is formatted correctly for the web,

                    </p>
                  </div>
                  <div className="bg-img">
                    <LazyLoadImage src={profund_bg} alt="img not found" className="img-fluid" effect="blur"/>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3 col-xxl-3">
              <div className="profund-card">
                <div className="step">
                  <h5>04</h5>
                </div>
                <div className="inner-box">
                  <div className="main-img">
                    <LazyLoadImage src="images/icon-17.png" alt="img not found" className="img-fluid" effect="blur"/>
                  </div>
                  <div className="desc">
                    <h4>Final</h4>
                    <p>We conduct a final round of edits and revisions to ensure that the copy is polished and error-free.</p>
                  </div>
                  <div className="bg-img">
                    <LazyLoadImage src={profund_bg} alt="img not found" className="img-fluid" effect="blur"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    <Footer />      
    </>
  )
}

export default CreativeWritting
