import React from 'react'

import Header from '../includes/header';
import Footer from '../includes/footer';
import useDocumentTitle from '../useDocumentTitle.jsx'

const TermsCondition = () => {
    useDocumentTitle('Terms And Condition')

  return (
    <>
          <Header/>
          <section className="polices-banner">
              <div className="container">
                  <div className="row justify-content-center text-center">
                      <div className="title">
                          <h1>Terms & Conditions</h1>
                      </div>
                  </div>
              </div>
          </section>

          <section className="main-privacy-sec">
              <div className="container">
                  <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <div className="term-text">
                              <h3>Revision Policy</h3>
                              <p>We believe in providing a service that does not require any kind of revisions. However, if it
                                  becomes necessary, we follow a policy for it.</p>
                              <p>Clients can approach us to revise their design with no extra charges, given that the design
                                  and concept remain intact. Furthermore, we provide revision under 48 hours and not more than
                                  48 hours.</p>
                              <h3>Refund Policy</h3>
                              <p>At Codex Design Agency we allow refunds within 45 days of order placement. However, we
                                  reserve the right to terminate this, at any time, with or without notice depending upon the
                                  scenario.</p>
                              <p>If you request a refund by viewing only the initial samples, this request shall continue the
                                  project. Therefore, a refund will not be available.</p>
                              <p>At Codex Design Agency, we value our customer’s prompt feedback. Therefore, we complete the
                                  task effectively and hope that the customer gives proper feedback to get the desired
                                  results.</p>
                              <p>We do not entertain or refund any orders if the client is non-responsive for 45 Days.
                                  However, the client can email us to hold the project if there is some personal issue.</p>
                              <p>If you want to forfeit any part of the discounted package, a refund (if applicable) will be
                                  available for the whole package. However, if the client has already availed of the
                                  discounted package for a Logo &amp; Website and only wants to avail of one service instead
                                  of both, they will not be applicable for any refund.</p>
                              <p>You will have to forfeit the right for a refund if you request additional revisions (1 or
                                  more design changes regardless of the complexity) or modifications to any of the initial
                                  concepts. You shall also forfeit the right to a refund if you do not respond promptly to a
                                  status notification from “Codex Design Agency.”</p>
                              <p>It is also to note that the customer will not be eligible for a refund of the remaining
                                  package. 24-hour design fees and 24-hour rush changes are non-refundable. No refund is
                                  available for design firms or those who order our design services on behalf of another
                                  entity. If you post an order cancellation request before delivering the initial concepts,
                                  you are eligible for a refund. Our team will return 100% amount in case of a duplicate
                                  charge.</p>
                              <p>According to the delivery policy, you would have to prove if the design is not perfect or if
                                  you did not get the design. If you can provide proof for your claim, you can claim a refund.
                              </p>
                              <p>You will have to agree that the refund will be as per the services available for bundle
                                  packages (e.g., Logo, Stationery, Website, Social Media, and Brochure). If there is any
                                  discontinuation with a service in the bundle package, then you would have to claim the
                                  refund before initiating that service.</p>
                              <h5>The following terms and conditions apply to all the services purchased by the client
                                  and provided by Codex Design Agency.</h5>
                              <p>If you deposit any funds, they will not be liable for a refund, nor if the initial design and
                                  concepts (after delivery) are approved or asked for a change. However, the refund would be
                                  available if Codex Design Agency cancel or end your Contract for a reason other than your
                                  breach or non-execution.</p>
                              <p>All refund requests will be as per the following arrangement:</p>
                              <p>• You make a solicitation when the underlying ideas for a logo are already available.
                                  However, once you approve or ask for changes in the initial designs, the refund offer will
                                  be void, and we will not entertain a refund request.</p>
                              <p>• Once the project has been entered into the revision phase,the refund offer will be void,
                                  and the refund request will not be entertained.</p>
                              <p>• On the off chance that demand for a refund is made before the delivery of initial design
                                  concepts, then you would qualify for a full refund (less 10% administration and preparing
                                  charge).</p>
                              <p>• If the refund request is made after 48 hours, the refund will not be available.</p>
                              <p>• We will not entertain a refund request after 96 hours of the initial design delivery.
                                  However, we believe in 100% customer satisfaction, so you are requested to reach out to us
                                  to address your concerns.</p>
                              <p>• We will not entertain any refund request after inaction by the customer after seven working
                                  days. If you wish to restart the order, you will be charged a certain fee contingent upon
                                  your venture.</p>
                              <p>• We will not entertain a request for a refund if the customer stays unresponsive at any
                                  point in time for five working days.</p>
                              <p>• If you want a refund, then communicate to the support department. codexdesignagency
                                  reserves the right to approve/reject refund requests based on an individual case-to-case
                                  premise.</p>
                              <p>• We will not accept a refund if the final files have been delivered.</p>
                              <p>• We will not accept a refund for website bundles once the initial design mock-up is revised
                                  or the inner pages are created with the client's approval.</p>
                              <p>• It would help if you communicated with the support department for refund requests.
                                  codexdesignagency, in light of the infringement of your user agreement, reserves all
                                  authority to affirm/object to your solicitation on an individual case to case premise.</p>
                              <p>• A refund will be available for the custom bundles from Codex Design Agency, just like the
                                  single packages.</p>
                              <p>• For instance, if you request a logo and web design and approve of the logo, you are
                                  eligible for the refund of the website service at the time of initial creation only.</p>
                              <p>A refund request should have a legitimate reason which must be qualified against the outline
                                  brief and client input for revisions. Unless an idea has not been composed as per the brief,
                                  a refund will not be approved; however, a discount will not be available. However, further
                                  revisions will be open until you are satisfied.</p>
                              <p>It is also to note that, under any circumstances, both parties (Codex Design Agency &amp;
                                  Client) agree not to attack/criticize each other and any of its employees, associate/s or
                                  partner/s publicly (on public forums, blogs, social networks, etc.) at any given time during
                                  or after the contract period.</p>
                              <p>Similarly, both parties agree not to talk on forums, blogs, community groups, or any social
                                  media platform in a way that brings a bad name to either party or any of its employees,
                                  associate, or partner. In case of a breach, the breaching party would have to pay a
                                  reasonable compensation decided by the non-breaching party as damages.</p>
                              <p>Money-Back Guarantee depends on whether the request is set per honesty. For example, where a
                                  client has placed design orders with more than one agency for the same job, intending to
                                  claim a refund, Codex Design Agency does not consider this viable. In such instances, we
                                  reserve the right to decline a refund request.</p>
                              <p>All design jobs require client input before finishing the design, so it is imperative that
                                  the customer remains active throughout the process and provides feedback for the results.
                              </p>
                              <p>If Codex Design Agency designs a logo similar to another existing design or any likeness to a
                                  current outline will be just a fortuitous event. Codex Design Agency will not acknowledge
                                  any responsibility or claim compensation in such a case. After finalizing the logo, it needs
                                  to go through the registration process to have complete ownership and 100% rights over the
                                  logo.</p>
                              <h3>How To Claim Your Refund</h3>
                              <p>To ensure that your refund request is processed effectively and is approved, please make sure
                                  that you meet the following requirements.</p>
                              <p>1. Specify your concern and claim your refund through any of the following three modes:-</p>
                              <p>• i. Toll-free # <a href="#">+1-(844)-452-0008</a></p>
                              <p>• ii. Live Chat.</p>
                              <p>• iii. Email :<a href="mailto:info@codexdesignagency.com">(<span className=" info@codexdesignagency.com">
                                  info@codexdesignagency.com</span>)</a></p>
                              <p>We will try to resolve your query and concern in light of our revision policy immediately or
                                  email you a refund request approval from our refund department.</p>
                              <p>After the refund, the rights to your design would be transferred to Codex Design Agency, and
                                  you would not legally be allowed to display any version of the design sent by the company.
                              </p>
                              <p>1. Since the design rights would now be transferred to Codex Design Agency, you concur that
                                  you will have no right (immediate or circuitous) to use any reaction or other substance,
                                  work item, or media, nor will you have any ownership interest for or to the same.</p>
                              <p>2. Working in a joint effort with the Government Copyright Agencies, Codex Design Agency
                                  would share Copyright Acquisition data for the refunded outlines that would confine the
                                  re-utilization of the designs as original designs in the future.</p>
                              <p>If you have any questions or concerns about our Refund Policy, don't hesitate to contact us
                                  by clicking here <a href="tel:+1-(844)-452-0008">(<span>
                                      info@codexdesignagency.com</span>)</a></p>
                              <h3>My Account</h3>
                              <p>The My Account area is a convenient way to communicate. However, it is your sole
                                  responsibility to check the account section for all questions, queries, concerns, and any
                                  other instructions for the designer. Failure to actively check my account section may become
                                  a cause of hindrance in your pursuit of a refund. If you are unsure of how to use the My
                                  Account area, please get in touch with customer support at any time for prompt assistance.
                              </p>
                              <h3>Quality Assurance Policy</h3>
                              <p>For complete satisfaction, our designers are instructed not to deviate from the
                                  specifications provided by the client in the order form.</p>
                              <p>The designs are crafted after adequate and thorough research to ensure quality and
                                  uniqueness.</p>
                              <h3>100% Satisfaction Guarantee</h3>
                              <p>We ensure that you are 100% fulfilled with your spending and our delivery (depending upon
                                  your package).</p>
                              <h3>Domain and Hosting</h3>
                              <p>Domain and Hosting are provided if included in your customized package.</p>
                              <p>Emails can only be provided if your website is hosted on our server.</p>
                              <p>If you wish to opt out of hosting the website with us, we will not provide email accounts.
                              </p>
                              <h3>Delivery Policy</h3>
                              <p>All design order files are delivered to My Account as specified on the "Order Confirmation"
                                  date. We also compose an email to inform them about the design order delivery. All policies
                                  about revision &amp; refund are subject to the date and time of the design order delivered
                                  to the client's account area.</p>
                              <p>All design order files are available on "My Account" as per the date specified on the "Order
                                  Confirmation." In addition, we compose an email to inform the client about their design
                                  order delivery to their specific account area. All policies about revision &amp; refund are
                                  subject to the date and time of the design order delivered to the client's account area.</p>
                              <p>All customized design orders are delivered via email within 5 to 7 days after receiving the
                                  order.</p>
                              <p>We offer a RUSH DELIVERY service through which you can have your first logo within 24 hours
                                  by paying just $100 additional! For further help, get in touch with our customer support
                                  department.</p>
                              <h3>Record Maintenance</h3>
                              <p>We keep your final design archived after we deliver your final files. If you wish to receive
                                  the final files again, we can email them upon request.</p>
                              <h3>Customer Support</h3>
                              <p>We offer 24-Hour Customer Support to address your questions and queries.</p>
                              <p>You can get in touch with us anytime, as we promise a prompt reply.</p>
                              <h3>Correspondence Policy</h3>
                              <p>YOU concur that Codex Design Agency is not at risk for any correspondence from email address
                                  (es) other than the ones taken after by our particular area, i.e., "insert mail" or/and any
                                  toll-free number not specified on our site. Codex Design Agency is not in charge of any
                                  damage(s) brought about by such correspondence. We assume the liability of any
                                  correspondence through email address (es) under our space name or/and through the toll-free
                                  number, i.e., as specified on Website Design Flex Website.</p>
                              <h3>Money-Back Guarantee</h3>
                              <p>We are highly confident of the work we do, which is why we back it up with a money-back
                                  guarantee. If we cannot meet your expectations, we'll give you a refund.</p>
                              <h3>100% Unique Design Guarantee</h3>
                              <p>At Codex Design Agency, we promise that our logos are produced/designed from scratch.
                                  Therefore, we will provide you with a fantastic logo and completely comply with your design
                                  brief.</p>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <Footer />
    </>
  )
}

export default TermsCondition
