import React from 'react'
import qm_img from '../assets/images/qm-img.png';
import Cta from '../component/cta';


const choose_us = () => {
  return (
    <div>
          <section className="why_choose">
              <div className="container">
                  <div className="row align-items-center">
                      <div className="col-lg-8 col-12">
                          <h2>Why Choose Us!</h2>
                          <p>Codex Design Agency offers exceptional website solutions for your business. Our team of experts combines creativity and technical expertise to deliver outstanding results that meet your unique needs and goals. With a focus on quality and
                              customer satisfaction, our website design agency ensures that every aspect of your website is tailored to your specific requirements. Our web development and design services will take your online presence to new heights. Let us help
                              you succeed online.</p>
                      </div>
                      <div className="col-lg-4 col-12 d-flex justify-content-sm-end "><img src={qm_img} alt="img not found"/></div>
                  </div>
              </div>
          </section>
          <Cta/>
    </div>
  )
}

export default choose_us
