import React from 'react'
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Logo from '../assets/images/logo.png';
import payment from '../assets/images/payment.png';
import faq_slider from '.././json/faq_slider.json'

const footer = () => {

    const Brands_Slider = {
        loop: true,
        margin: 10,
        nav: true,
        nav: false,
        dots: false,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            1024: {
                items: 2
            },
            1200: {
                items: 3
            }
        }
    };
  return (
    <>
        <section className="faq-sec">
            <div className="container">
                <div className="row align-items-center justify-content-center text-center">
                    <div className="col-lg-8 col-12 head_txt">
                        <h2>Frequently Asked Questions</h2>
                        <p>Have questions about our website development services? Get your answers here!</p>
                    </div>
                </div>
                <div className="faqs-slider">
                  <OwlCarousel {...Brands_Slider} className="owl-carousel owl-theme">
                    {
                        faq_slider.map((item)=>{
                            return(
                                <div className="mx-1" key={item.id}> 
                                    <div className="faq-content shadow-sm">
                                        <h4>{item.title}</h4>
                                        <p>{item.desc}</p>
                                        <div className="overlay">
                                            <h4>{item.title2}</h4>
                                            <p>{item.desc2}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })

                    }
                  </OwlCarousel>
                </div>
            </div>
        </section>

        <section className="ft_form">
            <div className="container">
                <div className="row form-bg justify-content-between">
                    <div className="col-lg-4 col-12">
                        <h3>Know what you want?<br/>Get in touch!</h3>
                        <p>Are you looking to take your online presence to the next level? Do you want to build a stunning website that showcases your br/Linknd and attracts customers? If so, Codex Design Agency is here to help. Our web design agency &amp; writing services
                            specializes in web development, logo design, digital marketing, and SEO services, helping businesses just like yours achieve their online goals.</p>
                    </div>
                    <div className="col-lg-7 col-12 form-wrap">
                        <form action="https://leads.infinityprojectmanager.com/br/Linknd/codexdesignagency/lead" className="popup-form CrudForm cmxform">
                            <input type="hidden" name="br/Linknd_name" defaultValue="codexdesignagency.com"/> 
                            <input type="hidden" id="lead_area" name="lead_area" defaultValue="https://codexdesignagency.com/"/>
                            <input className="form-control" placeholder="Name" name="name" required/>
                            <input className="form-control" placeholder="Email" name="email" required/>
                            <input className="form-control" placeholder="Contact Number" name="phone" required/>
                            <select className="form-select" aria-label="Default select example" name="service">
                                <option selected="">Select Services</option>
                                <option defaultValue="Web Design">Web Design</option>
                                <option defaultValue="Web development">Web development</option>
                                <option defaultValue="Logo Design">Logo Design</option>
                                <option defaultValue="Ecommerce Solutions">Ecommerce Solutions</option>
                                <option defaultValue="Animation">Animation</option>
                                <option defaultValue="PPC">PPC</option>
                                <option defaultValue="SEO Services">SEO Services</option>
                                <option defaultValue="Digital Marketing">Digital Marketing</option>
                                <option defaultValue="Creative Copywriting">Creative Copywriting</option>
                            </select>
                            <textarea className="form-control" rows="3" name="message" placeholder="Message" required></textarea>
                            <button className="form-btn">Send Message</button>
                        </form>
                    </div>
                </div>
            </div>
        </section>

        <footer>
            <div className="container">
                <div className="row justify-content-between">
                      <div className="col-lg-3 col-12"><LazyLoadImage src={Logo} placeholderSrc={Logo} effect="blur" className="footer-logo" alt="img not found"/>
                        <p className="sec-para">We are a one-stop solution for all your website development and br/Linknding needs! Let your business attain great heights with our custom web design &amp; development &amp; writing services.</p>
                    </div>
                    <div className="col-lg-9 col-12">
                        <div className="row">
                              <div className="col-lg-3 col-12 col-md-6">
                                <div className="footer-navigation">
                                    <h3>Quick Links</h3>
                                    <ul>
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/about">About Us</Link></li>
                                        <li><Link to="/contact">Contact Us</Link></li>
                                        <li><Link to="/review">Reviews</Link></li>
                                    </ul>
                                </div>
                            </div>
                              <div className="col-lg-3 col-12 col-md-6">
                                <div className="footer-navigation">
                                    <h3>Services</h3>
                                    <ul>
                                        <li><Link to="/web-design">Web Design</Link></li>
                                        <li><Link to="/logo-design">Logo Design</Link></li>
                                        <li><Link to="/ecommerce">Ecommerce Solutions</Link></li>
                                        <li><Link to="/animation">Animation</Link></li>
                                        <li><Link to="/seo">SEO Services</Link></li>
                                    </ul>
                                </div>
                            </div>
                              <div className="col-lg-3 col-12 col-md-6">
                                <div className="footer-navigation">
                                    <h3>More Services</h3>
                                    <ul>
                                        <li><Link to="/digital-marketing">Digital Marketing</Link></li>
                                        <li><Link to="/creative-writting">Creative Copywriting</Link></li>
                                        <li><Link to="/ppc">PPC</Link></li>
                                    </ul>
                                </div>
                            </div>
                              <div className="col-lg-3 col-12 col-md-6 footer-nav">
                                <div className="footer-navigation">
                                    <h3>Get in Touch Now!</h3>
                                    <ul>
                                        <li>
                                            <Link to="+1-(844)-452-0008">
                                                <i className="fa-solid fa-phone"></i> +1-(844)-452-0008 </Link>
                                        </li>
                                        <li><Link to=" info@codexdesignagency.com"><span> <i className="fa-solid fa-envelope"></i> info@codexdesignagency.com</span></Link></li>
                                        <li>
                                            <Link to="#">
                                                <i className="fa-solid fa-location-dot"></i> 4900 California Avenue, Tower B, 2nd Floor, Bakersfield, California, 93309, United States of America </Link>
                                        </li>
                                          <li><LazyLoadImage src={payment} effect="blur" placeholderSrc={payment} alt="img not found"/></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="row align-items-center justify-content-center mt-4">
                        <div className="col-lg-8 col-12">
                              <p>Copyright © 2020-{new Date().getFullYear()} Codex Design Agency All Rights Reserved.</p>
                        </div>
                        <div className="col-lg-4 col-12 d-sm-flex justify-content-end">
                            <div className="policy-links">
                             <Link to="/privacy-policy">Privacy Policy</Link>
                             <Link to="/terms-condition">Terms &amp; Condition</Link></div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>  

    </>
    
  )
}

export default footer
