import React from 'react'
import { Link } from 'react-router-dom';


const mobileMenu = () => {
  return (
    <>
        <div className="mobile_menu">
            <ul>
                <li>
                    <Link to="/"><i class="fa-regular fa-house"></i></Link>
                </li>
                <li>
                      <Link to="/about"><i class="fa-regular fa-user"></i></Link>
                </li>
                <li>
                      <Link to="/packages"><i class="fa-regular fa-sack-dollar"></i></Link>
                </li>
                  <li>
                      <Link to="/review"><i class="fa-light fa-star-sharp"></i></Link>
                  </li>
                <li>
                      <Link to="/contact"><i class="fa-regular fa-address-book"></i></Link>
                </li>
               
            </ul>
        </div>
    </>
  )
}

export default mobileMenu
