import React from 'react'


import Header from '../includes/header';
import Footer from '../includes/footer';
import About from '../component/about';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import icon_5 from '../assets/images/icon-5.png';
import icon_6 from '../assets/images/icon-6.png';
import icon_7 from '../assets/images/icon-7.png';
import icon_8 from '../assets/images/icon-8.png';
import icon_9 from '../assets/images/icon-9.png';
import icon_10 from '../assets/images/icon-10.png';
import icon_11 from '../assets/images/icon-11.png';
import icon_12 from '../assets/images/icon-12.png';
import icon_13 from '../assets/images/icon-13.png';
import icon_15 from '../assets/images/icon-15.png';
import icon_14 from '../assets/images/icon-14.png';
import icon_16 from '../assets/images/icon-16.png';
import icon_17 from '../assets/images/icon-17.png';
import icon_18 from '../assets/images/icon-18.png';
import brand_img from '../assets/images/brand-img.png';
import inner_banner_img from '../assets/images/inner-banner-img.png';
import pricing_bg from '../assets/images/pricing-bg.png';
import profund_bg from '../assets/images/profund-bg.png';
import useDocumentTitle from '../useDocumentTitle.jsx'


const Animation = () => {
    useDocumentTitle('Animation')

  return (
    <>
          <Header/>

          <section className="inner-banner-sec">
              <div className="container">
                  <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="banner-content">
                              <h1>Premium Animation Video Services</h1>
                              <p>With years of experience, our animation video company offers an all-in-one solution that prioritizes the needs of our customers. We specialize in creating customized animated videos that are visually appealing and can capture your audience's attention, regardless of the style - 2D/3D, explainer, motion graphics video, and more. We stay up-to-date with the latest trends and techniques by utilizing advanced tools, which allows you to stay ahead of the competition.</p>
                              <div className="brand-rating">
                                  <div className="rating">
                                      <h6>5.0 </h6>
                                      <ul>
                                          <li><i className="fa-solid fa-star"></i></li>
                                          <li><i className="fa-solid fa-star"></i></li>
                                          <li><i className="fa-solid fa-star"></i></li>
                                          <li><i className="fa-solid fa-star"></i></li>
                                          <li><i className="fa-solid fa-star"></i></li>
                                      </ul>
                                      <h6> rating on</h6>
                                  </div>
                                  <div className="brand-img">
                                      <LazyLoadImage src={brand_img}  alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                              </div>
                              <div className="banner-btn">
                                  <a href="javascript:void;" className="btn btn-contact" data-bs-toggle="modal" data-bs-target="#exampleModal">Get a quote now</a>
                                  <a href="javascript:$zopim.livechat.window.show();" className="btn btn-chat"><i className="fa-solid fa-messages"></i></a>
                              </div>
                          </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="main-img">
                              <LazyLoadImage src={inner_banner_img} className="img-fluid"  alt="img not found" effect="blur"/>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          
          <About/>

          <section className="Premium-ppc-sec">
              <div className="container-fluid">
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="content">
                              <h3>Custom Animation Video Services</h3>
                              <p>Our animation video company offers a range of stellar animation services. Review them below</p>
                          </div>
                      </div>
                  </div>
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_5}  alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>2D Animation</h4>
                                  <p>This comprises objects and characters on a two-dimensional plane. Our team uses 2D animation to create explainer videos, educational videos, and marketing videos..</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_6}  alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>3D Animation</h4>
                                  <p>3D animation looks more realistic as the objects and characters are created on a three-dimensional plane. It's used for product demos, medical video production, and corporate videos.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_7}  alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Explainer Video Animation</h4>
                                  <p>Our team explains complex ideas or concepts simply and engagingly using explainer videos. Educational videos are mostly explainer videos.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_8}  alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Frame By Frame</h4>
                                  <p>This is a traditional type of animation, where each frame is drawn individually, creating a fluid and realistic movement. It's used in animated music videos and ads..</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_9}  alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Educational Video</h4>
                                  <p>Educational videos aim to educate the audience on a particular topic or subject, using different visual aids to simplify their learning process.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_10}  alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Corporate Video</h4>
                                  <p>We create corporate videos to showcase a company's products, services, or culture to its audience. It guarantees brand awareness and promotional purposes.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_11}  alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Animated Music Video</h4>
                                  <p>Our animated music video visualizes a song, creating a visually appealing experience for the audience.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_12}  alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Video Ad</h4>
                                  <p>Video ads are more promotional and aim to advertise a product or service to a specific audience, using persuasive techniques to generate interest and boost sales.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_13}  alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Medical Video Production</h4>
                                  <p>We create videos to educate medical professionals or patients on different medical procedures, conditions, and treatments.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_14} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Marketing Videos</h4>
                                  <p>Again, these promotional videos can help spread the word about your company or its services or products.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_15}  alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Product Demo Videos</h4>
                                  <p>Product demo videos show how a product works, its features, and its benefits. We can create attractive product demos to help your audience learn how to use your products.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_16}  alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Training Videos</h4>
                                  <p>Whether you’re looking to train your employees or customers, we can help you create informative training videos. .</p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="pricing-sec-wrap">
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">
                          <div className="content">
                              <h2>Pricing Plans</h2>
                          </div>
                      </div>
                  </div>
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <div className="pricing-top-btn">
                              <a href="javascript:$zopim.livechat.window.show();" className="btn btn-top-pricing">Video Animation</a>
                          </div>
                      </div>
                  </div>
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="pricing-card">
                              <div className="main-box">
                                  <LazyLoadImage src={pricing_bg} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="title">
                                  <h2>Teaser/Intro Video Package</h2>
                              </div>
                              <div className="inner-box">
                                  <h5>STARTING AT</h5>
                                  <h4>$145</h4>
                                  <ul>
                                      <li>10 Seconds Video</li>
                                      <li>Professional Script</li>
                                      <li>Sample Theme</li>
                                      <li>Storyboard</li>
                                      <li>Custom Artwork</li>
                                      <li>Animation</li>
                                      <li>Professional Voice - Over Sound Effects</li>
                                      <li>1 week Delivery</li>
                                      <li>Unlimited Revisions</li>
                                  </ul>

                                  <div className="delivery">
                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                  </div>
                                  <div className="pricing-btns">
                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="pricing-card">
                              <div className="main-box">
                                  <LazyLoadImage src={pricing_bg}  alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="title">
                                  <h2>Startup Video Package</h2>
                              </div>
                              <div className="inner-box">
                                  <h5>STARTING AT</h5>
                                  <h4>$195</h4>
                                  <ul>
                                      <li>30s Duration - HD 1080</li>
                                      <li>Professional Script</li>
                                      <li>Storyboard</li>
                                      <li>Sample Themes</li>
                                      <li>Custom Setting</li>
                                      <li>Characters Graphics</li>
                                      <li>Animation Effects Visualization</li>
                                      <li>Voice - Over Sound Effects (All accents) (M/F)</li>
                                      <li>Unlimited Revisions</li>
                                  </ul>

                                  <div className="delivery">
                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                  </div>
                                  <div className="pricing-btns">
                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="pricing-card">
                              <div className="main-box">
                                  <LazyLoadImage src={pricing_bg}  alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="title">
                                  <h2>Classic Video Package</h2>
                              </div>
                              <div className="inner-box">
                                  <h5>STARTING AT</h5>
                                  <h4>$395</h4>
                                  <ul>
                                      <li>60 Second Video - HD 1080</li>
                                      <li>Professional Script</li>
                                      <li>Sample Theme</li>
                                      <li>Storyboard</li>
                                      <li>Animation</li>
                                      <li>Voice - Over Sound Effects</li>
                                      <li>5 weeks Delivery</li>
                                      <li>Unlimited Revisions</li>
                                  </ul>

                                  <div className="delivery">
                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                  </div>
                                  <div className="pricing-btns">
                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="pricing-card">
                              <div className="main-box">
                                  <LazyLoadImage src={pricing_bg}  alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="title">
                                  <h2>Premium Video Package</h2>
                              </div>
                              <div className="inner-box">
                                  <h5>STARTING AT</h5>
                                  <h4>$695</h4>
                                  <ul>
                                      <li>90 Second Video - HD 1080</li>
                                      <li>Professional Script</li>
                                      <li>Sample Theme</li>
                                      <li>Storyboard</li>
                                      <li>Animation</li>
                                      <li>Voice - Over Sound Effects</li>
                                      <li>6 Weeks Delivery</li>
                                      <li>Unlimited Revisions</li>
                                  </ul>

                                  <div className="delivery">
                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                  </div>
                                  <div className="pricing-btns">
                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="pricing-card">
                              <div className="main-box">
                                  <LazyLoadImage src={pricing_bg}  alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="title">
                                  <h2>Deluxe Video Package Package</h2>
                              </div>
                              <div className="inner-box">
                                  <h5>STARTING AT</h5>
                                  <h4>$895</h4>
                                  <ul>
                                      <li>120 Second Video - HD 1080</li>
                                      <li>Professional Script</li>
                                      <li>Sample Theme</li>
                                      <li>Storyboard</li>
                                      <li>Animation</li>
                                      <li>Voice - Over Sound Effects</li>
                                      <li>Unlimited Revisions</li>
                                  </ul>

                                  <div className="delivery">
                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                  </div>
                                  <div className="pricing-btns">
                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="profund-sec-wrap">
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">
                          <div className="content">
                              <h2>Our Impeccable Animation Video Process</h2>
                          </div>
                      </div>
                  </div>
                  <div className="row justify-content-center">
                      <div className="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3 col-xxl-3">
                          <div className="profund-card">
                              <div className="step">
                                  <h5>01</h5>
                              </div>
                              <div className="inner-box">
                                  <div className="main-img">
                                      <LazyLoadImage src={icon_17}  alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                                  <div className="desc">
                                      <h4>Script Writing</h4>
                                      <p>Our team works to identify your needs and goals for the video. We will develop a script that clearly outlines the message and story that the video aims to convey.</p>
                                  </div>
                                  <div className="bg-img">
                                      <LazyLoadImage src={profund_bg}  alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3 col-xxl-3">
                          <div className="profund-card">
                              <div className="step">
                                  <h5>02</h5>
                              </div>
                              <div className="inner-box">
                                  <div className="main-img">
                                      <LazyLoadImage src={icon_18}  alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                                  <div className="desc">
                                      <h4>Concept & Story development</h4>
                                      <p>Next, we will develop the concept and storyboard of the video based on the script. A visual representation of the story and sequence of events will give you an idea of how the final video will look.</p>
                                  </div>
                                  <div className="bg-img">
                                      <LazyLoadImage src={profund_bg}  alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3 col-xxl-3">
                          <div className="profund-card">
                              <div className="step">
                                  <h5>03</h5>
                              </div>
                              <div className="inner-box">
                                  <div className="main-img">
                                      <LazyLoadImage src={icon_6}  alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                                  <div className="desc">
                                      <h4>Design</h4>
                                      <p>We use advanced tools and techniques to develop the visual elements of the video, including characters, objects, and backgrounds, ensuring that they align with your branding and style.</p>
                                  </div>
                                  <div className="bg-img">
                                      <LazyLoadImage src={profund_bg}  alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3 col-xxl-3">
                          <div className="profund-card">
                              <div className="step">
                                  <h5>04</h5>
                              </div>
                              <div className="inner-box">
                                  <div className="main-img">
                                      <LazyLoadImage src={icon_17}  alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                                  <div className="desc">
                                      <h4>Finalization</h4>
                                      <p>Our team will put everything together, and produce the final video by adding sound effects, voiceovers, music, etc.</p>
                                  </div>
                                  <div className="bg-img">
                                      <LazyLoadImage src={profund_bg}  alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <Footer />

      
    </>
  )
}

export default Animation
