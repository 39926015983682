import React from 'react'
import Header from '../includes/header';
import Footer from '../includes/footer';

import brand_img from '../assets/images/brand-img.png';
import inner_banner_img from '../assets/images/inner-banner-img.png';
import icon_1 from '../assets/images/icon-1.png';
import icon_2 from '../assets/images/icon-2.png';
import icon_3 from '../assets/images/icon-3.png';
import icon_4 from '../assets/images/icon-4.png';

import icon_5 from '../assets/images/icon-5.png';
import icon_6 from '../assets/images/icon-6.png';
import icon_8 from '../assets/images/icon-8.png';

import icon_9 from '../assets/images/icon-9.png';
import icon_10 from '../assets/images/icon-10.png';
import icon_11 from '../assets/images/icon-11.png';
import icon_12 from '../assets/images/icon-12.png';

import icon_13 from '../assets/images/icon-13.png';
import icon_14 from '../assets/images/icon-14.png';
import icon_15 from '../assets/images/icon-15.png';
import icon_16 from '../assets/images/icon-16.png';
import icon_17 from '../assets/images/icon-17.png';
import icon_18 from '../assets/images/icon-18.png';


import pricing_bg from '../assets/images/pricing-bg.png';
import profund_bg from '../assets/images/profund-bg.png';
import useDocumentTitle from '../useDocumentTitle.jsx'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';




const Seo = () => {
    useDocumentTitle('Seo')

  return (
    <>
    <Header/>
          <section className="inner-banner-sec">
              <div className="container">
                  <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="banner-content">
                              <h1>Professional SEO Services</h1>
                              <p>Are you looking for the best SEO Company online? We are known for providing top-notch SEO
                                  services globally, backed by a proven track record. Our customized strategies, advanced
                                  technology, and expert team ensure maximum revenue generation for clients, providing all the
                                  necessary tools for SEO-driven growth.</p>
                              <div className="brand-rating">
                                  <div className="rating">
                                      <h6>5.0 </h6>
                                      <ul>
                                          <li><i className="fa-solid fa-star"></i></li>
                                          <li><i className="fa-solid fa-star"></i></li>
                                          <li><i className="fa-solid fa-star"></i></li>
                                          <li><i className="fa-solid fa-star"></i></li>
                                          <li><i className="fa-solid fa-star"></i></li>
                                      </ul>
                                      <h6> rating on</h6>
                                  </div>
                                  <div className="brand-img">
                                      <LazyLoadImage src={brand_img} alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                              </div>
                              <div className="banner-btn">
                                  <a href="javascript:void(0);" className="btn btn-contact" data-bs-toggle="modal" data-bs-target="#exampleModal">Talk to us now!</a>
                                  <a href="javascript:$zopim.livechat.window.show();" className="btn btn-chat"><i className="fa-solid fa-messages"></i></a>
                              </div>
                          </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="main-img">
                              <LazyLoadImage src={inner_banner_img} className="img-fluid" alt="img not found" effect="blur"/>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="about-sec-wrap">
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">
                          <div className="content">
                              <h2>About Us</h2>
                              <h3>Professional SEO Company in the USA to Meet Your Needs.</h3>
                              <p>Codex Design Agency is the best SEO provider dedicated to driving the growth of your online
                                  business. Our team combines cutting-edge technology and effective strategies to provide a
                                  comprehensive suite of SEO services, including keyword research, on-page optimization,
                                  backlink building, and more. We understand the importance of a tailored approach to SEO,
                                  which is why we work closely with our clients to understand their unique needs and
                                  objectives. With a proven track record of success, we have helped countless businesses
                                  achieve higher rankings, increased visibility, and improved revenue generation. Trust us to
                                  help take your business to the next level with our elite SEO services.</p>
                          </div>
                      </div>
                  </div>
                  <div className="row justify-content-xl-between justify-content-center">
                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
                          <div className="about-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_1} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>275+</h4>
                                  <p>Projects Completed.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
                          <div className="about-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_2} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>89%</h4>
                                  <p>Customer Retention</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
                          <div className="about-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_3} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>120+</h4>
                                  <p>Professionals</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
                          <div className="about-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_4} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>20+</h4>
                                  <p>Years of Experience</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
                          <div className="about-card">
                              <h5>Schedule A Call
                                  <br/>
                                      Now!
                              </h5>
                              <div className="desc">
                                  <a href="javascript:void(0);" className="btn btn-call"><i className="fa-solid fa-phone-volume"></i> Click
                                      Here!</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="Premium-ppc-sec">
              <div className="container-fluid">
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="content">
                              <h3>Professional SEO services</h3>
                              <p>Learn about our range of SEO services in the USA to know why we stand out in the industry.
                              </p>
                          </div>
                      </div>
                  </div>
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_5} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>B2B SEO service</h4>
                                  <p>Our team conducts extensive research to identify the most relevant keywords and phrases
                                      that potential B2B customers use to search for products and services.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_6} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Shopify SEO service</h4>
                                  <p>Our Shopify SEO service is designed to help e-commerce businesses use the Shopify
                                      platform to improve their online visibility and drive more sales.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_6} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Local SEO services</h4>
                                  <p>We optimize our clients' websites to target the most relevant local keywords, create
                                      local citations and listings, and manage their online reputation to help them stand out
                                      in their local market.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_8} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>E-commerce SEO</h4>
                                  <p>Our e-commerce SEO service is designed to help online businesses improve their online
                                      visibility, increase traffic, and generate more sales..</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_9} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>SEO link-building service</h4>
                                  <p>We use a range of proven techniques to identify relevant websites and create engaging
                                      content that earns links naturally, helping our clients build authority and improve
                                      their search rankings.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_10} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Site Audit</h4>
                                  <p>We conduct a thorough analysis of their website's structure, content, and performance,
                                      providing detailed recommendations to help our clients improve their online presence and
                                      drive more traffic to their sites.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_11} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>On-page optimization</h4>
                                  <p>Our on-page optimization service is designed to improve the technical structure of our
                                      client's websites, making them more search engine friendly.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_12} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Keyword Research</h4>
                                  <p>We use a range of tools and techniques to identify the most relevant and high-converting
                                      keywords and phrases, helping our clients optimize their websites and create content
                                      that resonates with their target audience.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_13} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Competitive Analysis</h4>
                                  <p>We help businesses identify their key competitors and understand their online marketing
                                      strategies.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_14} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Technical SEO</h4>
                                  <p>Our team conducts an in-depth analysis of your business's online presence to ensure it's
                                      optimized to perform well.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_15} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Content Creation</h4>
                                  <p>Our content creation service is designed to help businesses create high-quality, engaging
                                      content that resonates with their target audience.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_16} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>Mobile Optimization</h4>
                                  <p>We optimize the website's design, content, and functionality to ensure that it is
                                      properly formatted and structured for mobile users.</p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="profund-sec-wrap">
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">
                          <div className="content">
                              <h2>Our Process</h2>
                          </div>
                      </div>
                  </div>
                  <div className="row justify-content-center">
                      <div className="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3 col-xxl-3">
                          <div className="profund-card">
                              <div className="step">
                                  <h5>01</h5>
                              </div>
                              <div className="inner-box">
                                  <div className="main-img">
                                      <LazyLoadImage src={icon_17} alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                                  <div className="desc">
                                      <h4>Discover</h4>
                                      <p>The first step is to discover the client's website and business to understand their
                                          unique needs and objectives.</p>
                                  </div>
                                  <div className="bg-img">
                                      <LazyLoadImage src={profund_bg} alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3 col-xxl-3">
                          <div className="profund-card">
                              <div className="step">
                                  <h5>02</h5>
                              </div>
                              <div className="inner-box">
                                  <div className="main-img">
                                      <LazyLoadImage src={icon_18} alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                                  <div className="desc">
                                      <h4>Analyze</h4>
                                      <p>Next, we analyze their target audience, competition, and relevant keywords and
                                          phrases.</p>
                                  </div>
                                  <div className="bg-img">
                                      <LazyLoadImage src={profund_bg} alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3 col-xxl-3">
                          <div className="profund-card">
                              <div className="step">
                                  <h5>03</h5>
                              </div>
                              <div className="inner-box">
                                  <div className="main-img">
                                      <LazyLoadImage src="images/icon-6.png" alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                                  <div className="desc">
                                      <h4>Execute</h4>
                                      <p>Our team implements the necessary changes to the client's website and begins
                                          executing the various SEO techniques. This includes optimizing the website's
                                          content, building high-quality backlinks, creating engaging content, and more.</p>
                                  </div>
                                  <div className="bg-img">
                                      <LazyLoadImage src={profund_bg} alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3 col-xxl-3">
                          <div className="profund-card">
                              <div className="step">
                                  <h5>04</h5>
                              </div>
                              <div className="inner-box">
                                  <div className="main-img">
                                      <LazyLoadImage src="images/icon-17.png" alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                                  <div className="desc">
                                      <h4>Report</h4>
                                      <p>We report the results of the SEO services and optimize them for continued success.
                                      </p>
                                  </div>
                                  <div className="bg-img">
                                      <LazyLoadImage src={profund_bg} alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="pricing-sec-wrap">
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">
                          <div className="content">
                              <h2>Pricing Plans</h2>
                          </div>
                      </div>
                  </div>
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <div className="pricing-top-btn">
                              <a href="javascript:void(0);" className="btn btn-top-pricing">Seo Services</a>
                          </div>
                      </div>
                  </div>
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="pricing-card">
                              <div className="main-box">
                                  <LazyLoadImage src={pricing_bg} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="title">
                                  <h2>Startup SEO Package</h2>
                              </div>
                              <div className="inner-box">
                                  <h5>STARTING AT</h5>
                                  <h4>$445</h4>
                                  <ul>
                                      <li>5 Keywords</li>
                                      <li>Guaranteed Ranking on Google</li>
                                      <li>Off-site Optimization</li>
                                      <li>Link Building</li>
                                      <li>Social Bookmarking</li>
                                      <li>Basic Analytical Report</li>
                                      <li>In-depth Site Analysis</li>
                                      <li>Content Duplicity Check</li>
                                      <li>Initial Backlinks analysis</li>
                                      <li>Google Penalty Check</li>
                                      <li>Mobile Usability Check</li>
                                      <li>Competition Analysis</li>
                                      <li>Keyword Research</li>
                                      <li>nformational Content Writing & Sharing (2 - Per Month)</li>
                                      <li>Blog Writing (1 - Per Month)</li>
                                      <li>Press Release Social Bookmarking (5 - Per Month)</li>
                                      <li>NAP Syndication</li>
                                      <li>Google My Business / Bing Local Listing</li>
                                      <li>Citation Building</li>
                                      <li>Classified Submissions</li>
                                      <li>Google Analytics Analysis Report</li>
                                      <li>SEO Reports</li>
                                      <li>Search Engine Rank Report</li>
                                      <li>Dedicated Accounts Manager</li>
                                      <li>onthly Action Plan</li>
                                      <li>Activity Report</li>
                                  </ul>

                                  <div className="delivery">
                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                  </div>
                                  <div className="pricing-btns">
                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="pricing-card">
                              <div className="main-box">
                                  <LazyLoadImage src={pricing_bg} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="title">
                                  <h2>Identity SEO Package</h2>
                              </div>
                              <div className="inner-box">
                                  <h5>STARTING AT</h5>
                                  <h4>$845</h4>
                                  <ul>
                                      <li>2 Stationery Design Set</li>
                                      <li>10 Keywords</li>
                                      <li>Guaranteed Ranking on Google</li>
                                      <li>Off-site Optimization</li>
                                      <li>On-site Optimization</li>
                                      <li>Link Building</li>
                                      <li>Social Bookmarking</li>
                                      <li>In-depth Site Analysis</li>
                                      <li>Content Duplicacy Check</li>
                                      <li>Initial Backlinks analysis</li>
                                      <li>Google Penalty Check</li>
                                      <li>Mobile Usability Check</li>
                                      <li>Competition Analysis</li>
                                      <li>Keyword Research</li>
                                      <li>Title & Meta Tags Optimization</li>
                                      <li>Content Optimization</li>
                                      <li>Page Speed Analysis & Optimization</li>
                                      <li>HTML Code Cleanup & Optimization</li>
                                      <li>Internal Link Structuring & Optimization</li>
                                      <li>Pages H tags Optimization</li>
                                      <li>Canonicalization/301 Redirect</li>
                                      <li>Website Page Load Optimization</li>
                                      <li>Schema Markup Implementation</li>
                                      <li>Image & Hyperlink Optimization</li>
                                      <li>Robots.txt Creation/Analysis</li>
                                      <li>Blog Writing (2 - Per Month)</li>
                                      <li>Informational Content Writing & Sharing (1 Per Month)</li>
                                      <li>Press Release Writing & Distribution</li>
                                      <li>Press Release Social Bookmarking</li>
                                      <li>Google Webmaster Tools Setup</li>
                                      <li>Google Analytics Setup & Integration</li>
                                  </ul>

                                  <div className="delivery">
                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                  </div>
                                  <div className="pricing-btns">
                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="pricing-card">
                              <div className="main-box">
                                  <LazyLoadImage src={pricing_bg} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="title">
                                  <h2>Elite SEO Package</h2>
                              </div>
                              <div className="inner-box">
                                  <h5>STARTING AT</h5>
                                  <h4>$1545</h4>
                                  <ul>
                                      <li>20 Keywords</li>
                                      <li>Guaranteed Ranking on Google</li>
                                      <li>Off-site Optimization</li>
                                      <li>On-site Optimization</li>
                                      <li>Link Building</li>
                                      <li>Social Bookmarking </li>
                                      <li>In-depth Site Analysis</li>
                                      <li>Content Duplicacy Check</li>
                                      <li>Initial Backlinks analysis</li>
                                      <li>Google Penalty Check</li>
                                      <li>Mobile Usability Check</li>
                                      <li>Competition Analysis</li>
                                      <li>Keyword Research</li>
                                      <li>Page Speed Analysis & Optimization</li>
                                      <li>Title & Meta Tags Optimization</li>
                                      <li>Content Optimization</li>
                                      <li>HTML Code Cleanup & Optimization</li>
                                      <li>Internal Link Structuring & Optimization</li>
                                      <li>Pages H tags Optimization</li>
                                      <li>Canonicalization/301 Redirect</li>
                                      <li>Website Page Load Optimization</li>
                                      <li>Robots.txt Creation/Analysis</li>
                                      <li>Press Release Writing & Distribution</li>
                                      <li>Press Release Social Bookmarking</li>
                                      <li>Schema Markup Implementation</li>
                                      <li>Image & Hyperlink Optimization</li>
                                      <li>Google Webmaster Tools Setup</li>
                                      <li>Google Analytics Setup & Integration</li>
                                      <li>Blog Writing (2 - Per Month)</li>
                                      <li>Informational Content Writing & Sharing (1 Per Month)</li>
                                  </ul>

                                  <div className="delivery">
                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                  </div>
                                  <div className="pricing-btns">
                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="pricing-card">
                              <div className="main-box">
                                  <LazyLoadImage src={pricing_bg} alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="title">
                                  <h2>Professional SEO Package</h2>
                              </div>
                              <div className="inner-box">
                                  <h5>STARTING AT</h5>
                                  <h4>$2145</h4>
                                  <ul>
                                      <li>30 Keywords</li>
                                      <li>Guaranteed Ranking on Google</li>
                                      <li>Off-site Optimization</li>
                                      <li>On-site Optimization</li>
                                      <li>Link Building</li>
                                      <li>Social Bookmarking</li>
                                      <li>In-depth Site Analysis</li>
                                      <li>Content Duplicacy Check</li>
                                      <li>Initial Backlinks analysis</li>
                                      <li>Google Penalty Check</li>
                                      <li>Mobile Usability Check</li>
                                      <li>Competition Analysis</li>
                                      <li>Keyword Research</li>
                                      <li>Page Speed Analysis Optimization</li>
                                      <li>Title Meta Tags Optimization</li>
                                      <li>Content Optimization</li>
                                      <li>HTML Code Cleanup Optimization</li>
                                      <li>Internal Link Structuring Optimization</li>
                                      <li>Pages H tags Optimization</li>
                                      <li>Canonicalization/301 Redirect</li>
                                      <li>Website Page Load Optimization</li>
                                      <li>Robots.txt Creation/Analysis</li>
                                      <li>Press Release Writing Distribution</li>
                                      <li>Press Release Social Bookmarking</li>
                                      <li>Schema Markup Implementation</li>
                                      <li>Image Hyperlink Optimization</li>
                                      <li>Google Webmaster Tools Setup</li>
                                      <li>Google Analytics Setup Integration</li>
                                      <li>Blog Writing (2 - Per Month)</li>
                                      <li>Informational Content Writing Sharing (1 Per Month)</li>
                                  </ul>

                                  <div className="delivery">
                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                  </div>
                                  <div className="pricing-btns">
                                      {/* <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">See Detail</a> */}
                                      <a href="javascript:$zopim.livechat.window.show();" className="btn btn-pricing">Chat Now</a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <Footer />

    </>
  )
}

export default Seo
