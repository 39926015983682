import React from 'react'
import Header from '../includes/header';
import Footer from '../includes/footer';
import brand_img from '../assets/images/brand-img.png';
import inner_banner_img from '../assets/images/inner-banner-img.png';
import icon_1 from '../assets/images/icon-1.png';
import icon_2 from '../assets/images/icon-2.png';
import icon_3 from '../assets/images/icon-3.png';
import icon_4 from '../assets/images/icon-4.png';

import icon_5 from '../assets/images/icon-5.png';
import icon_6 from '../assets/images/icon-6.png';
import icon_7 from '../assets/images/icon-7.png';
import icon_8 from '../assets/images/icon-8.png';

import icon_9 from '../assets/images/icon-9.png';
import icon_10 from '../assets/images/icon-10.png';
import icon_11 from '../assets/images/icon-11.png';
import icon_12 from '../assets/images/icon-12.png';

import icon_13 from '../assets/images/icon-13.png';
import icon_14 from '../assets/images/icon-14.png';
import icon_15 from '../assets/images/icon-15.png';
import icon_16 from '../assets/images/icon-16.png';
import icon_17 from '../assets/images/icon-17.png';
import icon_18 from '../assets/images/icon-18.png';
import pricing_bg from '../assets/images/pricing-bg.png';
import profund_bg from '../assets/images/profund-bg.png';
import useDocumentTitle from '../useDocumentTitle.jsx';


import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const WebDesign = () => {
    useDocumentTitle('Web Design')

  return (
    <>
        <Header />
          <section className="inner-banner-sec">
              <div className="container">
                  <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="banner-content">
                              <h1>Personalized Web Design Services Made Easy</h1>
                              <p>Having an online presence is crucial for any business to succeed. Set yourself apart from the competition and enhance your brand image with a captivating web page design from Codex Design Agency. Our design services are executed by experienced professionals, ensuring that you receive a visually appealing and impactful website.</p>
                              <p>Benefit from our web design company of highly rated and reviewed designers. Choose from a range of design concepts and receive a customized website within a week.</p>
                              <div className="brand-rating">
                                  <div className="rating">
                                      <h6>5.0 </h6>
                                      <ul>
                                          <li><i className="fa-solid fa-star"></i></li>
                                          <li><i className="fa-solid fa-star"></i></li>
                                          <li><i className="fa-solid fa-star"></i></li>
                                          <li><i className="fa-solid fa-star"></i></li>
                                          <li><i className="fa-solid fa-star"></i></li>
                                      </ul>
                                      <h6> rating on</h6>
                                  </div>
                                  <div className="brand-img">
                                      <LazyLoadImage src={brand_img}  alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                              </div>
                              <div className="banner-btn">
                                  <a href="javascript:void(0);" className="btn btn-contact">Get a quote</a>
                                  <a href="javascript:void(0);" className="btn btn-chat"><i className="fa-solid fa-messages"></i></a>
                              </div>
                          </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="main-img">
                              <LazyLoadImage src={inner_banner_img} className="img-fluid"  alt="img not found" effect="blur"/>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="about-sec-wrap">
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">
                          <div className="content">
                              <h2>Building, Managing, And Optimizing Websites to Increase Your Leads</h2>
                              <p>As a business owner, having a responsive website design is crucial for attracting both search engines and online users. Your target customers must perceive your online platform as credible and trustworthy. Failure to do so can result in the loss of potential clients and damage to your brand image.</p>
                              <p>Invest in responsive website design to establish trust and confidence in your brand offerings. Our website design company offers SEO-friendly website design services that aim to improve your search rankings, increase your online visibility, and convert website visitors into paying customers. So far, we have successfully achieved the following:</p>
                          </div>
                      </div>
                  </div>
                  <div className="row justify-content-xl-between justify-content-center">
                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
                          <div className="about-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_1}  alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="desc">
                                  <h4>275+</h4>
                                  <p>Projects Completed.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
                          <div className="about-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_2}  alt="img not found" effect="blur" className="img-fluid" />
                              </div>
                              <div className="desc">
                                  <h4>89%</h4>
                                  <p>Customer Retention</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
                          <div className="about-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_3}  alt="img not found" effect="blur" className="img-fluid" />
                              </div>
                              <div className="desc">
                                  <h4>120+</h4>
                                  <p>Professionals</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
                          <div className="about-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_4}  alt="img not found" effect="blur" className="img-fluid" />
                              </div>
                              <div className="desc">
                                  <h4>20+</h4>
                                  <p>Years of Experience</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
                          <div className="about-card">
                              <h5>Schedule A Call
                                  <br/>
                                      Now!
                              </h5>
                              <div className="desc">
                                  <a href="javascript:void(0);" className="btn btn-call"><i className="fa-solid fa-phone-volume"></i> Click
                                      Here!</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="Premium-ppc-sec">
              <div className="container-fluid">
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="content">
                              <h3>Custom Web Design Services</h3>
                              <p>Our web design consultants can help your brand with the following web design services</p>
                          </div>
                      </div>
                  </div>
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_5}  alt="img not found" effect="blur" className="img-fluid" />
                              </div>
                              <div className="desc">
                                  <h4>Responsive Design</h4>
                                  <p>A design approach that adjusts to various screen sizes for optimal viewing on a desktop, tablet, and mobile devices.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_6}  alt="img not found" effect="blur" className="img-fluid" />
                              </div>
                              <div className="desc">
                                  <h4>Website Copywriting</h4>
                                  <p>Our team provides customized content for websites to effectively communicate your brand message and attract visitors.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_7}  alt="img not found" effect="blur" className="img-fluid" />
                              </div>
                              <div className="desc">
                                  <h4>E-Commerce Functionality</h4>
                                  <p>We also add online shopping and transaction capabilities to a website, enabling a business to sell products or services online.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_8}  alt="img not found" effect="blur" className="img-fluid" />
                              </div>
                              <div className="desc">
                                  <h4>Content Management System (CMS)</h4>
                                  <p>We create an interface for managing website content and functionality, allowing for easy updates without coding knowledge.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_9}  alt="img not found" effect="blur" className="img-fluid" />
                              </div>
                              <div className="desc">
                                  <h4>BigCommerce Web Design</h4>
                                  <p>We specialize in custom designing and developing websites using the BigCommerce platform.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_10}  alt="img not found" effect="blur" className="img-fluid" />
                              </div>
                              <div className="desc">
                                  <h4>Shopify Web Design</h4>
                                  <p>Differentiate your brand from the competition with a tailored Shopify theme from Codex Design Agency that adheres to top CRO and SEO practices to maximize conversions and improve search engine ranking.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_11}  alt="img not found" effect="blur" className="img-fluid" />
                              </div>
                              <div className="desc">
                                  <h4>Woo Commerce Web Design</h4>
                                  <p>Create the perfect e-commerce website that fits your vision. WooCommerce, an open-source eCommerce solution based on WordPress, offers limitless customization options."</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_12}  alt="img not found" effect="blur" className="img-fluid" />
                              </div>
                              <div className="desc">
                                  <h4>Web Design Magento</h4>
                                  <p>With extensive experience in a Magento web development firm, Codex Design Agency comprehends the intricate process of creating eCommerce websites that are both attractive and highly functional.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_13}  alt="img not found" effect="blur" className="img-fluid" />
                              </div>
                              <div className="desc">
                                  <h4>WordPress Website Design Service</h4>
                                  <p>Codex Design Agency's WordPress website design services are crafted to deliver a seamless and enjoyable mobile experience for your website visitors.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_14}  alt="img not found" effect="blur" className="img-fluid" />
                              </div>
                              <div className="desc">
                                  <h4>ADA Compliant Web Design</h4>
                                  <p>We design websites to be accessible to individuals with disabilities, meeting the standards set by the Americans with Disabilities Act (ADA).</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_15}  alt="img not found" effect="blur" className="img-fluid" />
                              </div>
                              <div className="desc">
                                  <h4>Website Analysis</h4>
                                  <p>Our team analyzes a website to identify strengths and weaknesses, provide recommendations for improvement and maximize online performance.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="ppc-card">
                              <div className="main-img">
                                  <LazyLoadImage src={icon_16}  alt="img not found" effect="blur" className="img-fluid" />
                              </div>
                              <div className="desc">
                                  <h4>Website Maintenance</h4>
                                  <p>We provide ongoing support to keep a website updated, secure, and functioning optimally.</p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="pricing-sec-wrap">
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">
                          <div className="content">
                              <h2>Pricing Plans</h2>
                          </div>
                      </div>
                  </div>
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <div className="pricing-top-btn">
                              <a href="javascript:void(0);" className="btn btn-top-pricing">Web Design</a>
                          </div>
                      </div>
                  </div>
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="pricing-card">
                              <div className="main-box">
                                  <LazyLoadImage src={pricing_bg}  alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="title">
                                  <h2>Startup Website Package</h2>
                              </div>
                              <div className="inner-box">
                                  <h5>STARTING AT</h5>
                                  <h4>$245</h4>
                                  <ul>
                                      <li>5 Stock Photos</li>
                                      <li>5 Page Website</li>
                                      <li>3 Banner Design</li>
                                      <li>1 jQuery Slider Banner</li>
                                      <li>FREE Google Friendly Sitemap</li>
                                      <li>Complete W3C Certified HTML</li>
                                      <li>Facebook Page Design</li>
                                      <li>Twitter Page Design</li>
                                      <li>YouTube Page Design</li>
                                      <li>100% Satisfaction Guarantee</li>
                                      <li>100% Unique Design Guarantee</li>
                                      <li>100% Money Back Guarantee</li>
                                      <li>Mobile Responsive will be Additional $99</li>
                                      <li>CMS will be Additional $149</li>
                                  </ul>

                                  <div className="delivery">
                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                  </div>
                                  <div className="pricing-btns">
                                      <a href="javascript:void(0);" className="btn btn-pricing">See Detail</a>
                                      <a href="javascript:void(0);" className="btn btn-pricing">Chat Now</a>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="pricing-card">
                              <div className="main-box">
                                  <LazyLoadImage src={pricing_bg}  alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="title">
                                  <h2>Professional Website Package</h2>
                              </div>
                              <div className="inner-box">
                                  <h5>STARTING AT</h5>
                                  <h4>$695</h4>
                                  <ul>
                                      <li>10 Unique Pages Website</li>
                                      <li>Mobile Responsive</li>
                                      <li>5 Stock images</li>
                                      <li>3 Banner Designs</li>
                                      <li>1 jQuery Slider Banner</li>
                                      <li>FREE Google Friendly Sitemap</li>
                                      <li>Complete W3C Certified HTML</li>
                                      <li>Facebook Page Design</li>
                                      <li>Twitter Page Design</li>
                                      <li>YouTube Page Design</li>
                                      <li>Complete Deployment</li>
                                      <li>100% Satisfaction Guarantee</li>
                                      <li>100% Unique Design Guarantee</li>
                                  </ul>

                                  <div className="delivery">
                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                  </div>
                                  <div className="pricing-btns">
                                      <a href="javascript:void(0);" className="btn btn-pricing">See Detail</a>
                                      <a href="javascript:void(0);" className="btn btn-pricing">Chat Now</a>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="pricing-card">
                              <div className="main-box">
                                  <LazyLoadImage src={pricing_bg}  alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="title">
                                  <h2>Professional Logo Package</h2>
                              </div>
                              <div className="inner-box">
                                  <h5>Elite Website Package</h5>
                                  <h4>$995</h4>
                                  <ul>
                                      <li>Upto 15 Unique Pages Website</li>
                                      <li>Conceptual and Dynamic Website</li>
                                      <li>Mobile Responsive</li>
                                      <li>Online Reservation/Appointment Tool (Optional)</li>
                                      <li>Online Payment Integration (Optional)</li>
                                      <li>Custom Forms</li>
                                      <li>Lead Capturing Forms (Optional)</li>
                                      <li>Striking Hover Effects</li>
                                      <li>Newsletter Subscription (Optional)</li>
                                      <li>Newsfeed Integration</li>
                                      <li>Social Media Integration</li>
                                      <li>Search Engine Submission</li>
                                      <li>8 Stock Photos</li>
                                      <li>5 Unique Banner Design</li>
                                      <li>1 jQuery Slider Banner</li>
                                      <li>Complete W3C Certified HTML</li>
                                      <li>Facebook Page Design</li>
                                      <li>Twitter Page Design</li>
                                      <li>YouTube Page Design</li>
                                      <li>Complete Deployment</li>
                                      <li>100% Satisfaction Guarantee</li>
                                      <li>100% Unique Design Guarantee</li>
                                      <li>100% Money Back Guarantee *</li>
                                  </ul>

                                  <div className="delivery">
                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                  </div>
                                  <div className="pricing-btns">
                                      <a href="javascript:void(0);" className="btn btn-pricing">See Detail</a>
                                      <a href="javascript:void(0);" className="btn btn-pricing">Chat Now</a>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="pricing-card">
                              <div className="main-box">
                                  <LazyLoadImage src={pricing_bg}  alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="title">
                                  <h2>Silver Website Package</h2>
                              </div>
                              <div className="inner-box">
                                  <h5>STARTING AT</h5>
                                  <h4>$1595</h4>
                                  <ul>
                                      <li>15 to 20 Pages Website</li>
                                      <li>Custom Made</li>
                                      <li>Interactive</li>
                                      <li>Dynamic High End Design</li>
                                      <li>Custom WP (or) Custom PHP Development</li>
                                      <li>1 jQuery Slider Banner</li>
                                      <li>Up to 10 Custom Made Banner Designs</li>
                                      <li>10 Stock Images</li>
                                      <li>Unlimited Revisions</li>
                                      <li>Special Hoover Effects</li>
                                      <li>Content Management System (CMS)</li>
                                      <li>Online Appointment/Scheduling/Online Ordering Integration (Optional)</li>
                                      <li>Online Payment Integration (Optional)</li>
                                      <li>Multi Lingual (Optional)</li>
                                      <li>Custom Dynamic Forms (Optional)</li>
                                      <li>Signup Area (For Newsletters</li>
                                      <li>Offers etc.)</li>
                                      <li>Search Bar</li>
                                      <li>Live Feeds of Social Networks integration (Optional)</li>
                                      <li>Mobile Responsive</li>
                                      <li>Free Google Friendly Sitemap</li>
                                      <li>Search Engine Submission</li>
                                      <li>Complete W3C Certified HTML</li>
                                      <li>Industry Specified Team of Expert Designers and Developers</li>
                                      <li>Complete Deployment</li>
                                      <li>Dedicated Accounts Manager</li>
                                      <li>Facebook Page Design</li>
                                      <li>Twitter Page Design</li>
                                      <li>YouTube Page Design</li>
                                      <li>100% Ownership Rights</li>
                                      <li>100% Satisfaction Guarantee</li>
                                      <li>100% Unique Design Guarantee</li>
                                      <li>100% Money Back Guarantee *</li>
                                  </ul>

                                  <div className="delivery">
                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                  </div>
                                  <div className="pricing-btns">
                                      <a href="javascript:void(0);" className="btn btn-pricing">See Detail</a>
                                      <a href="javascript:void(0);" className="btn btn-pricing">Chat Now</a>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="pricing-card">
                              <div className="main-box">
                                  <LazyLoadImage src={pricing_bg}  alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="title">
                                  <h2>Business Website Package</h2>
                              </div>
                              <div className="inner-box">
                                  <h5>STARTING AT</h5>
                                  <h4>$2495</h4>
                                  <ul>
                                      <li>15 to 20 Pages Website</li>
                                      <li>Custom Made</li>
                                      <li>Interactive</li>
                                      <li>Dynamic High End Design</li>
                                      <li>Custom WP (or) Custom PHP Development</li>
                                      <li>1 jQuery Slider Banner</li>
                                      <li>Up to 10 Custom Made Banner Designs</li>
                                      <li>10 Stock Images</li>
                                      <li>Unlimited Revisions</li>
                                      <li>Special Hoover Effects</li>
                                      <li>Content Management System (CMS)</li>
                                      <li>Online Appointment/Scheduling/Online Ordering Integration (Optional)</li>
                                      <li>Online Payment Integration (Optional)</li>
                                      <li>Multi Lingual (Optional)</li>
                                      <li>Custom Dynamic Forms (Optional)</li>
                                      <li>Signup Area (For Newsletters</li>
                                      <li>Offers etc.)</li>
                                      <li>Search Bar</li>
                                      <li>Live Feeds of Social Networks integration (Optional)</li>
                                      <li>Mobile Responsive</li>
                                      <li>15 Seconds 2D Explainer Video</li>
                                      <li>Voice - Over Sound Effects</li>
                                      <li>Professional Script Writing</li>
                                      <li>Storyboard</li>
                                      <li>SEO Meta Tags</li>
                                      <li>Free Google Friendly Sitemap</li>
                                      <li>Search Engine Submission</li>
                                      <li>Complete W3C Certified HTML</li>
                                      <li>Industry Specified Team of Expert Designers and Developers</li>
                                      <li>Complete Deployment</li>
                                      <li>Dedicated Accounts Manager</li>
                                      <li>100% Ownership Rights</li>
                                      <li>100% Satisfaction Guarantee</li>
                                      <li>100% Unique Design Guarantee</li>
                                      <li>100% Money Back Guarantee *</li>
                                  </ul>
                                  <div className="delivery">
                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                  </div>
                                  <div className="pricing-btns">
                                      <a href="javascript:void(0);" className="btn btn-pricing">See Detail</a>
                                      <a href="javascript:void(0);" className="btn btn-pricing">Chat Now</a>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                          <div className="pricing-card">
                              <div className="main-box">
                                  <LazyLoadImage src={pricing_bg}  alt="img not found" effect="blur" className="img-fluid"/>
                              </div>
                              <div className="title">
                                  <h2>Automated/Interactive Conferencing Portal Package</h2>
                              </div>
                              <div className="inner-box">
                                  <h5>STARTING AT</h5>
                                  <h4>$4995</h4>
                                  <ul>
                                      <li>Unlimited Page Website</li>
                                      <li>Custom Content Management System (CMS)</li>
                                      <li>Unique Pages and UI Design</li>
                                      <li>Complete Custom Development</li>
                                      <li>Process Automation Tools</li>
                                      <li>Newsfeed Integration</li>
                                      <li>Social Media Plugins Integration</li>
                                      <li>Upto 40 Stock images</li>
                                      <li>10 Unique Banner Designs</li>
                                      <li>JQuery Slider</li>
                                      <li>Search Engine Submission</li>
                                      <li>Free Google Friendly Sitemap</li>
                                      <li>Custom Email Addresses</li>
                                      <li>Social Media Page Designs (Facebook/ Twitter/ Instagram)</li>
                                      <li>Complete W3C Certified HTML</li>
                                      <li>Complete Deployment</li>
                                      <li>100% Satisfaction Guarantee</li>
                                      <li>100% Unique Design Guarantee</li>
                                      <li>Money Back Guarantee</li>
                                      <li>Key features</li>
                                      <li>Automated Course Creation</li>
                                      <li>Video Conferencing</li>
                                      <li>Skills/Certification Tracking</li>
                                      <li>Mobile Learning</li>
                                      <li>Asynchronous Learning</li>
                                      <li>CRM Features</li>
                                      <li>Gamification</li>
                                      <li>Social Learning/Message Boards</li>
                                      <li>Motivational Triggers</li>
                                      <li>Forums And Webinars</li>
                                      <li>E-commerce And Subscriptions</li>
                                      <li>Online Course Booking</li>
                                      <li>Excellent Reporting</li>
                                      <li>Invoicing Integration</li>
                                      <li>Financial Integrations</li>
                                      <li>Student Information management</li>
                                      <li>Automated communications</li>
                                      <li>Learning Management System</li>
                                      <li>Quick And Easy Course Scheduling</li>
                                      <li>Reporting And Data Analysis</li>
                                      <li>Assessment Management &amp; Live Feedback</li>
                                      <li>Gradebooks</li>
                                      <li>Quick User Integration</li>
                                      <li>Easy Payment Methods</li>
                                      <li>Online Communities &amp; Social Engagement</li>
                                      <li>Curation of Resources And Adding Own Resources</li>
                                  </ul>

                                  <div className="delivery">
                                      <p>ADD ON : $500 For 24 Hours Rush Delivery</p>
                                  </div>
                                  <div className="pricing-btns">
                                      <a href="javascript:void(0);" className="btn btn-pricing">See Detail</a>
                                      <a href="javascript:void(0);" className="btn btn-pricing">Chat Now</a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="profund-sec-wrap">
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">
                          <div className="content">
                              <h2>Our Process</h2>
                          </div>
                      </div>
                  </div>
                  <div className="row justify-content-center">
                      <div className="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3 col-xxl-3">
                          <div className="profund-card">
                              <div className="step">
                                  <h5>01</h5>
                              </div>
                              <div className="inner-box">
                                  <div className="main-img">
                                      <LazyLoadImage src={icon_17}  alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                                  <div className="desc">
                                      <h4>Give Instructions For Your Website Design</h4>
                                      <p>In this step, the client provides Codex Design Agency with specific details and requirements for the desired website design.</p>
                                  </div>
                                  <div className="bg-img">
                                      <LazyLoadImage src={profund_bg}  alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3 col-xxl-3">
                          <div className="profund-card">
                              <div className="step">
                                  <h5>02</h5>
                              </div>
                              <div className="inner-box">
                                  <div className="main-img">
                                      <LazyLoadImage src={icon_18}  alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                                  <div className="desc">
                                      <h4>Get A Dozen Ideas From Us</h4>
                                      <p>Based on your instructions, Codex Design Agency will present a dozen design ideas for the client to review and consider.</p>
                                  </div>
                                  <div className="bg-img">
                                      <LazyLoadImage src={profund_bg}  alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3 col-xxl-3">
                          <div className="profund-card">
                              <div className="step">
                                  <h5>03</h5>
                              </div>
                              <div className="inner-box">
                                  <div className="main-img">
                                      <LazyLoadImage src={icon_6}  alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                                  <div className="desc">
                                      <h4>Pick Your Favorite Web Design</h4>
                                      <p>In the third step of our web design process, you will choose your favorite design concept from the dozen presented.</p>
                                  </div>
                                  <div className="bg-img">
                                      <LazyLoadImage src={profund_bg}  alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3 col-xxl-3">
                          <div className="profund-card">
                              <div className="step">
                                  <h5>04</h5>
                              </div>
                              <div className="inner-box">
                                  <div className="main-img">
                                      <LazyLoadImage src={icon_17}  alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                                  <div className="desc">
                                      <h4>Finalize</h4>
                                      <p>In the final step, Codex Design Agency will work with you to finalize and implement the selected design, ensuring that the website is fully functional and meets your specifications.</p>
                                  </div>
                                  <div className="bg-img">
                                      <LazyLoadImage src={profund_bg}  alt="img not found" effect="blur" className="img-fluid"/>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
        <Footer />
    </>
  )
}

export default WebDesign
