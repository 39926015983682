import React from 'react'


import icon_1 from '../assets/images/icon-1.png';
import icon_2 from '../assets/images/icon-2.png';
import icon_3 from '../assets/images/icon-3.png';
import icon_4 from '../assets/images/icon-4.png';

const about = () => {
  return (
    <>

          <section className="about-sec-wrap">
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">
                          <div className="content">
                              <h2>About Us</h2>
                              <p>Our video production company embarked on its creative journey two decades ago. During this time, we've completed over 1000 video projects for start-ups and established companies around the globe. We take pride in our ability to leave behind a trail of satisfied clients wherever we operate. From motion graphics services to 3D animations, our explainer video company can help build your brand image in the eyes of your target audience</p>
                          </div>
                      </div>
                  </div>
                  <div className="row justify-content-xl-between justify-content-center">
                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
                          <div className="about-card">
                              <div className="main-img">
                                  <img src={icon_1} alt="img not found" className="img-fluid" />
                              </div>
                              <div className="desc">
                                  <h4>275+</h4>
                                  <p>Projects Completed.</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
                          <div className="about-card">
                              <div className="main-img">
                                  <img src={icon_2} alt="img not found" className="img-fluid" />
                              </div>
                              <div className="desc">
                                  <h4>89%</h4>
                                  <p>Customer Retention</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
                          <div className="about-card">
                              <div className="main-img">
                                  <img src={icon_3} alt="img not found" className="img-fluid" />
                              </div>
                              <div className="desc">
                                  <h4>120+</h4>
                                  <p>Professionals</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
                          <div className="about-card">
                              <div className="main-img">
                                  <img src={icon_4}alt="img not found" className="img-fluid" />
                              </div>
                              <div className="desc">
                                  <h4>20+</h4>
                                  <p>Years of Experience</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 p-xxl-0">
                          <div className="about-card">
                              <h5>Schedule A Call
                                  <br/>
                                      Now!
                              </h5>
                              <div className="desc">
                                  <a href="javascript:void(0);" className="btn btn-call"><i className="fa-solid fa-phone-volume"></i> Click
                                      Here!</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
      
    </>
  )
}

export default about
