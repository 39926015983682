import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {  Link } from 'react-router-dom';
import Logo from '../assets/images/logo.png';
import Logo_White from '../assets/images/logo-white.png';
import Addons from '../component/addons';
import Modal from '../component/modal';
import Menu from '../includes/mobileMenu';


const header = () => {



  return (
    <>
      <Modal />
      <Menu/>
     <Addons/>
      {/* <!-- header  --> */}
      <div className="header">
        <div className="container-fluid">
          <header className="mobile-header d-lg-none">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-6 logo_mb"><Link to="/"><LazyLoadImage src={Logo} effect="blur" placeholderSrc={Logo} className="img-fluid mobile-logo" alt="not found" /></Link></div>
                <div className="col-6 d-flex justify-content-end">
                  <div className="bar"><Link data-bs-toggle="offcanvas" to="#offcanvasExample" role="button" aria-controls="offcanvasExample"><i className="fas fa-bars"></i></Link></div>
                </div>
              </div>
            </div>
          </header>
          {/* <!-- mobile header --> */}
          <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
            <div className="offcanvas-header"><LazyLoadImage src={Logo_White} effect="blur" placeholderSrc={Logo_White} className="white-logo" alt="img not found"/>
              <div className="close-btn-offcanvas"><button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button></div>
            </div>
            <div className="offcanvas-body">
              <div className="offcanvas-manu nav">
                <ul>
                  <li><Link to="/">Home</Link></li>
                  <li>
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Services</button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <ul className="offcanvas-submenu">
                              <li><Link to="/web-design">Web Design</Link></li>
                              <li><Link to="/logo-design">Logo Design</Link></li>
                              <li><Link to="/ecommerce">Ecommerce Solutions</Link></li>
                              <li><Link to="/animation">Animation</Link></li>
                              <li><Link to="/ppc">PPC</Link></li>
                              <li><Link to="/seo">SEO Services</Link></li>
                              <li><Link to="/digital-marketing">Digital Marketing</Link></li>
                              <li><Link to="/creative-writting">Creative Copywriting</Link></li>
                              <li><Link to="/author-website">Author Website</Link></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li><Link to="/packages">Packages</Link></li>
                  <li><Link to="/combo-packages">Combo Packages</Link></li>
                  <li><Link to="/about">About Us</Link></li>
                  <li><Link to="/contact">Contact</Link></li>
                  <li><Link to="/review">Reviews</Link></li>
                </ul>
              </div>
            </div>
          </div>
          {/* <!-- mobile header -->
          <!-- web header --> */}
          <header className="position d-none d-lg-block header_sticky">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-2"><Link to="/"><LazyLoadImage src={Logo} placeholderSrc={Logo} alt="img not found" className="logo" effect="blur" /></Link></div>
                <div className="col-8 d-flex justify-content-end">
                  <nav className="navigation">
                    <ul className="d-flex">
                      <li><Link to="/">Home</Link></li>
                      <li>
                        <div className="dropdown"><button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Services</button>
                          <ul className="dropdown-menu">
                            <li><Link className="dropdown-item" to="/web-design">Web Design</Link></li>
                            <li><Link className="dropdown-item" to="/logo-design">Logo Design</Link></li>
                            <li><Link className="dropdown-item" to="/ecommerce">Ecommerce Solutions</Link></li>
                            <li><Link className="dropdown-item" to="/animation">Animation</Link></li>
                            <li><Link className="dropdown-item" to="/ppc">PPC</Link></li>
                            <li><Link className="dropdown-item" to="/seo">SEO Services</Link></li>
                            <li><Link className="dropdown-item" to="/digital-marketing">Digital Marketing</Link></li>
                            <li><Link className="dropdown-item" to="/creative-writting">Creative Copywriting</Link></li>
                            <li><Link className="dropdown-item" to="/author-website">Author Website</Link></li>
                          </ul>
                        </div>
                      </li>
                      <li><Link to="/packages">Packages</Link></li>
                      <li><Link to="/combo-packages">Combo Packages</Link></li>
                      <li><Link to="/contact">Contact</Link></li>
                      <li><Link to="/about">About</Link></li>
                      <li><Link to="/review">Reviews</Link></li>
                    </ul>
                  </nav>
                </div>
                <div className="col-2">
                  <div className="d-flex justify-content-end"><button className="header-btn" data-bs-toggle="modal" data-bs-target="#exampleModal">Request Quote</button></div>
                </div>
              </div>
            </div>
          </header>
          {/* <!-- web header --> */}
        </div>
      </div>
      {/* <!-- header  --> */}
    </>
  )
}

export default header
